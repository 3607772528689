import React , { useState } from "react";
import useSetInterval from "use-set-interval";

const TimerTimeLeft = (Value) => {
    var ThisTimerValue = Value.SeatFourTimerValue;

    if(ThisTimerValue <= 0){
        ThisTimerValue = 20
    }

    const [ TimerValue , TimerValueUpdate ] = useState(ThisTimerValue);

    useSetInterval ( ()=> {
        TimerValueUpdate(TimerValue - 1)
    }, 1000)

    return (
        <div>
            { TimerValue > 0 && TimerValue }
        </div>
    )

}

export default TimerTimeLeft