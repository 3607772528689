import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Icon from '../../../Icons/BluffLogo.svg'


const Logo = () => {

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long",
                Top : "5%"
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long",
                Top : "5%"
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long",
                Top : "5%"
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 70,
                ImageHeight : percentage(70 , 20),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 80,
                ImageHeight : percentage(80 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 80,
                ImageHeight : percentage(80 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 70,
                ImageHeight : percentage(70 , 15),
                ImageType : "Medium",
                Top : "5%"
            })
        }
    }

    const TemplateValue = TemplateData()

    if(TableType >= 1){
    return (
        <motion.div 
        style={{
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , 90)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : "50%",
            top : TemplateValue.Top,
            transform : "translate(-50%, -0%)",
        }}>

            <motion.div
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    height :  `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,
                    left : "50%",
                    transform : "translate(-50%, -0%)",
                }}>
                    <object style={{
                        height : percentage(currentSize.width , TemplateValue.ImageHeight),
                        position : "absolute",
                        zIndex : 1,
                        left : "50%",              
                        transform : "translate(-50%, -0%)",          
                    }} type="image/svg+xml" data={Icon}>Floor</object>  
                
            </motion.div>                      
        </motion.div>
    )
    } else {
        return (<motion.div>
            Resolution Not Supported
        </motion.div>)
    }
    
}

export default Logo