import React from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/BluffInfo.svg'

const Button = () => {

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    return (
        <motion.div 
        style={{
            position : "absolute",
            bottom : "93%",
            left : `${0 - percentage(BoardWidth , 12.5)}px`,
            width : `${percentage(BoardWidth , 125)}px`,
            height : `${percentage(BoardWidth , 40)}px`,                                             
            }}>
            <object style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  
        </motion.div>
    )
}

export default Button