module.exports = {
    'url_api' : '/api',
 //  'url_api' : 'http://192.168.0.104:5001/api',
    'app_version' : "v3",
    'AssetLoadedStatus' : null,
    'MatchMakerData' : null,
    'DefaultBluffSound' : null,
    'practiceModeTableAccessPass' : null,
    'MxPlayerData' : null,
    'isPayloadSent' : false,
    'FirstTimeLoad' : null
};