import React , { useState } from 'react';
import ChallengeSound from './ChallengeSound';
import useSetTimeout from 'use-set-timeout';

const Section = () => {
    const [ Playing , PlayingUpdate ] = useState(true);
    
    useSetTimeout(() => {
        PlayingUpdate(false)
    }, 5000)

    return (
        <div>
            { Playing === true && < ChallengeSound /> }
        </div>
    )
    
}

export default Section