import React, { useState  , useEffect } from "react";
import { motion }  from "framer-motion";
import useResize from 'use-resize'; 
import TableTemplate from './TableTemplate';
import TimedOut from '../../../Icons/TimedOut.svg'
import PassIcon from '../../../Icons/Pass.svg'
import socket from '../../../SocketConnectionManager';
import useSetTimeout from "use-set-timeout";

const Mover = () => {
    const [ EmoteLocation , EmoteLocationUpdate ] = useState(null)
    const [ EmoteType , EmoteTypeUpdate ] = useState(null)

    const currentSize = SizeInfo();

    const TemplateValue = TableTemplate()

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    useEffect(() => {
        socket.on("EmoteAlert" , function(Data){
            EmoteLocationUpdate(Data.PlayerSeat)
            EmoteTypeUpdate(Data.EmoteType)
        })

        return () => {
            socket.off('EmoteAlert');
        }
    }, [ ])


    const Animater = (Seat) => {
        if(Seat === 1){
            return ({
                position : "absolute",
                left : "32%",   
                transform : "translate(-50%, -50%)",
                top : "61%",  
                scale : 1,               
            })
        } else if(Seat === 2){
            return ({
                position : "absolute",
                left : "-8%",   
                transform : "translate(-50%, -50%)",
                top : "5%", 
                scale : 1,
            })
        } else if(Seat === 3){
            return ({
                position : "absolute",
                left : "32%",   
                transform : "translate(-50%, -50%)",
                top : "-22%",
                scale : 1,
            })
        } else if(Seat === 4){
            return ({
                position : "absolute",
                left : "71.75%",   
                transform : "translate(-50%, -50%)",
                top : "5%", 
                scale : 1,
            })
        } else {
            return ({
                position : "absolute",
                left : "32%",    
                transform : "translate(-50%, -50%)",
                top : "40%" ,
                scale : 0.5,
            })
        }        
    }


    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }

    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "1",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
        })
    }
    
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const EmoteOff = () => {
        useSetTimeout(() => {
            EmoteLocationUpdate(null)
            EmoteTypeUpdate(null)
        }, 2000)

        return(<motion.div>

        </motion.div>)
    }
    return (
        <motion.div 
        className=""
        style={
            animateValue()
        }>            
               <motion.div 
                style={
                    Animater(EmoteLocation)
                }>
                    <motion.div
                        style={{                   
                            width :  `${percentage(BoardWidth , 25)}px`,
                            height : `${percentage(BoardHeight , 9)}px`,                  
                            position : "absolute",
                            }}>
                                { EmoteType === 9 && 
                                    <motion.div>
                                        <object style={{
                                            width :  `100%`,
                                            position : "absolute",
                                            zIndex : 1                                
                                        }} type="image/svg+xml" data={PassIcon}>Table</object>                                  
                                    </motion.div>                            
                                }

                                { EmoteType === 17 && 
                                        <motion.div>
                                        <object style={{
                                            width :  `100%`,
                                            position : "absolute",
                                            zIndex : 1                                
                                        }} type="image/svg+xml" data={TimedOut}>Table</object>  
                                    </motion.div>                            
                                }
                            </motion.div>

                </motion.div>

               { EmoteLocation !== null && < EmoteOff /> }
        </motion.div>
    )
    
}

            

export default Mover