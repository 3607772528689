import React from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import Icon from '../../../Icons/BootCollection.svg'
import TableTemplate from './TableTemplate';

const Mover = () => {

    const currentSize = SizeInfo();


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20                                      
        })
    }

    return (
        <motion.div style={ animateValue() }>
            <motion.div
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,                     
                }}>


                    <object style={{
                        width : percentage(currentSize.width , TemplateValue.ImageWidth),
                        position : "absolute",
                        zIndex : 1,
                        left : "50%",
                        top : "50%",
                        transform : "translate(-50%, -50%)",
                    }} type="image/svg+xml" data={Icon}>Table</object>  
                
            </motion.div>                      
        </motion.div>
    )

    
}

            

export default Mover