import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/MusicCold.svg'

const Button = (Value) => {
    const [ IconRendered , IconRenderedUpdate ] = useState(null)
    const isExitSelected = Value.isExitSelected
    const MusicStatus = Value.MusicStatus ;
    const MusicMode = Value.MusicMode ;

    const IconLoaded = () => {
        IconRenderedUpdate(true)
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    const zIndexValue = () => {
        if(MusicStatus === true){
            if(MusicMode === 1){
                return (2)
            } else {
                return (1)
            }
        } else {
            return (0)
        }
    }

    const AnimateValue = () => {
        if(isExitSelected === null && MusicStatus === true && MusicMode === 1 && IconRendered === true){
            return ({
                x : 0, y : 0, opacity : 1
            })
        } else {
            return ({
                x : 0, y : 0, opacity : 0
            })
        }
    }

    const TransitionValue = () => {
        if(isExitSelected === null && MusicStatus === true && MusicMode === 1 && IconRendered === true){
            return ({
                duration : 0.3
            })
        } else {
            return ({
                duration : 0
            })
        }
    }

    return (
        <motion.div 
        initial={{x : 0, y : 0, opacity : 0}}
        animate={ AnimateValue() }  
        transition={ TransitionValue() }
        style={{
                position : "absolute",
                bottom : "0%",
                zIndex : zIndexValue(),
                right : `${percentage(BoardHeight , 1)}px`,
                width : `${percentage(BoardHeight , 20)}px`,
                height : `${percentage(BoardHeight , 10)}px`,   
            }}>
            <object onLoad={() => IconLoaded() } style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  

        </motion.div>
    )
}

export default Button