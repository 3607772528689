import React , { useState , useEffect } from 'react';
import useOrientationChange from "use-orientation-change";
import IsAccess from '../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import { Redirect } from 'react-router';
import Rendering from './ProfileComponents/TutorialRendering'
import DisconnectedProfile from './ProfileAssets/ProfileDisconnected'

const Section = () => {
    const orientation = useOrientationChange()
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null);

    useEffect(() => {
        if(IsAccessData === null){
            IsAccess(function (AccessDataValue){
                IsAccessDataUpdate(AccessDataValue)
            })              
        }
    },[ IsAccessData ])


    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div style={{'position' : "fixed"}}>
                { IsAccessData === false && <Redirect to="/" />}
                { IsAccessData !== null && IsAccessData !== false && < Rendering /> }
                < DisconnectedProfile />
            </div>
        )
    }
}

export default Section