import React from 'react';
import { motion } from "framer-motion";
import Icon from '../../../Icons/PlayerBlockLeft.svg'
import IconTwo from '../../../Icons/PlayerBlockRight.svg'
import IconThree from '../../../Icons/WinnerLeft.svg'
import IconFour from '../../../Icons/WinnerRight.svg'
import Avatar from './OpponentSectionElements/Avatar'
import UserName from './OpponentSectionElements/Username';
import MxPlayerUserName from './OpponentSectionElements/MxPlayerUserName';
import MxPlayerAvatar from './OpponentSectionElements/MxPlayerAvatar';
import CardCount from './OpponentSectionElements/CardCount';
import LifeCount from './OpponentSectionElements/LifeCount';
import Evicted from '../../../Icons/Evicted.svg';

const Player = (Value) => {
    const Seat = Value.Seat ;
    const PlayerValues = Value.PlayerValues ;

    

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const BoardHeight = Value.BoardHeight ;
    const BoardWidth = Value.BoardWidth ;


    return (<motion.div>


        { Seat !== 4 &&
        <motion.div 
        style={{
            position : "absolute",
            zIndex : "1",
            width :  `${percentage(BoardWidth , 30)}px`,
            height : `${percentage(BoardHeight , 18)}px`,                                                                       
        }}>

        
            <object style={{
                width : `${percentage(BoardWidth , 30)}px`,
                position : "absolute",
                zIndex : 1,
                left : "0%",
            }} type="image/svg+xml" data={Icon}>Table</object>  
        
            { PlayerValues !== null && PlayerValues.Seat !== 1 &&
                < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }  

            { PlayerValues !== null && PlayerValues.Seat === 1 &&
                < MxPlayerAvatar PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }   

            { PlayerValues !== null && PlayerValues.Seat !== 1 &&
                < UserName PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            } 

            { PlayerValues !== null && PlayerValues.Seat === 1 &&
                < MxPlayerUserName PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            } 

            { PlayerValues !== null &&
                < CardCount isLeft={true} PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }    

            { PlayerValues !== null &&
                < LifeCount isLeft={true} PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }          

        
            { PlayerValues !== null && PlayerValues.RemainingLife === 0 && 
                <img className="slide-in-left" style={{
                    width : `${percentage(BoardWidth , 30)}px`,
                    position : "absolute",
                    top : "-10%",
                    zIndex : 100,
                    left : "0%",
                    backgroundColor : "rgba(0,0,0,0.8)",
                    borderRadius : "50%"
                }} src={Evicted} alt="Evicted" />
            } 

            { PlayerValues !== null && PlayerValues.Winner === true && 
                <motion.div className="slide-in-left" style={{
                    position : "absolute",
                    zIndex : 20,
                    width :  `${percentage(BoardWidth , 60)}px`,
                    height :  `${percentage(BoardWidth , 30)}px`,
                    top : "-10%",
                    left : "-2%"
                }}>
                    <object style={{
                        width :  `${percentage(BoardWidth , 60)}px`,
                        position : "absolute",
                        zIndex : 1                                
                    }} type="image/svg+xml" data={IconThree}>Table</object>  
                
                </motion.div>
            } 


        </motion.div>  
        }

        { Seat === 4 &&
        <motion.div  
        style={{
            position : "absolute",
            zIndex : "1",
            width :  `${percentage(BoardWidth , 30)}px`,
            height : `${percentage(BoardHeight , 18)}px`,                                                 
        }}>


            <object style={{
                width : `${percentage(BoardWidth , 30)}px`,
                position : "absolute",
                zIndex : 1,
                left : "0%",
            }} type="image/svg+xml" data={IconTwo}>Table</object>  

            { PlayerValues !== null &&
                < Avatar PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }    

            { PlayerValues !== null &&
                < UserName PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            } 

            { PlayerValues !== null &&
                < CardCount isLeft={false} PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }  

            { PlayerValues !== null &&
                < LifeCount isLeft={false} PlayerValues = {PlayerValues} BlockSize={percentage(BoardWidth , 20)}/>  
            }   
                        
            { PlayerValues !== null && PlayerValues.RemainingLife === 0 && 
                <img className="slide-in-right" style={{
                    width : `${percentage(BoardWidth , 30)}px`,
                    position : "absolute",
                    top : "-10%",
                    zIndex : 100,
                    left : "0%",
                    backgroundColor : "rgba(0,0,0,0.8)",
                    borderRadius : "50%"
                }} src={Evicted} alt="Evicted" />
            } 

            { PlayerValues !== null && PlayerValues.Winner === true && 
                <motion.div className="slide-in-right" style={{
                    position : "absolute",
                    zIndex : 20,
                    width :  `${percentage(BoardWidth , 60)}px`,
                    height :  `${percentage(BoardWidth , 30)}px`,
                    top : "-10%",
                    left : "-90%"
                }}>
                    <object style={{
                        width :  `${percentage(BoardWidth , 60)}px`,
                        position : "absolute",
                        zIndex : 1                                
                    }} type="image/svg+xml" data={IconFour}>Table</object>  
                
                </motion.div>
            }
            
        </motion.div>  
        }
    </motion.div>)
    
}

export default Player