import React , { useState , useEffect } from 'react';
import useOrientationChange from "use-orientation-change";
import IsAccess from '../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import Rendering from './ProfileComponents/GameTableLogics';
import PlayerControls from './GameAssets/PlayerControls/PlayerControls'
import EmoteHandler from './GameAssets/EmoteHandler'

const Section = () => {
    const orientation = useOrientationChange()
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null);

    useEffect(() => {
        if(IsAccessData === null){
            IsAccess(function (AccessDataValue){
                IsAccessDataUpdate(AccessDataValue)
            })
        }
    },[ IsAccessData ])

    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div>
                < Rendering />
                < PlayerControls />
                < EmoteHandler />
            </div>
        )
    }
}

export default Section