import React , { useState } from 'react';
import { motion } from "framer-motion";
import ConnectingWithMatchMaker from '../../../Icons/ConnectingToMatchMaker.svg'
import OpponentFound from '../../../Icons/OpponentFound.svg'
import OpponentFoundNoAd from '../../../Icons/OpponentFoundNoAd.svg'
import Loader from '../../../Icons/Loading.svg'
import useSetTimeout from 'use-set-timeout';
import useResize from 'use-resize'; 
import RewardNotAdded from '../../../Icons/BonusNotAddedSearchingForOpponent.svg'
import RewardAdded from '../../../Icons/BonusAddedSearchingForOpponent.svg'
import LowBalanceNoAd from '../../../Icons/LowBalanceNoAd.svg'
import LowBalance from '../../../Icons/LowBalance.svg'
import BonusAddedSearchingForOpponent from '../../../Icons/BonusAddedSearchingForOpponent.svg'
import socket from '../../../SocketConnectionManager';
import AdSeen from '../../../GlobalFunctions/NetworkCalls/AdSeen'

const MatchMakerLogic = (Value) => { 
    const [ isVideoOn , isVideoOnUpdate ] = useState(false)
    const [ isVideoWatched , isVideoWatchedUpdate ] = useState(null)
    const [ TransferToGameTable , TransferToGameTableUpdate ] = useState(false)
    const AD_Available = Value.AD_Available ;
    const PlayerData = Value.PlayerData;
    const QualifyingBalance = 10;
    const MatchMakerData = Value.MatchMakerData  ;
    const LayerLevel = "2"
    const DefaultBackgroundColor = ""

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const ConnectingWithMatchMakerClass = () => {
        if(Value.isExit === true && MatchMakerData === null){
            return ("flip-out-hor-top-fast")
        } else if(Value.LoaderOn === true){
            return ("flip-out-hor-top-fastest")
        } else if(MatchMakerData !== null){
            return ("slide-out-top-fast")
        } else {
            return("slide-in-left")
        }
    }

    const OpponentFoundClass = () => {
        if(AD_Available === true && isVideoWatched === null){
            if(Value.isExit === true){
                return ("flip-out-hor-top-fast")
            } else if(Value.LoaderOn === true){
                return ("flip-out-hor-top-fastest")
            } else if(MatchMakerData !== null){
                return ("slide-in-right")
            } else {
                return("flip-out-hor-top-fast")
            }
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const OpponentFoundNoAdClass = () => {
        if(AD_Available === false && isVideoWatched === null){
            if(Value.isExit === true){
                return ("flip-out-hor-top-fast")
            } else if(Value.LoaderOn === true){
                return ("flip-out-hor-top-fastest")
            } else if(MatchMakerData !== null){
                return ("slide-in-right")
            } else {
                return("flip-out-hor-top-fast")
            }
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const RewardAddedClass = () => {
        if(isVideoWatched === null){
            return ("flip-out-hor-top-fastest")
        } else if(isVideoWatched === true){
            return ("slide-in-right")
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const RewardNotAddedClass = () => {
        if(isVideoWatched === null){
            return ("flip-out-hor-top-fastest")
        } else if(isVideoWatched === false){
            return ("slide-in-right")
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const LowBalanceClass = () => {
        if(AD_Available === true && isVideoWatched === null){
            if(Value.isExit === true){
                return ("flip-out-hor-top-fast")
            } else if(Value.LoaderOn === true){
                return ("flip-out-hor-top-fastest")
            } else if(MatchMakerData !== null){
                return ("slide-in-right")
            } else {
                return("flip-out-hor-top-fast")
            }
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const LowBalanceNoAdClass = () => {
        if(AD_Available === false && isVideoWatched === null){
            if(Value.isExit === true){
                return ("flip-out-hor-top-fast")
            } else if(Value.LoaderOn === true){
                return ("flip-out-hor-top-fastest")
            } else if(MatchMakerData !== null){
                return ("slide-in-right")
            } else {
                return("flip-out-hor-top-fast")
            }
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const BonusAddedConnectingWithMatchMakerClass = () => {
        if(isVideoWatched === null){
            return ("flip-out-hor-top-fastest")
        } else if(isVideoWatched === true){
            return ("slide-in-right")
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 40
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 40
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 40
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 42
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 39
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 70,
                ImageHeight : 35,
                ImageType : "Long",
                Left : "15%",
                Top : 35
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 65,
                ImageHeight : 32.5,
                ImageType : "Long",
                Left : "17.5%",
                Top : 40
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 60,
                ImageHeight : 30,
                ImageType : "Long",
                Left : "20%",
                Top : 40
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 55,
                ImageHeight : 27.5,
                ImageType : "Long",
                Left : "22%",
                Top : 35
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 45,
                ImageHeight : 22.5,
                ImageType : "Long",
                Left : "28%",
                Top : 35
            })
        }
    }

    const TemplateValue = TemplateData()

    const DefaultSizeProvider = () => {
        if(isVideoWatched !== null){
            return({
                width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
                height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,  
            })
        } else if(MatchMakerData === null){
            return({
                width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
                height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,  
            })
        } else {
            return({
                width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
                height : `${percentage(currentSize.width , TemplateValue.ImageHeight) * 2}px`,  
            })
        }        
    } 

    const RewardButtonBackground = () => {
        if(isVideoOn === false){
            return("")
        } else if(isVideoOn === true){
            return("rgba(0,0,0,0.5)")
        }
    }

    const ButtonRewardProvider = () => {
        const ButtonSize = {
            Width :  percentage(currentSize.width , TemplateValue.ImageWidth)/1.25,
            Height : percentage(currentSize.width , TemplateValue.ImageHeight)/1.45
        }
            return({
                width : `${percentage(ButtonSize.Width, 109)}px`,   
                height : `${percentage(ButtonSize.Height, 70)}px`,
                left : `${percentage(ButtonSize.Width, 8)}px`,   
                top : "94%",  
                position : "absolute",
                zIndex : 3,
                borderRadius : `${percentage(ButtonSize.Width, 3)}px`,
            })
        
    }

    const ButtonRewardLowBalanceProvider = () => {
        const ButtonSize = {
            Width :  percentage(currentSize.width , TemplateValue.ImageWidth),
            Height : percentage(currentSize.width , TemplateValue.ImageHeight)
        }

        return({
            width : `${percentage(ButtonSize.Width, 73)}px`,   
            height : `${percentage(ButtonSize.Height, 43)}px`,
            left : `${percentage(ButtonSize.Width, 13.5)}px`,   
            top : "39%",  
            position : "absolute",
            zIndex : 3,
            backgroundColor : RewardButtonBackground(),
            borderRadius : `${percentage(ButtonSize.Width, 3)}px`,   
        })
    }

    const ButtonRewardFreeBalanceProvider = () => {
        const ButtonSize = {
            Width :  percentage(currentSize.width , TemplateValue.ImageWidth),
            Height : percentage(currentSize.width , TemplateValue.ImageHeight)
        }

        return({
            width : `${percentage(ButtonSize.Width, 92)}px`,   
            height : `${percentage(ButtonSize.Height, 43)}px`,
            left : `${percentage(ButtonSize.Width, 4)}px`,   
            top : "39%",  
            position : "absolute",
            zIndex : 3,
            backgroundColor : RewardButtonBackground(),
            borderRadius : `${percentage(ButtonSize.Width, 3)}px`,   
        })
    }
    

    
    const DefaultTop = TemplateValue.Top;

    const isLoaded = TemplateValue.Left;

    const DefaultSize = DefaultSizeProvider()
    
    const CheckAD = () => {
        isVideoOnUpdate(true)
        window.MX.AdsManager.showRewardedAd({
            autoPlayed : 1, 
            position : "start" 
         }
         , function(data){
            if (data.status === 0) {
                AdSeen(function(Data){})
                isVideoWatchedUpdate(true)
                TransferToGameTableUpdate(true)
            } else {
                if(PlayerData.FreeModeWallet.Balance >= QualifyingBalance){                    
                    isVideoWatchedUpdate(false)
                    TransferToGameTableUpdate(true)
                } else {                    
                    isVideoOnUpdate(false)
                    isVideoWatchedUpdate(null)
                }                
            }            
        })

        window.MX.onTrack("gameAdClicked",{
            autoPlayed : 1, 
            position : "start"
        });
    }

    const CheckAD_Disabled = () => {
        isVideoWatchedUpdate(false)
        TransferToGameTableUpdate(true)
    }


    const PlayNowFree = () => {
        isVideoWatchedUpdate(true)
        TransferToGameTableUpdate(true)
    }

    const StartTransfer = () => {
        useSetTimeout(() => {
            socket.emit('Join-Table-Request')
        }, 100)
        return(
            <div></div>
        )
    }

    return(<motion.div 
        style={{
            position : "absolute",
            zIndex : LayerLevel,       
            width :  DefaultSize.width,   
            height : DefaultSize.height,              
            top : `${percentage(currentSize.height ,DefaultTop)}px`,
            backgroundColor : DefaultBackgroundColor ,
            left : isLoaded
        }}>
            { PlayerData.FreeModeWallet.Balance >= QualifyingBalance &&            
                <motion.div>            
                    <motion.div 
                        className={ConnectingWithMatchMakerClass()}
                        style={{
                            position : "absolute",
                            zIndex : 1,
                            width : "100%",
                            top : "-40%",
                            textAlign : "center"
                        }}>
                                <object                                 
                                style={{
                                    width : `${percentage(currentSize.width , 50)}px`,   
                                    left :  `${percentage(currentSize.width , 25)}px`,                  
                                }} type="image/svg+xml" data={ConnectingWithMatchMaker}>Floor</object>  

                                <motion.div>
                                    <img className='rotate-in-center-animation'
                                        style={{
                                            width : "20%"
                                        }}
                                        src={Loader} alt="Loader" />
                                </motion.div>  
                            
                    </motion.div>

                    <object 
                    className={OpponentFoundClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%",
                        top : "-40%"
                    }} type="image/svg+xml" data={OpponentFound}>Floor</object>  

                    <object 
                    className={OpponentFoundNoAdClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%",
                        top : "-40%"
                    }} type="image/svg+xml" data={OpponentFoundNoAd}>Floor</object>  

                    <object 
                    className={RewardAddedClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%",
                    }} type="image/svg+xml" data={RewardAdded}>Floor</object>  

                    <object 
                    className={RewardNotAddedClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%",
                    }} type="image/svg+xml" data={RewardNotAdded}>Floor</object>  

                    { MatchMakerData !== null && AD_Available === true && isVideoWatched === null &&
                        <motion.div 
                        onTapStart={CheckAD}
                        style={ButtonRewardProvider()}>
                        </motion.div>
                    }

                    { MatchMakerData !== null && AD_Available === false && isVideoWatched === null &&
                        <motion.div 
                        onTapStart={CheckAD_Disabled}
                        style={ButtonRewardProvider()}>
                        </motion.div>
                    }
                </motion.div>    
            }

            { PlayerData.FreeModeWallet.Balance < QualifyingBalance &&            
                <motion.div> 

                    <motion.div 
                        className={ConnectingWithMatchMakerClass()}
                        style={{
                            position : "absolute",
                            zIndex : 1,
                            width : "100%",
                            top : "-40%",
                            textAlign : "center"
                        }}>
                                <object                                 
                                style={{
                                    width : `${percentage(currentSize.width , 50)}px`,   
                                    left :  `${percentage(currentSize.width , 25)}px`,                  
                                }} type="image/svg+xml" data={ConnectingWithMatchMaker}>Floor</object>  

                                <motion.div>
                                    <img className='rotate-in-center-animation'
                                        style={{
                                            width : "20%"
                                        }}
                                        src={Loader} alt="Loader" />
                                </motion.div>  
                            
                    </motion.div>
 

                    <object           
                    className={LowBalanceClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%"
                    }} type="image/svg+xml" data={LowBalance}>Floor</object>  

                    <object           
                    className={LowBalanceNoAdClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%"
                    }} type="image/svg+xml" data={LowBalanceNoAd}>Floor</object>  

                    { MatchMakerData !== null && AD_Available === true && isVideoWatched === null &&
                        <motion.div 
                        onTapStart={CheckAD}
                        style={ButtonRewardLowBalanceProvider()}>
                        </motion.div>
                    }

                    { MatchMakerData !== null && AD_Available === false && isVideoWatched === null &&
                        <motion.div 
                        onTapStart={PlayNowFree}
                        style={ButtonRewardFreeBalanceProvider()}>
                        </motion.div>
                    }

                    <object 
                    className={BonusAddedConnectingWithMatchMakerClass()}
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : "100%",
                    }} type="image/svg+xml" data={BonusAddedSearchingForOpponent}>Floor</object>  
                </motion.div>    
            }          
            

            { TransferToGameTable === true &&
                < StartTransfer />
            }
    </motion.div>)
}

export default MatchMakerLogic