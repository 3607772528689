import React from 'react';
import { motion } from 'framer-motion';

const LifeCount = (Values) => {
    const BlockSize = Values.BlockSize;   
    const PlayerValues = Values.PlayerValues ;

    function percentage(num, per)
    {
            return (num/100)*per;
    }

    const LeftValue = () => {
        if(Values.isLeft === true){
            return("4%")
        } else {
            return("76%")
        }
    }

    const TopValue = () => {
        if(Values.isLeft === true){
            return(`${percentage(BlockSize , 34)}px`)
        } else {
            return(`${percentage(BlockSize , 34)}px`)
        }
    }

    return (<motion.div 
     
    style={{
        position : "absolute",
        width : `${percentage(BlockSize , 30)}px`,
        height : `${percentage(BlockSize , 30)}px`,
        textAlign : "center",
        fontSize : `${percentage(BlockSize , 15)}px`,
        top : TopValue(),
        zIndex : "20",
        left : LeftValue(),
        color : "#FDA80D",
        backgroundColor : "rgba(0,0,0,0.9)",
        borderRadius : "50%"
    }}>
        <div style={{
            height : `${percentage(BlockSize , 5)}px`,
        }}></div>
        { PlayerValues.RemainingLife }
    </motion.div>)
}

export default LifeCount