import React , { useState , useEffect } from 'react';
import socket from '../../../../SocketConnectionManager';
import { motion } from 'framer-motion';
import IsGamerAccess from '../../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import PlayerControlDisplay from './PlayerControlDisplay'

const Section = () => {
    const [ GameData , GameDataUpdate ] = useState(null)
    const [ GamerData , GamerDataUpdate ] = useState(null)
    const [ SoundEffectStatus , SoundEffectUpdate ] = useState(true)
    const [ TimerValue , TimerValueUpdate ] = useState(20);
    const [ PlayerTurn , PlayerTurnUpdate ] = useState(null)

    useEffect(() => {
        socket.on('GameStateUpdate', function (Data){
            GameDataUpdate(Data)

            if(Data.PlayerOne.Turn === true){
                var ThisTimerValue = Data.PlayerOne.TimerValue;
    
                if(ThisTimerValue <= 0){
                    ThisTimerValue = 20
                }

                if(PlayerTurn !== Data.PlayerOne.Turn){
                    PlayerTurnUpdate(Data.PlayerOne.Turn)
                }
    
                TimerValueUpdate(ThisTimerValue)
            } else {
                if(PlayerTurn !== Data.PlayerOne.Turn){
                    PlayerTurnUpdate(Data.PlayerOne.Turn)
                }
            }
        });

        if(GamerData === null){
            IsGamerAccess(function(Data){
                GamerDataUpdate(Data)
            })
        }

        socket.on('onStopMusicPlayerControl' , function (){
            SoundEffectUpdate(false)
        })
    
        socket.on('onStartMusicPlayerControl' , function (){
            SoundEffectUpdate(true)
        })

        

        return () => {
            socket.off('GameStateUpdate');
        }
    },[ GameData , GamerData, PlayerTurn])

    return(<motion.div>
        {  GameData !== null && GamerData !== null && GameData.PlayerOne.TotalCards !== null &&
            < PlayerControlDisplay 
            GameState = { GameData } 
            GamerData = { GamerData } 
            TimerValue = { TimerValue }
            PlayerTurn = { PlayerTurn }
            SoundEffectStatus = { SoundEffectStatus } /> 
        }
    </motion.div>)
}

export default Section