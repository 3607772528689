import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 

const Logic = () => {   

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    

    const currentSize = SizeInfo();



    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }
    

    
    return (
        <motion.div
        className='slide-in-top'
        style={{
            "height" : `${Dimensions.height}px`,
            "width": `${Dimensions.width}px`,
            "position" : "fixed",
        }}>

        </motion.div>
    )
    
}

export default Logic