import React , { useState , useEffect } from 'react';
import useResize from 'use-resize';
import { Redirect } from 'react-router';
import IsGamerProfile from '../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import MatchMakerLogic from './MatchMakerLogic';
import MatchMakerExit from './MatchMakerExit';
import socket from '../../../SocketConnectionManager';
import useSetTimeout from 'use-set-timeout';
import { motion } from "framer-motion";
import Loader from '../../../Icons/Loading.svg'
import AdNotSeen from '../../../GlobalFunctions/NetworkCalls/AdNotSeen'
import InitializingMatchMaker from '../../../Icons/InitializingMatchMaker.svg'

const MatchMaker = () => {    
    const [ LoaderIconStatus , LoaderIconStatusUpdate ] = useState(false)
    const [ AD_Available , AD_AvailableUpdate ] = useState(null)
    const [ RedirectProperty , RedirectPropertyUpdate ] = useState(false);
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }    

    const currentSize = SizeInfo();
    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    const MX = window.MX;
    const [ PlayerData , PlayerDataUpdate ] = useState(null) ;
    const [ isExitToProfile , isExitToProfileUpdate ] = useState(false)
    const [ MatchMakerData , MatchMakerDataUpdate ] = useState(null)
    const [ LoaderOn , LoaderOnUpdate ] = useState(null)
    const [ CurrentTable , CurrentTableUpdate ] = useState(null)
    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    

    useEffect(() => {
        socket.on('TableAccess' , function(Data){
            MatchMakerDataUpdate(Data)
        })   
        
        if(PlayerData === null){
            PlayerDataUpdate("FETCH")
            IsGamerProfile(function (data){
                LoaderOnUpdate(true)
                PlayerDataUpdate(data)
                if(data.CurrentTable !== null){
                    if(data.CurrentTable.Joined === true){
                        CurrentTableUpdate(true)
                    } else {
                        CurrentTableUpdate(false)
                    }
                } else {
                    CurrentTableUpdate(false)
                }
            })
        }

        socket.on('JoinTable' , function(){
            MX.notifyGameState(MX.GameState.Started);
            RedirectPropertyUpdate(true)
        })   

        socket.on('CheckForAD' , function(){
            AdNotSeen(function (Data){})
            window.MX.AdsManager.checkRewardedAdExists(function(result){
                if (result.status === 0) {
                    socket.emit('MatchMakingRequest' , PlayerData._id)
                    LoaderOnUpdate(false)
                    AD_AvailableUpdate(true)
                } else {
                    socket.emit('MatchMakingRequest' , PlayerData._id)
                    LoaderOnUpdate(false)
                    AD_AvailableUpdate(false)
                }     
            });
        })

        socket.on("ExitToProfile" , function(){
            isExitToProfileUpdate(true)
        });        
        
        return () => {
            socket.off('ExitToProfile');
            socket.off("TableAccess");
            socket.off('CheckForAD');
            socket.off('JoinTable')
        }
    },[ PlayerData , MatchMakerData, CurrentTable, MX])

    
            

    const ExitToProfile = () => {
        const [ Timer , TimerUpdate ] = useState(false)        
        useSetTimeout (() => { 
            TimerUpdate(true) 
        }, 500);


        return (<div style={{
            position : "absolute",
            width :  `${percentage(currentSize.width , 100)}px`,
            top : "50%"
        }}>
            { Timer === true && 
                <Redirect to={'/freetoplay/profile'} />
            }    
        </div>)
    }

    const LoaderManager = () => {
        useSetTimeout(() => {
            socket.emit('Check-AD-Status')
        }, 1000)
        return(
            <div></div>
        )
    }

    const Launcher = () => {
        const [ Timer , TimerUpdate ] = useState(false)        
        useSetTimeout (() => { TimerUpdate(true) }, 2000);


        return (<div style={{
            position : "absolute",
            width :  "100%",
            bottom : "10%",
            textAlign : "center"
        }}>
            { Timer === true &&
                <Redirect to={'/freetoplay/gametable'} />
            }   

            <motion.div>
                <img className='rotate-in-center-animation'
                    style={{
                        width : "20%"
                    }}
                    src={Loader} alt="Loader" />
            </motion.div>         
        </div>)
    }


    const LoaderIconLoaded = () => {
        LoaderIconStatusUpdate(true)
    }

    const LoaderIconClass = () => {
        if(LoaderIconStatus === true && AD_Available === null){
            return("slide-in-bck-right")
        } else if(LoaderIconStatus === true && AD_Available !== null){
            return ("flip-out-hor-top")
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }
    
    return (<div style={{
        height : `${Dimensions.height}px`,
        width : `${Dimensions.width}px`,
        position : "fixed",   
    }}>  
        
        { LoaderOn === true && < LoaderManager /> }

        <motion.div 
        className={ LoaderIconClass() }
        style={{
            position : "absolute",
            textAlign : "center",
            color : "#FDA80D",
            width : `${percentage(currentSize.width , 50)}px`,
            height : `${percentage(currentSize.width , 24)}px`,
            left : `${percentage(currentSize.width , 25)}px`,
            top : `${percentage(currentSize.height , 50)}px`, 
        }}>
                <object 
                onLoad={ () =>LoaderIconLoaded ()  }
                style={{
                    width : `${percentage(currentSize.width , 50)}px`,                    
                }} type="image/svg+xml" data={InitializingMatchMaker}>Floor</object>  

                <motion.div>
                    <img className='rotate-in-center-animation'
                        style={{
                            width : "20%"
                        }}
                        src={Loader} alt="Loader" />
                </motion.div>  
            
        </motion.div>
        

        { (PlayerData !== null && PlayerData !== "FETCH") && LoaderOn !== null && CurrentTable === false &&
        <div 
            style={{
                "height" : `${Dimensions.height}px`,
                "width": `${Dimensions.width}px`,
                "position" : "fixed", 
            }}>
                < MatchMakerExit  LoaderOn={ LoaderOn } isExit = { isExitToProfile }/>

                < MatchMakerLogic LoaderOn={ LoaderOn } 
                        isExit = { isExitToProfile } 
                        AD_Available = { AD_Available }
                        PlayerData = { PlayerData }
                        MatchMakerData = { MatchMakerData } />
        </div>
        }
        
        { isExitToProfile === true && CurrentTable === false && < ExitToProfile /> }

        { RedirectProperty === true && CurrentTable === false && < Launcher /> }

        { CurrentTable === true && <Redirect to={'/freetoplay/gametable'} /> }
        
    </div>)
    
}

export default MatchMaker