import React , { useState } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Config from '../../../GlobalFunctions/Config';
import socket from '../../../SocketConnectionManager';
import Icon from '../../../Icons/TutorialInfoBarDisplay.svg'
import IconTwo from '../../../Icons/AccessingTutorial.svg'

const TutorialButton = (Data) => {
    const [ RedirectOn , RedirectOnUpdate ] = useState(false)
    const LayerLevel = "2"
    const DefaultBackgroundColor = ""

    const ActiveClass = () => {
        if(Data.isExit === false || RedirectOn === true){
            return("")
        } else if(Data.isExit === true){
            return("flip-out-hor-top-fast")
        }
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 30
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 27
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 27
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 27
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 27
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 53),
                ImageType : "Long",
                Left : "21%",
                Top : 27
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 50,
                ImageHeight : percentage(50 , 53),
                ImageType : "Long",
                Left : "24%",
                Top : 27
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 40,
                ImageHeight : percentage(40 , 53),
                ImageType : "Long",
                Left : "30%",
                Top : 25
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 40,
                ImageHeight : percentage(40 , 53),
                ImageType : "Long",
                Left : "30%",
                Top : 25
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 35,
                ImageHeight : percentage(35 , 53),
                ImageType : "Long",
                Left : "32%",
                Top : 23
            })
        }
    }

    const TemplateValue = TemplateData()

    const  DefaultSize = {
        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
        height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,    
    }

    const DefaultTop = TemplateValue.Top

    const isLoaded  = TemplateValue.Left

    const ButtonClicked = () => {        
        RedirectOnUpdate(true)
        Config.AssetLoadedStatus = true
        socket.emit('Transfer-To-Tutorial')
    }


    const AnimateValue = () => {
        if(RedirectOn === false){
            return ({
                x : 0 , y : 0, scale : 1, opacity : 1
            })
        } else if(RedirectOn === true){
            return ({
                x : 0 , y : 0, scale : 0, opacity : 0
            })
        }
    }

    const RedirectAnimateValue = () => {
        if(RedirectOn === true){
            return ({
                x : 0 , y : 0, scale : 0.5, opacity : 1
            })
        } else if(RedirectOn === false){
            return ({
                x : 0 , y : 0, scale : 0, opacity : 0
            })
        }
    }

    
    return (
        <motion.div 
        className={ActiveClass()}
        style={{
            position : "absolute",
            zIndex : LayerLevel,       
            width :  DefaultSize.width,   
            height : DefaultSize.height,              
            top : `${percentage(currentSize.height ,DefaultTop)}px`,   
            backgroundColor : DefaultBackgroundColor ,
            left : isLoaded
        }}>   



            <motion.div 
                initial={{ x : 0 , y : 0 }}
                animate={AnimateValue()}
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth) * 1.75}px`,
                    height :  `${percentage(currentSize.width , TemplateValue.ImageHeight) * 1.6}px`,
                    left : `${0 - percentage(currentSize.width , TemplateValue.ImageWidth) /2.9 }px`,
                    top : `${0 - percentage(TemplateValue.ImageWidth , 80)}px`,
                    transform : "translate(-50%, -0%)",
                }}>
                    <object 
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width : percentage(currentSize.width , TemplateValue.ImageWidth) * 1.75
                    }} type="image/svg+xml" data={Icon}>Floor</object>  
            </motion.div>  

            { RedirectOn === false &&
                <motion.div  
                initial={{ x : 0 , y : 0 }}
                onTapStart={ButtonClicked}
                    style={{
                        position : "absolute",
                        zIndex : "1",
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth) * 1.75}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageHeight) * 1.6}px`,
                        left : `${0 - percentage(currentSize.width , TemplateValue.ImageWidth) /2.9 }px`,
                        top : `${0 - percentage(TemplateValue.ImageWidth , 80)}px`,
                        transform : "translate(-50%, -0%)",
                    }}>
                </motion.div>  
            }
                        
            <motion.div 
                initial={{ x : 0 , y : 0 }}
                animate={RedirectAnimateValue()}
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth) * 1.75}px`,
                    height :  `${percentage(currentSize.width , TemplateValue.ImageHeight) * 1.6}px`,
                    left : `${0 - percentage(currentSize.width , TemplateValue.ImageWidth) /2.9 }px`,
                    top : `${0 - percentage(TemplateValue.ImageWidth , 80)}px`,
                    transform : "translate(-50%, -0%)",
                }}>
                    <object 
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width : percentage(currentSize.width , TemplateValue.ImageWidth) * 1.75
                    }} type="image/svg+xml" data={IconTwo}>Floor</object>  
            </motion.div>  

                          
        </motion.div>
    )
    
}

export default TutorialButton