import React  from 'react';
import useResize from 'use-resize'; 
import OpponentBlock from './OpponentSection';
import { motion } from 'framer-motion';
import TableTemplate from './TableTemplate';
import MessageBoard from '../../../Icons/MessageBoard.svg'

const Section = (Data) => {
    const GameData = Data.GameData ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()

    const LeftValue = () => {
       return ((100 - TemplateValue.ImageWidth) / 2)
    }

    const animateValue = () => {
            return ({                
                position : "absolute",
                zIndex : "2",       
                width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
                height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
                left : percentage(currentSize.width , LeftValue()),                                        
                transform : "translate(-50%, -0%)",
                bottom : TemplateValue.BottomValue,     
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20                                        
            })
    }

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }

    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const MessageBoardPositionProviderMessage = {
        position : "absolute",
        top : `${0 - percentage(BoardWidth , 6.5)}px`,
        left : `${0 - percentage(BoardWidth , 10)}px`,
        color : "black",
        fontSize : `${percentage(BoardWidth , 2.5)}px`,
        width : `${percentage(BoardWidth , 25)}px`,
        textAlign : "center"
    }

    const MessageBoardPositionProviderBoard = {
        position : "absolute",
        top : `${0 - percentage(BoardWidth , 10)}px`,
        left : `${0 - percentage(BoardWidth , 10)}px`,
        width : `${percentage(BoardWidth , 25)}px` 
    }

    const GameStartingAnimation = () => {
        if(GameData !== null &&
            GameData.CardDistribution === null && GameData.BootDeduction !== false){
                return ({ x : 0 , y : 0})
        } else {
            return ({ x : 0 - currentSize.width , y : 0})
        }
    }

    const NewBluffRoundAnimation = () => {
        if(GameData !== null &&
            GameData.CurrentBluff === null && GameData.CurrentChallenge === null &&
            ( GameData.CardDistribution === false || GameData.BootDeduction === false ) &&
            GameData.Winner !== true){
                return ({ x : 0 , y : 0})
            } else {
                return ({ x : 0 - currentSize.width , y : 0})
            }
    }

    const ClaimChallengedAnimation = () => {
        if(GameData !== null &&
            GameData.CurrentBluff === null && GameData.CurrentChallenge !== null &&
            ( GameData.CardDistribution === false || GameData.BootDeduction === false )){
                return ({ x : 0 , y : 0})
            } else {
                return ({ x : 0 - currentSize.width , y : 0})
            }
    }

    if(GameData !== null && PlayerData() !== null){
    return (<motion.div 
        style={ animateValue() }>                    
                
                <motion.div 
                initial={{ opacity : 0 }}
                animate={{ opacity : 1 }}
                transition={{ delay : 1 }}
                >
                    < motion.div style={{
                        position : "absolute",
                        left : "35%",   
                        transform : "translate(-50%, -0%)",
                        top : "68%"           
                    }}>
                        < OpponentBlock BoardHeight = { BoardHeight } 
                            BoardWidth = { BoardWidth }  
                            Seat={1} 
                            PlayerValues = { PlayerData() }  />
                    </motion.div>
                    
                
                    < motion.div style={{
                        position : "absolute",
                        left : "-5%",   
                        transform : "translate(-50%, -0%)",
                        top : `${percentage(BoardHeight , 12)}px`,
                    }}>
                        < OpponentBlock BoardHeight = { BoardHeight }
                            BoardWidth = { BoardWidth }  
                            Seat={2} 
                            PlayerValues = { PlayerTwo() } />
                    </motion.div>

                    < motion.div style={{
                        position : "absolute",
                        left : "35%",   
                        transform : "translate(-50%, -0%)",
                        top : "-15%"
                    }}>
                        < OpponentBlock BoardHeight = { BoardHeight } 
                            BoardWidth = { BoardWidth } 
                            Seat={3} 
                            PlayerValues = { PlayerThree() }  />
                    </motion.div>
                    

                    < motion.div style={{
                        position : "absolute",
                        left : "75%",   
                        transform : "translate(-50%, -0%)",
                        top : "12%" 
                    }}>
                        < OpponentBlock BoardHeight = { BoardHeight } 
                            BoardWidth = { BoardWidth }  
                            Seat={4} 
                            PlayerValues = { PlayerFour() }  />
                    </motion.div>

                </motion.div>
                
                                     
                <motion.div 
                initial={{
                     x : 0 - currentSize.width , y : 0
                }}
                animate={ GameStartingAnimation() }>                     
                    <img src={ MessageBoard} alt="MessageBoard" style={
                            MessageBoardPositionProviderBoard
                    }/>
                    

                    <div style={ MessageBoardPositionProviderMessage }>
                        <div>STARTING</div> 
                        <div>GAME</div>
                    </div>
                </motion.div> 
                

                
                <motion.div 
                initial={{
                    x : 0 - currentSize.width , y : 0
                }}
                animate={ NewBluffRoundAnimation() }>                        
                    <img src={ MessageBoard} alt="MessageBoard" style={
                        MessageBoardPositionProviderBoard
                    }/>
                    

                    <div style={ MessageBoardPositionProviderMessage }>
                        <div>STARTING NEW</div> 
                        <div>BLUFF ROUND</div>
                    </div>
                </motion.div>                        
                

                
                <motion.div 
                initial={{
                    x : 0 - currentSize.width , y : 0
                }}
                animate={ ClaimChallengedAnimation() }>   
                    <img src={ MessageBoard} alt="MessageBoard" style={
                        MessageBoardPositionProviderBoard
                    }/>
                    

                    <div style={ MessageBoardPositionProviderMessage }>
                        <div>CLAIM</div> 
                        <div>CHALLENGED</div>
                    </div>
                </motion.div>
                
            </motion.div>)
    } else {
        return(<motion.div>
            
        </motion.div>)
    }

}

export default Section