import React , { useState , useEffect } from 'react';
import { motion } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import useResize from 'use-resize'; 
import socket from '../../../SocketConnectionManager';
import GameTableEngine from '../GameAssets/Table';
import Bluffer from '../GameAssets/Bluffer'
import Timer from '../GameAssets/Timer'
import Challenger from '../GameAssets/Challenger'
import BootCollectionAnimation from '../GameAssets/BootCollectionAnimation'
import CardDistributionAnimation from '../GameAssets/CardDistributionAnimation'
import CardPileDiscardedAnimation from '../GameAssets/CardPileDiscardedAnimation'
import CardPileAnimationHandler from '../GameAssets/CardPileAnimationHandler'
import SeriesRunning from '../GameAssets/SeriesRunning'
import ChallengeScreen from '../GameAssets/ChallengeScreen'
import TopPanelProvider from '../GameAssets/TopPanel/TopPanelProvider'
import IsGamerProfile from '../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import EvictedMessage from '../GameAssets/EvictedMessage';
import EvictedMainPlayerLogic from './EvictedMainPlayerLogic'
import ConnectingWithOpponentsMessage from '../GameAssets/ConnectingWithOpponents';
import DisconnectedMessage from '../GameAssets/DisconnectionMessage';
import useSetTimeout from 'use-set-timeout';
import Config from '../../../GlobalFunctions/Config';

const Section = () => {    
    const [ isDisconnected , isDisconnectedUpdate ] = useState(null);
    const [ Connected , ConnectedUpdate ] = useState(null);
    const [ isEvicted , isEvictedUpdate ] = useState(null);
    const [ isTable , isTableUpdate ] = useState(null);
    const [ TableData , TableDataUpdate ] = useState(null);
    const [ LeaveTable , LeaveTableUpdate ] = useState(false)

    const MX = window.MX;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    useEffect(() => {
        if(Connected === false){            
            IsGamerProfile(function (data){
                if(data.CurrentTable !== null){
                    socket.emit('MatchMakingAccessRequest' , {
                        User : data._id
                    });
                } else {
                    ConnectedUpdate(true)
                    isEvictedUpdate(true);
                }
                
            })
        }

        socket.on('AccessGranted' , function (){
            if(Connected !== true){
                ConnectedUpdate(true);
            }

            IsGamerProfile(function (data){
                socket.emit('UserConnectedToTable' , data._id);
            })
        })
        
        socket.on('GameStateUpdateMain', function (Data){
            isTableUpdate(true)
            TableDataUpdate(Data)
            if(isEvicted !== null){
                isEvictedUpdate(null);
            }
            
            if(isDisconnected === true){
                isDisconnectedUpdate(null);
            }

            if(Connected !== true){
                ConnectedUpdate(true);
            }
        })
    
        socket.on('disconnect' , function (){
            if(isDisconnected === null){
                isEvictedUpdate(null)
                isDisconnectedUpdate(true);
            }
        })
    
        socket.on('Evicted' , function (){
            if(isEvicted === null && Connected !== null){
                isDisconnectedUpdate(null);
                isEvictedUpdate(true);
            }
        })

        
        socket.on('connect' , function(){
            if(Connected === true){
                isDisconnectedUpdate(null);

                IsGamerProfile(function (data){                    
                    if(data.CurrentTable !== null){
                        socket.emit('UserConnectedToTable' , data._id);
                    } else {
                        isEvictedUpdate(true);
                    }
                    
                })
            }            
        })

        return () => {
            socket.off('connect')
            socket.off('AccessGranted');
            socket.off('disconnect');
            socket.off('Evicted');
            socket.off('GameStateUpdateMain');
        }
    },[Connected , isDisconnected, isEvicted , TableData, MX])

    const EvictFromTable = () => {
        if(Config.isPayloadSent === true){
            LeaveTableUpdate(false)
        } else {
            MX.notifyGameState(MX.GameState.Close, {
                adGameStartOpportunity : 1,
                adGameEndOpportunity : 0,
                adGamePowerupClaimed : 0,
                score: 0,
                lastLevel: 1,
                result: "quit",
                useCumulatedScoring : true,
            });
        }
    }
    
    const SetUpConnection = () => {        
        useSetTimeout(() => { 
            ConnectedUpdate(false)
        }, 1500)

        return(<div></div>)
    }

    const AnimateValue = () => {
        if(Config.FirstTimeLoad === null){
            Config.FirstTimeLoad = true
            return ({
                delay : 2.5
            })
        } else {
            return ({
                delay : 0.5
            })
        }
    }

    
    
    return (
        <motion.div>
            <motion.div 
            initial={{ scale : 1}}
            animate={{
                scale : 0
            }}
            transition={AnimateValue()}
            style={{
                position : "absolute",
                width : "100%",
                textAlign : "center",
                top : "50%",
                color : "#FDA80D",
                fontSize : "1%" 
            }}>SETTING UP TABLE....</motion.div>
            <motion.div initial={{ x : currentSize.width  }}
                animate={{ x : 0 }}
                transition={AnimateValue()}
                >  
                    { Connected === null && <SetUpConnection /> }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&      
                        < TopPanelProvider GameData = {TableData} />
                    }
                        
                    { isTable === null && TableData === null  && isEvicted === null && Connected === true &&         
                        < GameTableEngine GameData = {null} />
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&           
                        < GameTableEngine GameData = {TableData} />     
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < Timer GameData = {TableData} /> 
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < Bluffer GameData = {TableData} />     
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < SeriesRunning GameData={ TableData } /> 
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < Challenger GameData = {TableData} /> 
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < CardPileAnimationHandler GameData = {TableData} />     
                    }

                    { isTable === true && TableData !== null && isEvicted === null &&
                        TableData.CardPileDiscarded === true && Connected === true &&
                        < CardPileDiscardedAnimation GameData = {TableData} />     
                    }

                    { isTable === true && TableData !== null && isEvicted === null && Connected === true &&
                        < ChallengeScreen GameData = {TableData} />     
                    }

                    { TableData !== null && TableData.DeductBoot === true && TableData.CardDistribution === null && Connected === true &&
                        < BootCollectionAnimation /> 
                    }

                    { TableData !== null && TableData.DeductBoot === false && TableData.CardDistribution === true && Connected === true &&
                        < CardDistributionAnimation /> 
                    }

                    { TableData !== null && TableData.PlayerOne.RemainingLife === 0 && Connected === true &&
                        <motion.div 
                        style={{
                            position : "absolute",
                            zIndex : "400",
                            height : "100%",
                            width : "100%",
                        }}>
                            < EvictedMainPlayerLogic GameData={ TableData } />
                        </motion.div>            
                    }

                    
                    { isDisconnected === true && Connected === true &&
                        <motion.div style={{
                            position : "absolute",
                            zIndex : "400",
                            height : "100%",
                            width : "100%",
                            backgroundColor : "rgba(0,0,0,0.8)"
                        }}>
                            < DisconnectedMessage />
                        </motion.div>
                    }

                    { isDisconnected === null && isEvicted === true && Connected === true &&
                        <motion.div 
                        
                        style={{
                            position : "absolute",
                            zIndex : "400",
                            height : "100%",
                            width : "100%",
                        }}>
                            <motion.div 
                            onTapStart={EvictFromTable}
                            style={{
                                position : "absolute",
                                zIndex : "3",
                                height : "100%",
                                width : "100%",
                            }}>

                            </motion.div>

                            < EvictedMessage />

                            <motion.div 
                            style={{
                                position : "absolute",
                                zIndex : "1",
                                height : "100%",
                                width : "100%",
                                backgroundColor : "rgba(0,0,0,0.7)"
                            }}>
                            </motion.div>

                            
                        </motion.div>
                    }

                    { Connected !== true && isDisconnected === null && isEvicted === null &&
                        <motion.div 
                        style={{
                            position : "absolute",
                            zIndex : "400",
                            height : "100%",
                            width : "100%",
                        }}>
                            < ConnectingWithOpponentsMessage />
                        </motion.div>
                    }

                    { LeaveTable === true && < Redirect to="/freetoplay/profile" /> }

                </motion.div>
        </motion.div>
        
    )
}

export default Section