import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/BluffBottomPanel.svg'

const Button = () => {
    const [ IconRendered , IconRenderedUpdate ] = useState(null)
    const IconLoaded = () => {
        IconRenderedUpdate(true)
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    return (
        <motion.div
        style={{
            position : "absolute",
            bottom : "0%",
            left : `${0 - percentage(BoardWidth , 10)}px`,
            width : `${percentage(BoardWidth , 120)}px`,
            height : `${percentage(BoardWidth , 32)}px`,                                           
            }}>
            <object onLoad={() => IconLoaded() } style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  

        { IconRendered  === null &&
            <img 
            style={{
                width :  `100%`,
                position : "absolute",
                zIndex : 2,
                left : "50%",
                top : "50%",
                transform : "translate(-50%, -50%)",                            
            }}
            src={Icon} alt="Loader" />
        }   
        </motion.div>
    )
}

export default Button