import React , { useState } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Icon from '../../../Icons/ProfileInfoHolder.svg'
import PlaceHolder from '../../../Icons/ProfilePlaceHolder.svg'
import IconTwo from '../../../Icons/ProfileInfoHolderNonLoggedIn.svg'
import Config from '../../../GlobalFunctions/Config';

const ProfileInfo = (Data) => {
    const PlayerData = Data.PlayerData;
    const MX = window.MX ;   
    const [ isImageLoaded , isImageLoadedUpdate ] = useState(null)
    
    const ActiveClass = () => {
        if(Data.isExit === false){
            return("")
        } else if(Data.isExit === true){
            return("flip-out-hor-top-fast")
        }
    }

    const onProfileImageLoad = () => {
        console.log("IMAGE LOADED")
        isImageLoadedUpdate(true)
    }

    const onProfileImageLoadError = () => {
        console.log("IMAGE NOT LOADED")
        isImageLoadedUpdate(false)
    }


    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  48
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  45
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  45
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  45
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  45
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top :  45
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top : 45
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 65,
                ImageHeight : percentage(65 , 33),
                ImageType : "Long",
                Left : "17.5%",
                Top : 43
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 33),
                ImageType : "Long",
                Left : "23.5%",
                Top : 43
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 55,
                ImageHeight : percentage(55 , 33),
                ImageType : "Long",
                Left : "23.5%",
                Top : 42
            })
        }
    }

    const TemplateValue = TemplateData()

    
    const  DefaultSize  = {
        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
        height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,    
    }
    const  LayerLevel = "2"

    const  DefaultTop = TemplateValue.Top + 10

    const isLoaded = TemplateValue.Left;

    const Balance  = () => {
        if(PlayerData === null || PlayerData === "FETCH"){
            return 0
        } else if(PlayerData.FreeModeWallet.Balance <= 0){
            return 0
        } else {
            return PlayerData.FreeModeWallet.Balance
        }
    }

    const ValidateID = () => {
        if(PlayerData.isMxPlayerIDValid === true){
            
        } else {
            MX.notifyGameState(MX.GameState.Close, {
                adGameStartOpportunity : 0,
                adGameEndOpportunity : 0,
                adGamePowerupClaimed : 0,
                score: 0,
                lastLevel: 1,
                result: "quit",
                useCumulatedScoring : true,
            })
        }
    }

    if(TableType >= 1){
    return (
        <motion.div 
        className={ActiveClass()}
        style={{
            position : "absolute",
            zIndex : LayerLevel,       
            width :  DefaultSize.width,   
            height : DefaultSize.height,              
            top : `${percentage(currentSize.height ,DefaultTop)}px`,            
            left : isLoaded
        }}> 
            { PlayerData.isMxPlayerIDValid === true &&
                <motion.div
                    style={{
                        position : "absolute",
                        zIndex : "2",
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                        left : "50%",
                        transform : "translate(-50%, -0%)",
                    }}>
                        <object 
                        style={{
                            position : "absolute",
                            zIndex : 3,
                            width : percentage(currentSize.width , TemplateValue.ImageWidth)
                        }} type="image/svg+xml" data={Icon}>Floor</object>  
                </motion.div>
            }
              
            { PlayerData.isMxPlayerIDValid === false &&
                <motion.div
                    style={{
                        position : "absolute",
                        zIndex : "2",
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                        left : "50%",
                        transform : "translate(-50%, -0%)",
                    }}>
                        <object 
                        style={{
                            position : "absolute",
                            zIndex : 3,
                            width : percentage(currentSize.width , TemplateValue.ImageWidth)
                        }} type="image/svg+xml" data={IconTwo}>Floor</object>  
                </motion.div>            
            }
            
            
            <motion.div 
            style={{
                position : "absolute",
                zIndex : 3,
                top : "7%",
                left : "2.75%",
                width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                height :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                borderRadius : "50%"
            }}>     
                { Config.MxPlayerData.playerData.playerProfileData.profilePicUrl === null && PlayerData.isMxPlayerIDValid === true &&          
                    <img 
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        borderRadius : "50%"
                    }} alt="placeholder" src={PlaceHolder} />
                }

                { Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && PlayerData.isMxPlayerIDValid === true &&          
                    <img 
                    onLoad={()=> onProfileImageLoad() }
                    onError={()=> onProfileImageLoadError() }
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        borderRadius : "50%",
                        display : "none"
                    }} alt="placeholder" 
                    src={ Config.MxPlayerData.playerData.playerProfileData.profilePicUrl } />
                }

                { Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && 
                    PlayerData.isMxPlayerIDValid === true &&   
                    isImageLoaded === true &&       
                    <img 
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        borderRadius : "50%",
                    }} alt="placeholder" 
                    src={ Config.MxPlayerData.playerData.playerProfileData.profilePicUrl } />
                }

                { Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && 
                    PlayerData.isMxPlayerIDValid === true &&   
                    isImageLoaded === false &&       
                    <img 
                    style={{
                        position : "absolute",
                        zIndex : 3,
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,
                        borderRadius : "50%",
                    }} alt="placeholder" 
                    src={ PlaceHolder } />
                }
            </motion.div>
            

            { PlayerData !== null && PlayerData !== "FETCH" && PlayerData.isMxPlayerIDValid === true &&
                <motion.div       
                initial={{ skewX : -20 }}          
                style={{
                    position : "absolute",
                    zIndex : 3,
                    top : "-2%",
                    left : "33.75%",
                    fontSize : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    backgroundColor : "#2a2a2a",
                    paddingLeft : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    paddingBottom : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    borderStyle : "solid",
                    borderColor : "black",
                    borderWidth : `${percentage(currentSize.width , TemplateValue.ImageWidth)/150}px`,
                    color : "#09FFC7",                    
                    width : `${(percentage(currentSize.width , TemplateValue.ImageWidth)/20) * 12 }px`,
                    height : `${(percentage(currentSize.width , TemplateValue.ImageWidth)/16)}px`,                                   
                }}> 

                { Config.MxPlayerData.playerData.playerProfileData.userName === null &&
                    <div style={{
                        textOverflow : "ellipsis",
                        overflow : "hidden",
                        whiteSpace : "nowrap"
                    }}>
                        YOU
                    </div>
                }  

                { Config.MxPlayerData.playerData.playerProfileData.userName !== null &&
                    <div style={{
                        textOverflow : "ellipsis",
                        overflow : "hidden",
                        whiteSpace : "nowrap"
                    }}>
                        { Config.MxPlayerData.playerData.playerProfileData.userName }
                    </div>
                }                   
                   
                </motion.div>
            }


            { PlayerData !== null && PlayerData !== "FETCH" && PlayerData.isMxPlayerIDValid === true &&
                <motion.div       
                initial={{ skewX : -20 }}               
                style={{
                    position : "absolute",
                    zIndex : 3,
                    top : "34%",
                    left : "33.75%",
                    fontSize : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    backgroundColor : "black",
                    paddingLeft : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    paddingBottom : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    borderStyle : "solid",
                    borderColor : "#FDA80D",
                    borderWidth : `${percentage(currentSize.width , TemplateValue.ImageWidth)/150}px`,
                    color : "#FDA80D",
                    width : `${(percentage(currentSize.width , TemplateValue.ImageWidth)/5 ) * 2.65 }px`,
                    textAlign : "center",
                    textOverflow : "ellipsis",
                    overflow : "hidden",
                    whiteSpace : "nowrap",
                    display : "flex",
                    justifyContent : "center"
                }}>                    
                    <div style={{
                        marginRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/60}px`,
                    }}>BALANCE</div>
                    <div style={{
                        marginRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/60}px`,
                    }}>-</div>
                    <div>{ Balance() }</div>
                    <div style={{
                        fontSize : `${percentage(currentSize.width , TemplateValue.ImageWidth)/35}px`,
                        marginTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/55}px`,
                        marginLeft : `${percentage(currentSize.width , TemplateValue.ImageWidth)/70}px`,
                    }}>CHIPS</div>
                </motion.div>
            }

            { PlayerData !== null && PlayerData !== "FETCH" && PlayerData.isMxPlayerIDValid === true &&
                <motion.div                
                initial={{ skewX : -20 }}      
                style={{
                    position : "absolute",
                    zIndex : 3,
                    top : "68%",
                    left : "33.75%",
                    fontSize : `${percentage(currentSize.width , TemplateValue.ImageWidth)/30}px`,
                    backgroundColor : "#2a2a2a",
                    width : `${percentage(currentSize.width , TemplateValue.ImageWidth)/5}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageWidth)/7}px`,
                    paddingLeft : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    paddingBottom : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    textAlign : "center",
                    borderStyle : "solid",
                    borderColor : "black",
                    borderWidth : `${percentage(currentSize.width , TemplateValue.ImageWidth)/150}px`,
                    color : "#09FFC7",
                }}>                    
                    <div>MATCHES</div>
                    <div>PLAYED</div>
                    <div style={{
                        marginTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/120}px`,
                        color : "white"
                    }}>{PlayerData.MatchesPlayed}</div>
                </motion.div>
            }

            { PlayerData !== null && PlayerData !== "FETCH" && PlayerData.isMxPlayerIDValid === true &&
                <motion.div                 
                initial={{ skewX : -20 }}     
                style={{
                    position : "absolute",
                    zIndex : 3,
                    top : "68%",
                    left : "67.75%",
                    fontSize : `${percentage(currentSize.width , TemplateValue.ImageWidth)/30}px`,
                    backgroundColor : "#2a2a2a",
                    width : `${percentage(currentSize.width , TemplateValue.ImageWidth)/5}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageWidth)/7}px`,
                    paddingLeft : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingRight : `${percentage(currentSize.width , TemplateValue.ImageWidth)/20}px`,
                    paddingTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    paddingBottom : `${percentage(currentSize.width , TemplateValue.ImageWidth)/200}px`,
                    textAlign : "center",
                    borderStyle : "solid",
                    borderColor : "black",
                    borderWidth : `${percentage(currentSize.width , TemplateValue.ImageWidth)/150}px`,
                    color : "#09FFC7",
                }}>                    
                    <div>MATCHES</div>
                    <div>WON</div>
                    <div style={{
                        marginTop : `${percentage(currentSize.width , TemplateValue.ImageWidth)/120}px`,
                        color : "white"
                    }}>{PlayerData.MatchesWon}</div>
                </motion.div>
            }

            
            <motion.div
                onTapStart={ ValidateID }
                style={{
                    position : "absolute",
                    zIndex : "4",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,  
                    left : "50%",
                    transform : "translate(-50%, -0%)",
                }}>

            </motion.div>   
            
        </motion.div>
    )
    } else {
        return (<motion.div>
            Resolution Not Supported
        </motion.div>)
    }
    
}

export default ProfileInfo