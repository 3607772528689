import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 

const Wall = () => {
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width


    if(TableType >= 1){
    return (
        <motion.div 
        className='slide-in-right'
        style={{
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , 100)}px`,   
            height : `${percentage(currentSize.height , 100)}px`,              
            top : "0%",
            backgroundColor : "#0c0d18"
        }}>       
        </motion.div>
    )
    } else {
        return (<motion.div>
            Resolution Not Supported
        </motion.div>)
    }
    
}

export default Wall