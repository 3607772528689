import React from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableTemplate from './TableTemplate';
import CardPileSeatValueAnimator from './CardPileSeatValueAnimator'
import Icon from '../../../Icons/CardPile.svg'

const Mover = (Value) => {
    const GameData = Value.GameData
    const Seat = Value.Seat
    const currentSize = SizeInfo();


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20                                      
        })
    }

    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;


    return (
        <motion.div style={ animateValue() }>
            <motion.div
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,                     
                }}>


                <motion.div 
                initial={{
                    y : 0 - percentage(BoardWidth , 22.5),
                    x : 0 - percentage(BoardWidth , 12.5),
                }}
                style={{
                    width :  `${percentage(BoardWidth , 25)}px`,
                    position : "absolute",
                    zIndex : 1,
                    left : "50%",
                    top : "50%",
                    transform : "translate(-50%, -50%)",
                }}>
                <object style={{
                    width : "100%"
                }} type="image/svg+xml" data={Icon}>Table</object>             
                </motion.div>
                    
                < CardPileSeatValueAnimator GameData={GameData} Seat={ Seat } />       
            </motion.div>                      
        </motion.div>
    )

    
}

            

export default Mover