import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/MatchEnded.svg'
import IconTwo from '../../../../Icons/MatchEndedWin.svg'
import IconThree from '../../../../Icons/MatchEndedDefeat.svg'
import IconFour from '../../../../Icons/MatchEndedDefeatNoAd.svg'
import { Redirect } from 'react-router-dom';
import useSetTimeout from 'use-set-timeout';
import socket from '../../../../SocketConnectionManager';
import Config from '../../../../GlobalFunctions/Config';

const Button = (Data) => {
    const [ IconRendered , IconRenderedUpdate ] = useState(null)
    const [ RedirectSelected , RedirectSelectedUpdate ] = useState(false)
    const [ ShowDialog , ShowDialogUpdate ] = useState(null)

    const IconLoaded = () => {
        IconRenderedUpdate(true)
    }
    const MX = window.MX;
    const EmitSignalExit =  "LeaveGameNow-Bluff";
    const GameData = Data.GameData ;
    const GamerData = Data.GamerData ;
    const IsWinner = GameData.PlayerOne.Winner ;
    const isEvicted = GameData.PlayerOne.RemainingLife

    const AdClaimedValue = (Value) => {
        if(Value.CurrentTableAdSeen === true){
            return 1
        } else if(Value.CurrentTableAdSeen === false){
            return 0
        }
    }
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    
    const CheckAD = () => {
        MX.notifyGameState(MX.GameState.Result);
        
        window.MX.AdsManager.checkRewardedAdExists(function(result){
            if(result.status === 0) {
                if(IsWinner === true){
                    ShowDialogUpdate(1)
                } else {
                    ShowDialogUpdate(2)
                }                
            } else {
                if(IsWinner === true){
                    ShowDialogUpdate(1)
                } else {
                    ShowDialogUpdate(3)
                } 
            }     
        });
    }

    const RedirectorOn = () => {
        const [ isRedirected , isRedirectedUpdate ] = useState(null)

        useSetTimeout(() => {
            isRedirectedUpdate(true)
        }, 1000)
        
        return (
            <motion.div>
                { isRedirected === false && < Redirect to="/freetoplay/profile" /> }
            </motion.div>
        )
    }


    const Animater = () => {
        if(GameData.Winner === true && IconRendered === true && ShowDialog === null && isEvicted !== 0){
            return ({
                scale : 1
            })
        } else {
            if(ShowDialog !== null){
                return ({
                    scale : 0
                })
            } else {
                return ({
                    scale : 0
                })
            }            
        }        
    }

    const AnimaterTwo = () => {
        if(ShowDialog === null){
            return ({
                scale : 0
            })
        } else if(ShowDialog === 1){
            return({
                scale : 1
            })
        } else {
            return ({
                scale : 0
            })
        }     
    }

    const AnimaterThree = () => {
        if(ShowDialog === null){
            return ({
                scale : 0
            })
        } else if(ShowDialog === 2){
            return({
                scale : 1
            })
        } else {
            return ({
                scale : 0
            })
        }     
    }

    const AnimaterFour = () => {
        if(ShowDialog === null){
            return ({
                scale : 0
            })
        } else if(ShowDialog === 3){
            return({
                scale : 1
            })
        } else {
            return ({
                scale : 0
            })
        }     
    }

    const ExecuteDraw = () => {
        window.MX.AdsManager.showRewardedAd({
            autoPlayed : 1, 
            position : "end" 
         }
         , function(data){
            if (data.status === 0) {
                ReportDraw()
            } else {
                ReportDefeat()
            }
        })

        window.MX.onTrack("gameAdClicked",{
            autoPlayed : 1, 
            position : "end"
        });
    }

    const ReportWin = () => {
        RedirectSelectedUpdate(true)
        Config.isPayloadSent = true
        socket.emit(EmitSignalExit)        
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 1,
            adGameEndOpportunity : 0,
            adGamePowerupClaimed : AdClaimedValue(GamerData),
            score: 200,
            lastLevel: 1,
            result: "win", 
            useCumulatedScoring : true,
        });
    }
    
    const ReportDraw = () => {
        RedirectSelectedUpdate(true)
        Config.isPayloadSent = true
        socket.emit(EmitSignalExit)        
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 1,
            adGameEndOpportunity : 1,
            adGamePowerupClaimed : AdClaimedValue(GamerData) + 1,
            score: 0,
            lastLevel: 1,
            result: "draw", 
            useCumulatedScoring : true,
        });
    }

    const ReportDefeat = () => {
        RedirectSelectedUpdate(true)
        Config.isPayloadSent = true
        socket.emit(EmitSignalExit)        
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 1,
            adGameEndOpportunity : 1,
            adGamePowerupClaimed : AdClaimedValue(GamerData),
            score: -20,
            lastLevel: 1,
            result: "lose", 
            useCumulatedScoring : true,
        });
    }

    let callback = function (event_name, info) {
        switch (event_name) {
            case "backPressed": {
                if(IsWinner === true){
                    ReportWin()
                } else {
                    ReportDefeat()
                }
            break;
            }
            case "screenOn": {
            break;
            }
            case "screenOff": {                
            break;
            }
            case "pagePause": {                 
            break;
            }
            case "pageResume": {                
            break;
            }
            case "userPresent": {                
            break;
            }
            default:
        }
    }

    MX.listenEvent("SystemEvents", callback, this);


    return (
        <motion.div>
        <motion.div animate={ Animater() }        
        style={{
                position : "absolute",
                bottom : "44%",
                left : `${percentage(BoardWidth , 25)}px`,
                width : `${percentage(BoardWidth , 50)}px`,
                height : `${percentage(BoardWidth , 15)}px`,                   
            }}>
            <object onLoad={() => IconLoaded() } style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  

        </motion.div>

        { GameData.Winner === true && IconRendered === true && ShowDialog === null && isEvicted !== 0 &&
            <motion.div 
            onTapStart={ CheckAD }
            style={{
                    position : "absolute",
                    bottom : "44%",
                    left : `${percentage(BoardWidth , 25)}px`,
                    width : `${percentage(BoardWidth , 50)}px`,
                    height : `${percentage(BoardWidth , 16)}px`,  
                    zIndex : 2,
                }}>
            </motion.div>
        }

        { RedirectSelected === true && < RedirectorOn /> }

        <motion.div animate={ AnimaterTwo() }        
        style={{
                position : "absolute",
                bottom : "44%",
                left : `${percentage(BoardWidth , 5)}px`,
                width : `${percentage(BoardWidth , 90)}px`,
                height : `${percentage(BoardWidth , 55)}px`,
            }}>
            <object style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={IconTwo}>Table</object>  

        </motion.div>

        <motion.div animate={ AnimaterThree() }        
        style={{
                position : "absolute",
                bottom : "44%",
                left : `${percentage(BoardWidth , 5)}px`,
                width : `${percentage(BoardWidth , 90)}px`,
                height : `${percentage(BoardWidth , 55)}px`,
            }}>
            <object style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={IconThree}>Table</object>  

        </motion.div>

        <motion.div animate={ AnimaterFour() }        
        style={{
                position : "absolute",
                bottom : "44%",
                left : `${percentage(BoardWidth , 5)}px`,
                width : `${percentage(BoardWidth , 90)}px`,
                height : `${percentage(BoardWidth , 55)}px`,
            }}>
            <object style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={IconFour}>Table</object>  

        </motion.div>

        { ShowDialog === 1 &&
            <motion.div 
            onTapStart={ReportWin}
            style={{
                position : "absolute",
                bottom : "47%",
                left : `${percentage(BoardWidth , 33)}px`,
                width : `${percentage(BoardWidth , 34)}px`,
                height : `${percentage(BoardWidth , 14)}px`,
                zIndex : 2
            }}>

            </motion.div>
        }

        { ShowDialog === 3 &&
            <motion.div 
            onTapStart={ReportDefeat}
            style={{
                position : "absolute",
                bottom : "49%",
                left : `${percentage(BoardWidth , 33)}px`,
                width : `${percentage(BoardWidth , 35)}px`,
                height : `${percentage(BoardWidth , 20)}px`,
                zIndex : 2,
            }}>

            </motion.div>
        }

        { ShowDialog === 2 &&
            <motion.div 
            onTapStart={ReportDefeat}
            style={{
                position : "absolute",
                bottom : "49.5%",
                left : `${percentage(BoardWidth , 12.5)}px`,
                width : `${percentage(BoardWidth , 35)}px`,
                height : `${percentage(BoardWidth , 20)}px`,
                zIndex : 2,
            }}>

            </motion.div>
        }

        { ShowDialog === 2 &&
            <motion.div 
            onTapStart={ExecuteDraw}
            style={{
                position : "absolute",
                bottom : "49.5%",
                left : `${percentage(BoardWidth , 52)}px`,
                width : `${percentage(BoardWidth , 35)}px`,
                height : `${percentage(BoardWidth , 20)}px`,
                zIndex : 2
            }}>

            </motion.div>
        }

        </motion.div>
    )
}

export default Button