import React , { useState } from 'react';
import Music from '../../../../Sound/CardsDistributed.mp3';
import useSetTimeout from 'use-set-timeout';
import useSound from 'use-sound';

const Section = () => {
    const [ play ] = useSound(Music , { interrupt : false});
    const [ Playing , PlayingUpdate ] = useState(true);
    
    useSetTimeout(() => {
        PlayingUpdate(false)
    }, 1000)

    return (
        <div>
            { Playing === true && play() }
        </div>
    )
    
}

export default Section