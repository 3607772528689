import React , { useState } from 'react';
import useSetTimeout from 'use-set-timeout';
import { Redirect } from 'react-router-dom';

const Redirector = () => {
    const [ RedirectOn , RedirectOnUpdate ] = useState(null)

    useSetTimeout(() => {
        if(RedirectOn !== true){
            RedirectOnUpdate(true)
        }        
    }, 600)

    return (
        <div>
            { RedirectOn === true && < Redirect to="/freetoplay/tutorial" />}
        </div>
    )
}

export default Redirector