import React from 'react';
import { motion }  from "framer-motion";
import useResize from 'use-resize';
import TableTemplate from './TableTemplate';
import CardBackground from '../../../Icons/CardBackground.svg';


const Mover = (Value) => {
    const GameData = Value.GameData
    const Seat = Value.Seat
    const currentSize = SizeInfo();


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()


    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const NumberOfCardsBluffed = () => {        
        if(GameData.CurrentBluff !== null){
            return (GameData.CurrentBluff.NumberOfCardsBluffed)
        } else {
            return (null)
        }        
    }

    const BluffersCardsStyleProvider = () => {
        if(GameData.CurrentBluff !== null && ( NumberOfCardsBluffed() <= 3)){
           return ({
            position : "absolute",
            top : `${percentage(BoardHeight , 47)}px`,
            width : `${percentage(BoardWidth , 35)}px`,
            height : `${percentage(BoardWidth , 15)}px`,
            left : `${percentage(BoardWidth , 33)}px`,
            backgroundColor : "rgba(0,0,0,0.5)",
            textAlign : "center",
            borderRadius : `${percentage(BoardWidth , 2)}px`,
           })
        } else if(GameData.CurrentBluff !== null && (NumberOfCardsBluffed() <= 5 )){
            return ({
             position : "absolute",
             top : `${percentage(BoardHeight , 47)}px`,
             width : `${percentage(BoardWidth , 55)}px`,
             height : `${percentage(BoardWidth , 15)}px`,
             left : `${percentage(BoardWidth , 22.5)}px`,
             backgroundColor : "rgba(0,0,0,0.5)",
             textAlign : "center",
             borderRadius : `${percentage(BoardWidth , 2)}px`,
            })
         } else if(GameData.CurrentBluff !== null && (NumberOfCardsBluffed() === 6 )){
            return ({
             position : "absolute",
             top : `${percentage(BoardHeight , 47)}px`,
             width : `${percentage(BoardWidth , 70)}px`,
             height : `${percentage(BoardWidth , 15)}px`,
             left : `${percentage(BoardWidth , 15)}px`,
             backgroundColor : "rgba(0,0,0,0.5)",
             textAlign : "center",
             borderRadius : `${percentage(BoardWidth , 2)}px`,
            })
         } else {
            return (null)
        }
    }

    const CardCountClass = () => {
        if(Seat === 1){
            return ("slide-in-bottom-fast")
        } else if(Seat === 2){
            return ("slide-in-left")
        } else if(Seat === 3){
            return("slide-in-bck-right")
        } else if(Seat === 4){
            return("slide-in-right")
        }
    }

    return (
        <motion.div>
            <motion.div className={CardCountClass()} style={{
                position : "absolute",
                top : "39%",
                height : `${percentage(BoardWidth , 10)}px`,
                width : `${percentage(BoardWidth , 10)}px`,
                fontSize : `${percentage(BoardWidth , 2.5)}px`,
                textAlign : "center",
                background : "black",
                borderRadius : "50%",
                left : `${percentage(BoardWidth , 70)}px`,
                borderStyle : "solid",
                borderColor : "red",
                borderWidth : `${percentage(BoardWidth , 0.15)}px`,
            }}>

                <div style={{
                    position : "absolute",
                    fontSize : `${percentage(BoardWidth , 3.5)}px`,
                    top : "43%",
                    textAlign : "center",
                    width : `${percentage(BoardWidth , 10)}px`,
                }}>{ GameData.TableCards }</div>
                
                <div style={{
                    position : "absolute",
                    background : "red",
                    textAlign : "center",
                    width : `${percentage(BoardWidth , 20)}px`,
                    backgroundColor : "black",
                    borderRadius : `${percentage(BoardWidth , 2)}px`,
                    left : "-50%",
                    top : "-10%",
                    borderStyle : "solid",
                    borderColor : "red",
                    borderWidth : `${percentage(BoardWidth , 0.15)}px`,
                }}>
                    <div style={{
                        height : `${percentage(BoardWidth , 0.5)}px`,
                    }}></div>
                    CARD COUNT
                    <div style={{
                        height : `${percentage(BoardWidth , 0.5)}px`,
                    }}></div>
                </div>
            </motion.div>
                    
            <motion.div className={CardCountClass()} style={ BluffersCardsStyleProvider() }>
                <motion.div style={{
                    backgroundColor : "black",
                    position : "absolute",
                    width : `100%`,
                    height : `${percentage(BoardWidth , 4.5)}px`,
                    top : `${percentage(BoardHeight , 3.5)}px`,
                    fontSize : `${percentage(BoardWidth , 3.5)}px`,
                    zIndex : 2
                }}>BLUFFER'S CARDS</motion.div>

                { NumberOfCardsBluffed() === 1 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }

                { NumberOfCardsBluffed() === 2 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }

                { NumberOfCardsBluffed() === 3 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }

                { NumberOfCardsBluffed() === 4 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }

                { NumberOfCardsBluffed() === 5 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }

                { NumberOfCardsBluffed() === 6 &&
                    < motion.div>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                        <img src={CardBackground} alt="Card Background"
                                style={{
                                    height : `${percentage(BoardWidth , 15)}px`,
                                    width : "auto"
                                }}/>
                    </motion.div>
                }
                
            </motion.div>            
                
        </motion.div>
    )

    
}

            

export default Mover