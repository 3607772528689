import React , { useState } from 'react';
import ContentMobile from './ContentMobile';
import useOrientationChange from "use-orientation-change";
import { useLocation} from 'react-router-dom';
import useResize from 'use-resize';
import PokerWall from './MobileComponents/FreeToPlayBluff/ProfileAssets/PokerWall';
import PokerTable from './MobileComponents/FreeToPlayBluff/ProfileAssets/PokerTable'
import PokerFloor from './MobileComponents/FreeToPlayBluff/ProfileAssets/PokerFloor'
import BluffLogo from './MobileComponents/FreeToPlayBluff/ProfileAssets/BluffLogo'
import { motion } from 'framer-motion'
import DisplayOff from './BodyDisplayOff';
import useSetTimeout from 'use-set-timeout';

const Body = () => {
    const location = useLocation();
    const orientation = useOrientationChange()
    const [ LandscapeDetected , LandscapeDetectedUpdate ] = useState(null)
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null)

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height,
        "width" : currentSize.width 
    }

    

    const Landscaper = () => {
        LandscapeDetectedUpdate(true)

        return (
            <motion.div>
                LANDSCAPE MODE IS NOT SUPPORTED, ROTATE YOUR SCREEN TO PLAY
            </motion.div>
        )
    }

    const LogoClass = () => {
        if(location.pathname === "/freetoplay/profile" ||
        location.pathname === "/freetoplay/matchmaker" ||
        location.pathname === "/" ||
        location.pathname === "/freetoplay/tutorial" ||
        location.pathname === "/landingmobile"){
            return("slide-in-right")
        } else {
            return("flip-out-hor-top-fastest")
        }
    }

    const DisplayOffCallback = (Value) => {
        IsAccessDataUpdate(Value)
    }

    const FixingDisplay = () => {
        const [ Fix , FixUpdate ] = useState(null)

        useSetTimeout (() => {
            LandscapeDetectedUpdate(null)
            FixUpdate(false)
        }, 1000)
        return (<div>
           { Fix === null && <div 
           style={{
               width : `${Dimensions.width}px`,
               textAlign : "center",
               marginTop : "50%",
               height : `${Dimensions.height/2}px`,
           }}
           className='pulsate-fwd'>FIXING DISPLAY</div> }
        </div>)
    }
       
    return (
        <div style={{
            position : "absolute",
            height : `${Dimensions.height}px`,
            width: `${Dimensions.width}px`,
        }}>
                < DisplayOff DisplayOffCallback = { DisplayOffCallback }/>
                { orientation === "portrait" && LandscapeDetected === true && 
                    < FixingDisplay />
                }
                { orientation === "portrait" && LandscapeDetected !== true && 
                    <div style={{
                        "fontFamily" : "Custom",
                    }}>    
                        <div style={{
                                "height" : `${Dimensions.height}px`,
                                "width": `${Dimensions.width}px`,
                                "position" : "absolute",
                                "top" : "0%",
                                "zIndex" : 3
                            }}>      
                                { IsAccessData === "LOADED" && < ContentMobile /> }                                                                             
                        </div>

                        <div 
                        className={LogoClass()}
                        style={{
                            "height" : `${Dimensions.height}px`,
                            "width": `${Dimensions.width}px`,
                            "position" : "absolute",
                            "top" : "0%",
                            "zIndex" : 2
                        }}>                               
                            < BluffLogo />
                        </div>

                        { (location.pathname === "/freetoplay/profile" ||
                            location.pathname === "/freetoplay/matchmaker" ||
                            location.pathname === "/freetoplay/gametable" ||
                            location.pathname === "/freetoplay/tutorial" ||
                            location.pathname === "/" ||
                            location.pathname === "/landingmobile"
                        ) &&
                            <div style={{
                                "height": `${Dimensions.height}px`,
                                "width": `${Dimensions.width}px`,
                                "position" : "fixed",
                                "top" : "0%",
                                "zIndex" : 1
                            }}>                                
                                < PokerWall />
                                < PokerFloor /> 
                                < PokerTable Location = {location.pathname} />
                            </div>
                        }  
                   
                    </div>                            
                }

                { orientation === "landscape" &&
                    < Landscaper />
                }
                    
        </div>
    )
    

    
}

export default Body