import React , { useState } from 'react';
import { useLocation} from 'react-router-dom';
import { Redirect } from 'react-router';
import { motion } from 'framer-motion'
import useResize from 'use-resize';
import Config from './GlobalFunctions/Config';
import useSetTimeout from 'use-set-timeout';
import IsAccess from './GlobalFunctions/NetworkCalls/OnBoard'
import LoginUser from './GlobalFunctions/NetworkCalls/LoginUser'
import LogoutUser from './GlobalFunctions/NetworkCalls/LogoutUser'
import FindMxUser from './GlobalFunctions/NetworkCalls/FindMxUser'
import CreateFreeAccount from './GlobalFunctions/NetworkCalls/CreateFreeAccount';
import socket from './SocketConnectionManager';

const DisplayOff = (Value) => {
    const location = useLocation();
    const [ IsAccessData , IsAccessDataUpdate ] = useState(null)
    
    const DisplayOffCallback = Value.DisplayOffCallback

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height,
        "width" : currentSize.width 
    }

    useSetTimeout(() => {
        const MX = window.MX ;            
        var userData = MX.notifyGameState(MX.GameState.Loaded , null);          
        Config.MxPlayerData = userData       
        
        const MxPlayerID = userData.playerData.playerGameData.userId
        //const MxPlayerID = "8"

        let callback = function (event_name, info) {
            switch (event_name) {
                case "backPressed": {
                    socket.emit('MasterSoundOff') 
                    console.log("BACK PRESSED")
                break;
                }
                case "screenOn": {
                    console.log("SCREEN TURNED ON")
                break;
                }
                case "screenOff": {
                    socket.emit('MasterSoundOff') 
                    console.log("SCREEN TURNED OFF")
                break;
                }
                case "pagePause": {
                    console.log("ACTIVITY PAUSED")
                break;
                }
                case "pageResume": {
                    console.log("ACTIVITY RESUMED")
                break;
                }
                case "userPresent": {
                    console.log("USER PRESENT")
                break;
                }
                default:
            }
        }

        MX.listenEvent("SystemEvents", callback, this);
        console.log("CALLING")

        if(IsAccessData === null && (MxPlayerID !== "" && MxPlayerID !== " " && MxPlayerID !== "3")){
            IsAccessDataUpdate("LOADING")

            IsAccess(function (AccessValue){
                if(AccessValue === false){
                    FindMxUser(MxPlayerID , function(ThisValue){
                        if(ThisValue.Info === "LOGIN USER"){
                            LoginThisUser(ThisValue.Detail)
                        } else if(ThisValue.Info === "CREATE USER"){
                            CreateAccount(MxPlayerID)
                        }
                    });
                } else if(AccessValue === true){
                    LogoutCurrentUser(function(Result){
                        if(Result === "LoggedOut"){
                            FindMxUser(MxPlayerID , function(ThisValue){
                                if(ThisValue.Info === "LOGIN USER"){
                                    LoginThisUser(ThisValue.Detail)
                                } else if(ThisValue.Info === "CREATE USER"){
                                    CreateAccount(MxPlayerID)
                                }
                            });
                        }
                    });                    
                }
            });
        } else {
            LogoutCurrentUser(function(Result){
                if(Result === "LoggedOut"){
                    CreateAccount(MxPlayerID)
                }
            })
        }
    }, 2000)

    const CreateAccount = (MxPlayerID) => {
        CreateFreeAccount(MxPlayerID, function (Value){
            if(Value === "Success"){
                IsAccessDataUpdate("LOADED")
                DisplayOffCallback("LOADED")                                   
            } else {
                console.log("SIGNUP FAILURE")
                CreateAccount()
            }
        })
    }

    const LoginThisUser = (DataValue) => {
        LoginUser(DataValue, function(Value){
            if(Value === "Success"){
                console.log("USER LOGGED IN")
                IsAccessDataUpdate("LOADED")  
                DisplayOffCallback("LOADED")
            } else {
                LoginThisUser(DataValue)
            }
        })
    }

    const LogoutCurrentUser = (Value) => {
        LogoutUser(function (Data){
            if(Data === "Success"){
                return Value("LoggedOut")
            }
        })
    }

    return(<div style={{
        position : "absolute",
        backgroundColor : "black",
        zIndex : 100
    }}>
       { IsAccessData !== "LOADED" &&
            <motion.div style={{
                position : "absolute",
                top : "0%",
                height : `${Dimensions.height}px`,
                width : `${Dimensions.width}px`,
                background : "black"
            }}>
                
            </motion.div>
       }

       { IsAccessData === "LOADED" &&  location.pathname === "/" && <Redirect to="/freetoplay/profile" /> }
       
    </div>)
}

export default DisplayOff