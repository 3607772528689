import useResize from 'use-resize'; 

const TableTemplate = () => {
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "10%"
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "10%"
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "7%"
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "2%"
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "0%"
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 80,
                ImageHeight : 80 + percentage(80 , 45),
                BottomValue : "0%"
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 75,
                ImageHeight : 75 + percentage(75 , 45),
                BottomValue : "0%"          
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 65,
                ImageHeight : 65 + percentage(65 , 45),
                BottomValue : "0%"   
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 65,
                ImageHeight : 65 + percentage(65 , 45),
                BottomValue : "0%"   
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 55,
                ImageHeight : 55 + percentage(55 , 45),
                BottomValue : "0%"   
            })
        }
    }

    const TemplateValue = TemplateData()

    return TemplateValue
}

export default TableTemplate