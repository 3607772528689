import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/CardClickMessage.svg'

const Button = (Value) => {
    const [ IconLoaded , IconLoadedUpdate ] = useState(null)
    const PlayerTurn = Value.PlayerTurn ;
    const GameData = Value.GameData ;
    const PlayerValue = GameData.PlayerOne

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    const AnimateValue = () => {
        if(PlayerTurn === true && IconLoaded === true){
            return({
                x : 0 - percentage(BoardWidth , 100),
                y : percentage(BoardHeight , 70),  
            })
        } else if(PlayerValue.TotalCards === 0){
            return({
                x : 0 - percentage(BoardWidth , 100),
                y : percentage(BoardHeight , 70),  
            })
        } else if(GameData.ZeroCards === true){
            return({
                x : 0 - percentage(BoardWidth , 100),
                y : percentage(BoardHeight , 70),  
            })
        } else {
            return({
                x : 0 - percentage(BoardWidth , 5),
                y : percentage(BoardHeight , 70),  
            })
        }
    }

    
    return (
        <motion.div  
        style={{
            position : "absolute", 
            zIndex : "2",
            width  : `${ percentage(BoardWidth , 30)}px`,                                            
            height : `${ percentage(BoardHeight , 7)}px`,
        }}
        animate={ AnimateValue() }>
            <object 
            onLoad={() => IconLoadedUpdate(true) }
            style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>          
        </motion.div>
    )
}

export default Button