import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Icon from '../../../Icons/ExitButton.svg';
import socket from '../../../SocketConnectionManager';
import Config from '../../../GlobalFunctions/Config';

const Logo = (Value) => {    
    const DefaultClass = () => {
        if(Value.isExit === true){
            return ("flip-out-hor-top-fast")
        } else if(Value.LoaderOn === true){
            return ("flip-out-hor-top-fastest")
        } else {
            return("slide-in-left")
        }
    }
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long"
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long"
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Long"
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 100,
                ImageHeight : percentage(100 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 80,
                ImageHeight : percentage(80 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 80,
                ImageHeight : percentage(80 , 15),
                ImageType : "Medium"
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 70,
                ImageHeight : percentage(70 , 15),
                ImageType : "Medium"
            })
        }
    }


    const TemplateValue = TemplateData()

    const RedirectIt = () => {
        Config.AssetLoadedStatus = null
        socket.emit('Transfer-To-Profile')
    }


    if(TableType >= 1){
    return (
        <motion.div 
        className={DefaultClass()}
        style={{
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)/1.5}px`, 
            left : "1%",        
            top : `7%`,
        }}>
            
            <motion.div
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  "100%",
                    height : "100%",
                    left : "50%",
                    transform : "translate(-50%, -0%)",
                }}>
                    <object style={{
                        width : "100%",
                        position : "absolute",
                        zIndex : 2,
                        left : "0%",
                    }} type="image/svg+xml" data={Icon}>Floor</object>  
                
            </motion.div> 

            <motion.div 
            onTapStart={RedirectIt}
            style={{
                    position : "absolute",
                    zIndex : "5",       
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)/3.5}px`,   
                    height : `${percentage(currentSize.width , TemplateValue.ImageHeight)/1.5}px`, 
                    left : "1%",        
                    top : `7%`,                  
                }}>

            </motion.div>     
         
        </motion.div>
    )
    } else {
        return (<motion.div>
            Resolution Not Supported
        </motion.div>)
    }
    
}

export default Logo