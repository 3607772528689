import React , { useEffect, useState } from 'react';
import useResize from 'use-resize';
import ProfileInfo from '../ProfileAssets/ProfileInfo';
import TutorialButton from '../ProfileAssets/TutorialButton';
import PlayButton from '../ProfileAssets/PlayButton';
import IsGamerProfile from '../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import socket from '../../../SocketConnectionManager';
import useSetTimeout from 'use-set-timeout';
import ProfileLoader from '../ProfileAssets/ProfileLoader';
import RedirectorPlay from './RedirectorMatchMaker';
import RedirectorTutorial from './RedirectorTutorial';
import ExitGame from '../ProfileAssets/ExitGame';
import ExitGameOptions from './ExitGameOptions'
import Config from '../../../GlobalFunctions/Config';

const Profile = () => {    
    const [ PlayerData , PlayerDataUpdate ] = useState(null) ;
    const [ PlayButtonRedirect ,  PlayButtonRedirectUpdate ] = useState(null);
    const [ TutorialRedirect ,  TutorialRedirectUpdate ] = useState(null);
    const [ isExit , isExitUpdate ] = useState(false)   
    const [ isProfileDisplay , isProfileDisplayUpdate ]  = useState(false)
    const [ LoaderOn , LoaderOnUpdate ] = useState(false)
    const [ isDisconnected , isDisconnectedUpdate ] = useState(null)
    const [ TurnOffLoader , TurnOffLoaderUpdate ] = useState(false)
    const [ ShowExitOption , ShowExitOptionUpdate ] = useState(false)

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    useEffect(() => {
        if(PlayerData === null){
            PlayerDataUpdate("FETCH")
            IsGamerProfile(function (data){
                PlayerDataUpdate(data)
            })
        }

        socket.on("RedirectToMatchmaker" , function(){      
            if(PlayButtonRedirect !== true){
                PlayButtonRedirectUpdate(true)
                isExitUpdate(true)
            }
        })

        socket.on("RedirectToTutorial" , function(){      
            if(TutorialRedirect !== true){
                TutorialRedirectUpdate(true)
                isExitUpdate(true)
            }
        })
    
        socket.on("LoadProfile" , function (){            
            socket.off('LoadProfile')            
            isDisconnectedUpdate(false)
            isProfileDisplayUpdate(true)
        })
        
        socket.on('SwitchOffLoader' , function(){
            LoaderOnUpdate(true)
        })

        socket.on('QuitGameDisplay' , function(){
            isExitUpdate(true)
            ShowExitOptionUpdate(true)
        })

        socket.on('CancelQuitGameDisplay' , function(){
            isExitUpdate(false)
            ShowExitOptionUpdate(false)
            isProfileDisplayUpdate(true)
        })

        return () => {
            socket.off('LoadProfile')
            socket.off('RedirectToProfileInfo');
            socket.off("RedirectToMatchmaker")
            socket.off("RedirectToTutorial")
            socket.off('QuitGameDisplay')
            socket.off('CancelQuitGameDisplay')
        }
    }, [ PlayButtonRedirect , TutorialRedirect, isExit, LoaderOn, isProfileDisplay, ShowExitOption, PlayerData])
    
    const currentSize = SizeInfo();

    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    
    

    const LoaderHandler = () => {
        const [ Process , ProcessUpdate ] = useState(false)

        useSetTimeout(() => {
            TurnOffLoaderUpdate(true)
            ProcessUpdate(true)

            if(Config.FirstTimeLoad === null){
                Config.FirstTimeLoad = true
            }
        }, 500)

        return (<div>
           { Process === false && <div></div> }
        </div>)
    }

    

    

    

    
    
    const DisplayClass = () => {
        if(LoaderOn === true){
            if(ShowExitOption === false){
                return("slide-in-left")
            } else {
                return("flip-out-hor-top-fast")
            }            
        } else {
            return ("flip-out-hor-top-fastest")
        }
    }


        
    return (<div className='GpuAcceleration'      
        style={{
                height : `${Dimensions.height}px`,
                width : `${Dimensions.width}px`,
                position : "fixed",   
            }}>

                { TurnOffLoader === false &&< ProfileLoader isExit = { LoaderOn} /> }

                { LoaderOn === true && < LoaderHandler /> }
                

                { PlayerData !== null && 
                    PlayerData !== "FETCH" && 
                    isProfileDisplay === true &&
                    isDisconnected === false &&
                <div 
                className={DisplayClass()}
                style={{
                    "height" : `${Dimensions.height}px`,
                    "width": `${Dimensions.width}px`,
                    "position" : "fixed", 
                }}>
                    < TutorialButton isExit={isExit} />     
                    < PlayButton PlayerData = { PlayerData } isExit={isExit}  />
                    < ProfileInfo PlayerData = { PlayerData } isExit={isExit}  />
                    < ExitGame isExit={isExit} />
                </div>
                }

                { PlayButtonRedirect === true &&
                    < RedirectorPlay />
                } 

                { TutorialRedirect === true &&
                    < RedirectorTutorial />
                } 

                

                { ShowExitOption === true &&
                    < ExitGameOptions />
                }
    </div>)
}

export default Profile