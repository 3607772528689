import React from "react";
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import CurrentBluff from "./CurrentBluff";
import Icon from '../../../Icons/Bluffer.svg'
import TableTemplate from './TableTemplate';

const Mover = (Value) => {
    const GameData = Value.GameData ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    const TemplateValue = TableTemplate()

    const TotalWidth = percentage(currentSize.width , TemplateValue.ImageWidth)
    const TotalHeight = percentage(currentSize.width , TemplateValue.ImageHeight) 

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const Animater = () => {
        const Seat = PlayerTurn()
        
        if(Seat === 1){
            return ({ 
                scale : 0,               
            })
        } else if(Seat === 2){
            return ({
                x : 0 - percentage(TotalWidth , 7.5),
                y : percentage(TotalHeight , 15),
                scale : 1,
            })
        } else if(Seat === 3){
            return ({
                x : percentage(TotalWidth , 32),
                y : 0 - percentage(TotalHeight , 12.5),
                scale : 1,
            })
        } else if(Seat === 4){
            return ({                
                x : percentage(TotalWidth , 72),
                y : percentage(TotalHeight , 15),
                scale : 1,
            })
        } else {          
            return ({
                scale : 0,
            })
        }        
    }

    const PlayerTurn = () => {                
        if(GameData === null){
            return null
        } else if(SeatOneValues.isBluffer ===  true){
            return null
        } else if(SeatTwoValues.isBluffer === true){
            return 2
        } else if(SeatThreeValues.isBluffer === true){
            return 3
        } else if(SeatFourValues.isBluffer === true){
            return 4
        } else {
            return null
        }
    }

    const ClaimPosition = () => {
        const Seat = PlayerTurn()
        
        if(Seat === 1){
            return ({ 
                scale : 0,               
            })
        } else if(Seat === 2){
            return ({
                x : 0 - percentage(TotalWidth , 8.5),
                y : percentage(TotalHeight , 30.75),
                scale : 1,
            })
        } else if(Seat === 3){
            return ({
                x : percentage(TotalWidth , 37),
                y : percentage(TotalHeight , 3.75),
                scale : 1,
            })
        } else if(Seat === 4){
            return ({                
                x : percentage(TotalWidth , 82),
                y : percentage(TotalHeight , 30.75),
                scale : 1,
            })
        } else {          
            return ({
                scale : 0.5,
                x : percentage(TotalWidth , 31.5),
                y : percentage(TotalHeight , 40),
            })
        }        
    }

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const styleValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,                                      
        })
    }

    const animateValue = () => {
        if(PlayerTurn() !== null){
            return ({
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
                scale : 1
            })
        } else {
            return ({
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
                scale : 0
            })
        }
        
    }
    
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    
    return (
        <motion.div 
        animate={
            animateValue()
        }
        style={
            styleValue()
        }>
            { PlayerTurn() !== null && 
                <motion.div                 
                style={{
                    width :  `${percentage(BoardWidth , 25)}px`,
                    height : `${percentage(BoardHeight , 11)}px`,                   
                    position : "absolute",
                }}
                initial={
                    Animater()
                }
                animate={
                    Animater()
                }
                transition={{ duration : 0.3 }}
                >
                <object style={{
                        width :  `${percentage(BoardWidth , 20)}px`,
                        position : "absolute",
                        zIndex : 1,
                        left : "50%",
                        top : "50%",
                        transform : "translate(-50%, -50%)",
                    }} type="image/svg+xml" data={Icon}>Table</object>  
                                 
                </motion.div>
            }

            { GameData.CurrentBluff !== null &&
                <motion.div  style={{
                    position : "absolute"
                }}
                initial={ ClaimPosition()} animate={ ClaimPosition() } transition={{ duration : 0.3}}>
                    < CurrentBluff GameData = {GameData} 
                        BoardHeight = { BoardHeight } 
                        BoardWidth = { BoardWidth }  />
                </motion.div>
            }            
            
        </motion.div>
    )
    
}

            

export default Mover