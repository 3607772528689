import React , { useState , useEffect } from 'react';
import useResize from 'use-resize';
import { Redirect } from 'react-router';
import socket from '../../../SocketConnectionManager';
import useSetTimeout from 'use-set-timeout';
import { motion } from "framer-motion";
import TutorialOne from '../../../Icons/TutorialOne.svg'
import TutorialTwo from '../../../Icons/TutorialTwo.svg'
import TutorialThree from '../../../Icons/TutorialThree.svg'
import TutorialFour from '../../../Icons/TutorialFour.svg'
import TutorialFive from '../../../Icons/TutorialFive.svg'
import TutorialSix from '../../../Icons/TutorialSix.svg'
import TutorialSeven from '../../../Icons/TutorialSeven.svg'
import TutorialEight from '../../../Icons/TutorialEight.svg'
import TutorialNine from '../../../Icons/TutorialNine.svg'
import TutorialExit from '../ProfileAssets/TutorialExit'
import TutNext from '../../../Icons/TutNext.svg'
import TutPrev from '../../../Icons/TutPrev.svg'

const Tutorial = () => {    
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }    

    const currentSize = SizeInfo();
    const Dimensions = {
        "height" : currentSize.height ,
        "width" : currentSize.width 
    }

    
    const [ isExitToProfile , isExitToProfileUpdate ] = useState(false)
    const [ CurrentPage, CurrentPageUpdate ] = useState(1)
    const [ RemoveLoader , RemoveLoaderUpdate ] = useState(false)
    
    const ActiveClass = () => {
        if(RemoveLoader === true ){
            return("swing-in-left-fwd-fast")
        } else if(RemoveLoader === false){
            return("flip-out-hor-top-fastest")
        }
    }
    
    function percentage(num, per)
    {
        return (num/100)*per;
    }

    useEffect(() => {
        socket.on("ExitToProfile" , function(){
            isExitToProfileUpdate(true)
        });
        
        
        return () => {
            socket.off('ExitToProfile');
        }
    },[ isExitToProfile ])

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 90,
                ImageHeight : 120,
                ImageType : "Long",
                Left : "15%",
                Top : 35
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 90,
                ImageHeight : 120,
                ImageType : "Long",
                Left : "15%",
                Top : 35
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 90,
                ImageHeight : 120,
                ImageType : "Long",
                Left : "15%",
                Top : 32
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 90,
                ImageHeight : 120,
                ImageType : "Long",
                Left : "15%",
                Top : 32
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 90,
                ImageHeight : 120,
                ImageType : "Long",
                Left : "15%",
                Top : 31
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 80,
                ImageHeight : 110,
                ImageType : "Long",
                Left : "15%",
                Top : 30
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 80,
                ImageHeight : 110,
                ImageType : "Long",
                Left : "21%",
                Top : 32
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 70,
                ImageHeight : 100,
                ImageType : "Long",
                Left : "21%",
                Top : 30
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 60,
                ImageHeight : 90,
                ImageType : "Long",
                Left : "24%",
                Top : 30
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 60,
                ImageHeight : 90,
                ImageType : "Long",
                Left : "26%",
                Top : 25
            })
        }
    }

    const TemplateValue = TemplateData()

            

    const ExitToProfile = () => {
        const [ Timer , TimerUpdate ] = useState(false)        
        useSetTimeout (() => { 
            TimerUpdate(true) 
        }, 1000);


        return (<div style={{
            position : "absolute",
            width :  `${percentage(currentSize.width , 100)}px`,
            top : "50%"
        }}>
            { Timer === true && 
                <Redirect to={'/freetoplay/profile'} />
            }    
        </div>)
    }

    const CurrentPageClass = (Value) => {
        if(isExitToProfile === true){
            return ("flip-out-hor-top-fast")
        } else if(Value === CurrentPage){
            return ("swing-in-left-fwd-fast")
        } else {
            return ("flip-out-hor-top-fast")
        }
    }

    const NextButtonClass = () => {
        if(RemoveLoader === false){
            return ("flip-out-hor-top-fastest")
        } else if(isExitToProfile === true){
            return ("flip-out-hor-top-fast")
        } else if(CurrentPage < 9){
            return ("swing-in-left-fwd-fast")
        } else {
            return ("flip-out-hor-top-fast")
        }
    }

    const PrevButtonClass = () => {
        if(RemoveLoader === false){
            return ("flip-out-hor-top-fastest")
        } else if(isExitToProfile === true){
            return ("flip-out-hor-top-fast")
        } else if(CurrentPage > 1){
            return ("swing-in-left-fwd-fast")
        } else {
            return ("flip-out-hor-top-fast")
        }
    }

    const StyleCreator = {
        Height : percentage(currentSize.width , TemplateValue.ImageHeight),
        Width : percentage(currentSize.width , TemplateValue.ImageWidth),
    }

    const TutStyle = (Value) => {
        if(Value === CurrentPage){
            return ({
                position : "absolute",
                left : `${( currentSize.width - StyleCreator.Width )/ 2}px`,
                top : `${percentage(currentSize.height , 17)}px`,
                transform : "translate(-50%, -0%)",
                height : `${StyleCreator.Height}px`,
                width : `${StyleCreator.Width}px`,
                zIndex : 1,
            })   
        } else {
            return ({
                position : "absolute",
                left : `${( currentSize.width - StyleCreator.Width )/ 2}px`,
                top : `${percentage(currentSize.height , 17)}px`,
                transform : "translate(-50%, -0%)",
                height : `${StyleCreator.Height}px`,
                width : `${StyleCreator.Width}px`,
                zIndex : 2
            }) 
        } 
    }

    const NextUpdate = () => {
        if(CurrentPage < 9){
            CurrentPageUpdate(CurrentPage + 1)
        }        
    }
    
    const PrevUpdate = () => {
        if(CurrentPage > 1){
            CurrentPageUpdate(CurrentPage - 1)
        }
    }

    const RemoveLoaderScreen = () => {      
        useSetTimeout (() => { 
            RemoveLoaderUpdate(true)
        }, 1000);


        return (<div style={{
            position : "absolute",
            width :  `${percentage(currentSize.width , 100)}px`,
            top : "50%"
        }}>    
        </div>)
    }

    return (<div style={{
        height : `${Dimensions.height}px`,
        width : `${Dimensions.width}px`,
        position : "fixed" ,    
    }}>  
        <motion.div 
        className='slide-in-left'
        style={{
            position : "absolute",
            width :  `${percentage(currentSize.width , 100)}px`,   
            height : `${percentage(currentSize.height , 100)}px`,              
            top : "0%",
            backgroundColor : "rgba(0,0,0,0.8)"
        }}>

        </motion.div>

        { RemoveLoader === false &&
            < RemoveLoaderScreen />
        }

        { RemoveLoader === false  && 
            <motion.div className="slide-in-bck-right"
            style={{
                position : "absolute",   
                left : "0%",
                top : "40%",
                width :  `${percentage(currentSize.width ,  100)}px`,
                color : "#00F9FF",
                zIndex : "200",
                textAlign : "center"   
            }}>
                <motion.div
                style={{
                    marginTop : "2%",
                }}
                >LOADING TUTORIAL</motion.div>
            </motion.div>
        }

        { isExitToProfile === true && 
                
                <motion.div className="slide-in-bck-right"
                    style={{
                        position : "absolute",   
                        left : "0%",
                        top : "40%",
                        width :  `${percentage(currentSize.width ,  100)}px`,
                        color : "#00F9FF",
                        zIndex : "200",
                        textAlign : "center"   
                    }}>
                        <motion.div
                        style={{
                            marginTop : "2%",
                        }}
                        >LEAVING TUTORIAL</motion.div>
                </motion.div>
            }

        <motion.div className={ ActiveClass() }>

            <object  
            style={ TutStyle(1) }
            className={ CurrentPageClass(1) }    
            type="image/svg+xml" data={TutorialOne}>Backdrop</object>

            <object      
            style={ TutStyle(2) }           
            className={ CurrentPageClass(2) }    
            type="image/svg+xml" data={TutorialTwo}>Backdrop</object>

            <object      
            style={ TutStyle(3) }  
            className={ CurrentPageClass(3) } 
            type="image/svg+xml" data={TutorialThree}>Backdrop</object>

            <object    
            style={ TutStyle(4) }
            className={ CurrentPageClass(4) }   
            type="image/svg+xml" data={TutorialFour}>Backdrop</object>

            <object    
            style={ TutStyle(5) }  
            className={ CurrentPageClass(5) }   
            type="image/svg+xml" data={TutorialFive}>Backdrop</object>

            <object 
            style={ TutStyle(6) }    
            className={ CurrentPageClass(6) }   
            type="image/svg+xml" data={TutorialSix}>Backdrop</object>

            <object  
            style={ TutStyle(7) } 
            className={ CurrentPageClass(7) }                                
            type="image/svg+xml" data={TutorialSeven}>Backdrop</object>

            <object 
            style={ TutStyle(8) }  
            className={ CurrentPageClass(8) }                    
            type="image/svg+xml" data={TutorialEight}>Backdrop</object>

            <object  
            style={ TutStyle(9) }
            className={ CurrentPageClass(9) }                     
            type="image/svg+xml" data={TutorialNine}>Backdrop</object>

        </motion.div>
        
        { isExitToProfile === true && < ExitToProfile /> }

        < TutorialExit isExit = { isExitToProfile } LoaderOn={ RemoveLoader }/>  

        <motion.div style={{
            position : "absolute",
            width :  `${percentage(StyleCreator.Width , 30)}px`,
            height :  `${percentage(StyleCreator.Width , 8)}px`,
            bottom : "2%",
            right : "0%",
        }}>
            <object  
                className={ NextButtonClass() }
                type="image/svg+xml" data={TutNext}>Backdrop</object>

            <motion.div 
            onTapStart={ NextUpdate }
            style={{
                position : "absolute",
                width :  `${percentage(StyleCreator.Width , 30)}px`,
                height :  `${percentage(StyleCreator.Width , 8)}px`,
                bottom : "2%",
                right : "0%"
            }}>

            </motion.div>
        </motion.div>

        <motion.div style={{
            position : "absolute",
            width :  `${percentage(StyleCreator.Width , 30)}px`,
            height :  `${percentage(StyleCreator.Width , 8)}px`,
            bottom : "2%",
            left : "0%"
        }}>
            <object  
                className={ PrevButtonClass() }   
                type="image/svg+xml" data={TutPrev}>Backdrop</object>

            <motion.div 
            onTapStart={ PrevUpdate }
            style={{
                position : "absolute",
                width :  `${percentage(StyleCreator.Width , 30)}px`,
                height :  `${percentage(StyleCreator.Width , 8)}px`,
                bottom : "2%",
                right : "0%"
            }}>

            </motion.div>
        </motion.div>
    </div>)
    
}

export default Tutorial