import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/ExitOpen.svg'

const Button = (Value) => {
    const isExitSelected = Value.isExitSelected
    const [ IconRendered , IconRenderedUpdate ] = useState(null)
    const Winner = Value.Winner
    
    const IconLoaded = () => {
        IconRenderedUpdate(true)
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const AnimateValue = () => {
        if(isExitSelected === true && Winner !== true){
            return ({
                x : 0 , y : 0, scale : 1, opacity : 1
            })
        } else {
            return ({
                y : 0 - percentage(currentSize.height , 100), x : 0 , scale : 0, opacity : 0
            })
        }
    }

    return (
        <motion.div 
        initial={{ y : 0 - percentage(currentSize.height , 100), x : 0, scale : 0, opacity : 0 }}
        animate={AnimateValue()}
        transition={{ duration : 0.2 }}
        style={{
                position : "absolute",
                top : "0%",
                left : `${(percentage(currentSize.width , 100) -  percentage(BoardWidth , 100)) / 2 }px`,
                width : `${percentage(BoardWidth , 100)}px`,
                height : `${percentage(BoardHeight , 40)}px`, 
            }}>
            <object onLoad={() => IconLoaded() } style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  

        { IconRendered  === null &&
            <img 
            style={{
                width :  `100%`,
                position : "absolute",
                zIndex : 2,
                left : "50%",
                top : "50%",
                transform : "translate(-50%, -50%)",                            
            }}
            src={Icon} alt="Loader" />
        }   
        </motion.div>
    )
}

export default Button