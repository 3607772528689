import React from 'react'
import { Switch, Route ,  useLocation} from 'react-router-dom'
import Home from './MobileComponents/Home'
import F2PProfileBluff from './MobileComponents/FreeToPlayBluff/Profile';
import F2PMatchMakerBluff from './MobileComponents/FreeToPlayBluff/MatchMaker';
import F2PGameTableLauncherBluff from './MobileComponents/FreeToPlayBluff/GameTableLauncher';
import F2PTutorialBluff from './MobileComponents/FreeToPlayBluff/Tutorial';

const Content = () =>{
  const location = useLocation();

  const NoMatchPage = () => {
      return (
        <div>
            <div style={{
                "position" : "fixed",
                "height" : "100%",
                "width" : "100%",
                "top" : "0px",
                "left" : "0px",
                "backgroundImage" : "linear-gradient(rgba(0, 0, 0 ,0.8) , rgba(0,0,0,0.9) , rgba(0,0,0,0.9) ,  rgba(0,0,0,0.9),  rgba(0, 0, 0 ,0.8))",
                "zIndex" : "1000",
                "overflowY" : "none",
                "overflowX" : "none",
                "fontFamily" : "HighlightText",
                "fontSize" : "1.5em",
                "color" : "#00f9ff",
            }}>
                    <div style={{
                        "position" : "relative",
                        "top" : "50%",
                        "left": "50%",
                        "transform" : "translate(-50%, -50%)",
                    }}>
                        <div style={{
                            "margin" : "0 auto",
                            "width" : "70%",
                            "textAlign" : "center"
                        }}>
                            <div>404</div>
                            <div style={{
                                "marginTop" : "2%",
                            }}>NOT FOUND</div>

                            <div style={{
                                "marginTop" : "25%",
                            }}>
                                <div>CLICK HERE</div>
                                <div>TO GO BACK TO</div>
                                <div>HOME PAGE</div>
                            </div>
                        </div>

                        
                    </div>
            </div>
        </div>
      )
  }

  return(
            <Switch location={location} key={location.pathname}>
                <Route exact path = "/" component={Home}/>
                <Route exact path="/freetoplay/profile" component={F2PProfileBluff}/>
                <Route exact path="/freetoplay/tutorial" component={F2PTutorialBluff}/>
                <Route exact path="/freetoplay/matchmaker" component={F2PMatchMakerBluff}/>
                <Route exact path="/freetoplay/gametable" component={F2PGameTableLauncherBluff}/>
                <Route component={NoMatchPage} />
            </Switch>
  )
}
 
export default Content