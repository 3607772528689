import React from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/BluffTopPanelBluffRunning.svg'
import PlayAsAces from '../../../../Icons/PlayAsAces.svg'
import PlayAsKings from '../../../../Icons/PlayAsKings.svg'
import PlayAsQueens from '../../../../Icons/PlayAsQueens.svg'
import PlayAsJacks from '../../../../Icons/PlayAsJacks.svg'
import PlayAsTens from '../../../../Icons/PlayAsTens.svg'
import PlayAsNines from '../../../../Icons/PlayAsNines.svg'

const Button = (Value) => {
    const GameData = Value.GameData

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const BlockStyle = {
        position : "absolute",
        left : `${percentage(BoardWidth , 48)}px`,
        top : `${percentage(BoardWidth , 9)}px`,
        width : `${percentage(BoardWidth , 22)}px`,
        height : `${percentage(BoardWidth , 15)}px`, 
    }

    const BlockImageStyle = {
        width :  `100%`,
        position : "absolute",
        zIndex : 1,
        left : "50%",
        top : "50%",
        transform : "translate(-50%, -50%)",
    }

    return (
        <motion.div
        className='slide-in-left'
        style={{
            position : "absolute",
            bottom : "41%",
            left : `${0 - percentage(BoardWidth , 10)}px`,
            width : `${percentage(BoardWidth , 120)}px`,
            height : `${percentage(BoardWidth , 26)}px`, 
            zIndex : 1
        }}
        >
                <motion.div style={{
                }}>
                    <object style={{
                    width :  `100%`,
                    position : "absolute",
                    zIndex : 1,
                    left : "50%",
                    top : "50%",
                    transform : "translate(-50%, -50%)",
                }} type="image/svg+xml" data={Icon}>Table</object> 

                 
                </motion.div> 

                { GameData.CurrentBluff.Claim === "A" && 
                    <motion.div style={ BlockStyle }>
                        <object
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsAces}>Table</object>                      
                    </motion.div>
                }

                { GameData.CurrentBluff.Claim === "K" && 
                    <motion.div style={ BlockStyle }>
                        <object
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsKings}>Table</object>                         
                    </motion.div>
                }

                { GameData.CurrentBluff.Claim === "Q" && 
                    <motion.div style={ BlockStyle }>
                        <object
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsQueens}>Table</object>                      
                    </motion.div>
                }

                { GameData.CurrentBluff.Claim === "J" && 
                    <motion.div style={ BlockStyle }>
                        <object
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsJacks}>Table</object>                        
                    </motion.div>
                }

                { GameData.CurrentBluff.Claim === "10" && 
                    <motion.div style={ BlockStyle }>
                        <object
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsTens}>Table</object>                    
                    </motion.div>
                }

                { GameData.CurrentBluff.Claim === "9" && 
                    <motion.div style={ BlockStyle }>
                        <object 
                        style={ BlockImageStyle} type="image/svg+xml" data={PlayAsNines}>Table</object>                        
                    </motion.div>
                }
        </motion.div>
    )
}

export default Button