import React from "react";
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import Icon from '../../../Icons/Timer.svg'
import TableTemplate from './TableTemplate';
import TimerTwo from './TimerValueProvider/SeatTwo';
import TimerThree from './TimerValueProvider/SeatThree';
import TimerFour from './TimerValueProvider/SeatFour';

const Mover = (Value) => {
    const GameData = Value.GameData ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    const TemplateValue = TableTemplate()

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TotalWidth = percentage(currentSize.width , TemplateValue.ImageWidth)
    const TotalHeight = percentage(currentSize.width , TemplateValue.ImageHeight) 

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const Animater = () => {
        const Seat = PlayerTurn()
        
        if(Seat === 1){
            return ({ 
                scale : 0,               
            })
        } else if(Seat === 2){
            return ({
                x : 0 - percentage(TotalWidth , 7.5),
                y : percentage(TotalHeight , 15),
                scale : 1,
            })
        } else if(Seat === 3){
            return ({
                x : percentage(TotalWidth , 32),
                y : 0 - percentage(TotalHeight , 12.5),
                scale : 1,
            })
        } else if(Seat === 4){
            return ({                
                x : percentage(TotalWidth , 72),
                y : percentage(TotalHeight , 15),
                scale : 1,
            })
        } else { 
            if(GameData.BootDeduction === null || GameData.CardDistribution === null){
                return ({
                    scale : 0,
                    x : percentage(TotalWidth , 31.5),
                    y : percentage(TotalHeight , 40),
                })   
            } else if(GameData.Winner === true ){
                return ({
                    scale : 0,
                    x : percentage(TotalWidth , 31.5),
                    y : percentage(TotalHeight , 40),
                })   
            } else if(GameData.CurrentChallenge !== null ){
                return ({
                    scale : 0,
                    x : percentage(TotalWidth , 31.5),
                    y : percentage(TotalHeight , 40),
                })   
            } else {
                return ({
                    scale : 0.5,
                    x : percentage(TotalWidth , 31.5),
                    y : percentage(TotalHeight , 40),
                })
            }         
                     
        }        
    }

    const PlayerTimerValue = () => {
        if(PlayerTimerFetch() <= 0){
            return 20
        } else {
            return PlayerTimerFetch()
        }
    }

    const PlayerTimerFetch = () => {
        if(GameData.PlayerOne.Turn === true){
            return GameData.PlayerOne.TimerValue;
        } else if(GameData.PlayerTwo.Turn === true){
            return GameData.PlayerTwo.TimerValue;
        } else if(GameData.PlayerThree.Turn === true){
            return GameData.PlayerThree.TimerValue;
        } else if(GameData.PlayerFour.Turn === true){
            return GameData.PlayerFour.TimerValue;
        } else {
            return null
        }
    }

    const PlayerTurn = () => {          
        if(GameData === null){
            return null
        } else if(SeatOneValues.Turn ===  true){
            return null
        } else if(SeatTwoValues.Turn === true){
            return 2
        } else if(SeatThreeValues.Turn === true){
            return 3
        } else if(SeatFourValues.Turn === true){
            return 4
        } else {
            return null
        }
    }

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }

    const styleValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,                                         
        })
    }

    const animateValue = () => {
        if(PlayerTurn() !== null){
            return ({
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
                scale : 1
            })
        } else if(PlayerData().Turn === true){
            return ({
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
                scale : 0
            })
        } else {
            return ({
                y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
                scale : 1
            })
        }
        
    }
    
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    
    const SeatNumber = () => {
        if(GameData.PlayerOne.Turn === true){
            return GameData.PlayerOne.Seat;
        } else if(GameData.PlayerTwo.Turn === true){
            return GameData.PlayerTwo.Seat;
        } else if(GameData.PlayerThree.Turn === true){
            return GameData.PlayerThree.Seat;
        } else if(GameData.PlayerFour.Turn === true){
            return GameData.PlayerFour.Seat;
        } else {
            return null
        }
    };

    return (
        <motion.div 
        animate={
            animateValue()
        }
        style={
            styleValue()
        }>
            
                <motion.div 
                style={{
                    width :  `${percentage(BoardWidth , 25)}px`,
                    height : `${percentage(BoardHeight , 11)}px`,                  
                    position : "absolute",
                }}
                initial={
                    Animater()
                }
                animate={
                    Animater()
                }>
                <object style={{
                        width :  `${percentage(BoardWidth , 25)}px`,
                        position : "absolute",
                        zIndex : 1,
                        left : "50%",
                        top : "50%",
                        transform : "translate(-50%, -50%)",                        
                    }} type="image/svg+xml" data={Icon}>Table</object>  
                
                    <div style={{
                        position :"absolute",
                        left : "76%",
                        top : `${0 - (percentage(BoardWidth , 7.85))}px`, 
                        zIndex : 3,     
                        fontSize :  `${percentage(BoardWidth , 3.5)}px`,  
                        height :`${percentage(BoardWidth , 3.5)}px`,  
                        width :`${percentage(BoardWidth , 5)}px`,                          
                        color : "red",
                        fontWeight : "bolder",   
                        textAlign : "center"                     
                    }}>
                        { SeatNumber() === 2 && < TimerTwo SeatTwoTimerValue = { PlayerTimerValue() } /> }
                        { SeatNumber() === 3 && < TimerThree SeatThreeTimerValue = { PlayerTimerValue() } /> }
                        { SeatNumber() === 4 && < TimerFour SeatFourTimerValue = { PlayerTimerValue() } /> }         
                    </div>              
                </motion.div>            
        </motion.div>
    )
    
}

            

export default Mover