import React from 'react';
import { motion } from 'framer-motion';
import Config from '../../../../GlobalFunctions/Config';

const UserName = (Values) => {
    const BlockSize = Values.BlockSize;   

    function percentage(num, per)
    {
            return (num/100)*per;
    }

    return (<motion.div 
     
    style={{
        position : "absolute",
        width : `${percentage(BlockSize , 190)}px`,
        textAlign : "center",
        fontSize : `${percentage(BlockSize , 15)}px`,
        top : `${percentage(BlockSize , 110)}px`,
        zIndex : "23",
        left : "-12%",
        color : "#FDA80D",
        backgroundColor : "rgba(0,0,0,0.9)",
        borderTopLeftRadius : "20%",
        borderTopRightRadius : "20%",
        borderBottomLeftRadius : "20%",
        borderBottomRightRadius : "20%"
    }}>
        <div style={{
            height : `${percentage(BlockSize , 2)}px`,
        }}></div>
            { Config.MxPlayerData.playerData.playerProfileData.userName === null &&
                <div style={{
                    textOverflow : "ellipsis",
                    overflow : "hidden",
                    whiteSpace : "nowrap"
                }}>
                    YOU
                </div>
            }  

            { Config.MxPlayerData.playerData.playerProfileData.userName !== null &&
                <div style={{
                    textOverflow : "ellipsis",
                    overflow : "hidden",
                    whiteSpace : "nowrap"
                }}>
                    { Config.MxPlayerData.playerData.playerProfileData.userName }
                </div>
            }    

        <div style={{
            height : `${percentage(BlockSize , 2)}px`,
        }}></div>
    </motion.div>)
}

export default UserName