import React from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/BluffTopPanel.svg'

const Button = () => {

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    return (
        <motion.div className="slide-in-left"
         style={{
            position : "absolute",
            bottom : "41%",
            left : `${0 - percentage(BoardWidth , 10)}px`,
            width : `${percentage(BoardWidth , 120)}px`,
            height : `${percentage(BoardWidth , 26)}px`,   
            zIndex : 1                                         
            }}>
            <object style={{
            width :  `100%`,
            position : "absolute",
            zIndex : 1,
            left : "50%",
            top : "50%",
            transform : "translate(-50%, -50%)",
        }} type="image/svg+xml" data={Icon}>Table</object>  

        </motion.div>
    )
}

export default Button