import React, { useState } from 'react';
import Music from '../../../../Sound/ChallengeSound.mp3';
import MusicTwo from '../../../../Sound/OnHandWon.mp3';
import useSound from 'use-sound';
import useSetTimeout from 'use-set-timeout';

const Section = () => {
    const [ play ] = useSound(Music , { interrupt : false});
    const [ playSecond ] = useSound(MusicTwo , { interrupt : false});

    const WinningSound = () => {
        const [PlayThis , PlayThisUpdate ] = useState(null)

        useSetTimeout(() => {
            PlayThisUpdate(true)
        }, 4000)

        return (<div>
            { PlayThis === true && 
                <div>
                    { playSecond() }
                </div>
            }
        </div>)
    }

    return (
            <div>
                { play() }
                { WinningSound() }
            </div>        
    )
    
}

export default Section