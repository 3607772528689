import React , { useState , useEffect } from 'react';
import useSound from 'use-sound';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TemplateData from '../TableTemplate';
import ExitButton from './ExitButton'
import ExitButtonSelected from './ExitButtonSelected'
import MusicCold from './MusicCold'
import MusicWarm from './MusicWarm'
import MusicOff from './MusicOff'
import useSetTimeout from 'use-set-timeout';
import SoundEffectsOff from './SoundEffectsOff'
import SoundEffectsOn from './SoundEffectsOn'
import socket from '../../../../SocketConnectionManager';
import BootDeductionSound from './BootDeductionSound'
import CardDistributionSound from './CardDistributionSound'
import ChallengeSound from './ChallengeSoundPlayer'
import WinnerMusic from './WinnerMusic'
import MusicOne from '../../../../Sound/BackgroundScoreDiff.mp3';
import MusicTwo from '../../../../Sound/BackgroundScore.mp3';
import PassSound from '../../../../Sound/PassSound.mp3'
import BluffSound from '../../../../Sound/SlideCardToPlayer.mp3'
import TimeOutSound from '../../../../Sound/TimeOut.mp3'
import IsGamerAccess from '../../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import Config from '../../../../GlobalFunctions/Config';
import { Redirect } from 'react-router-dom';
import ReportQuit from '../../../../GlobalFunctions/NetworkCalls/ReportQuit'

const Section = (Data) => {
    const [ DisplayValue , DisplayValueUpdate ] = useState("none")
    const [ isExitSelected, isExitSelectedUpdate ] = useState(null)
    const [ MusicMode , MusicModeUpdate ] = useState(2)
    const [ MusicStatus , MusicStatusUpdate ] = useState(null)
    const [ SoundEffectStatus , SoundEffectUpdate ] = useState(null)
    const [ isChallengeRuning , isChallengeRuningUpdate ] = useState(null)
    const [ playOne , { stop } ] = useSound(MusicOne, { volume : 0.5  , interrupt : false});
    const [ playTwo , { pause } ] = useSound(MusicTwo, { volume : 0.2  , interrupt : false});
    const [ playThree ] = useSound(PassSound, { interrupt : false});
    const [ playFour ] = useSound(BluffSound, { interrupt : false});
    const [ playFive ] = useSound(TimeOutSound, { interrupt : false});
    const [ StartExit , StartExitUpdate ] = useState(null)
    const [ PlayDeductBoot , PlayDeductBootUpdate ] = useState(null);
    const [ PlayCardDistribution , PlayCardDistributionUpdate ] = useState(null);
    const [ PlayChallengeSound , PlayChallengeSoundUpdate ] = useState(null)    
    const [ PlayWinnerSound , PlayWinnerSoundUpdate ] = useState(null)
    const [ GamerData , GamerDataUpdate ] = useState(null)
    const [ isBlurred , isBlurredUpdate ] = useState(false)
    const [ RedirectToHome , RedirectToHomeUpdate ] = useState(false)
    const GameData = Data.GameData ;

    const EmitSignalExit =  "LeaveGameNow-Bluff";

    
    
    useEffect(() => {
        const blurCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            
            if(isBlurred !== true){
                stop()
                pause()
                MusicStatusUpdate(false)
                SoundEffectUpdate(false)
                isBlurredUpdate(true)
            }   
    
            return "";
        };
    

        const handleActivity = (event) => {
            event.preventDefault();
            event.returnValue = "";
            
            stop()
            pause()
            MusicStatusUpdate(false)
            SoundEffectUpdate(false)
            isBlurredUpdate(true)
            
    
            return "";
        };


        const focusCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            
            if(isBlurred !== false){
                isBlurredUpdate(false)
                IsGamerAccess(function(Data){
                    GamerDataUpdate(Data)
                    MusicStatusUpdate(Data.MusicStatus)
                    SoundEffectUpdate(Data.SoundEffects)

                    
                    if(MusicMode === 1){
                        playOne()
                    }
    
                    if(MusicMode === 2){
                        playTwo()
                    } 
                
                })
            }
    
            return "";
        };
    
    
        window.addEventListener('blur', blurCallback)
            
        window.addEventListener('focus', focusCallback);

        window.addEventListener("visibilitychange", handleActivity);;
        
        socket.on('EvictedFromTable' , function (){
            MusicStatusUpdate(false)
            SoundEffectUpdate(false)
            if(MusicMode === 1){
                stop()
            }

            if(MusicMode === 2){
                pause()
            }        
            
            RedirectToHomeUpdate(true)
        })

        socket.on('MasterSoundOff' , function (){
            MusicStatusUpdate(false)
            SoundEffectUpdate(false)
        })
    
        socket.on('onStopMusic' , function (){
            SoundEffectUpdate(false)
        })
    
        socket.on('onStartMusic' , function (){
            SoundEffectUpdate(true)
        })

        socket.on('PlayDeductBoot' , function (){
            if(PlayDeductBoot === null && SoundEffectStatus === true && isBlurred === false){
                PlayDeductBootUpdate(true)
            }
        })

        socket.on('PlayCardDistribution' , function (){
            if(PlayCardDistribution === null && SoundEffectStatus === true && isBlurred === false){
                PlayCardDistributionUpdate(true)
            }
        })

        socket.on('PlayChallengeSound' , function (){
            if(PlayChallengeSound === null && SoundEffectStatus === true && isBlurred === false){
                PlayChallengeSoundUpdate(true)
            }
        })

        socket.on('SwitchOffChallengeSound' , function (){
            if(PlayChallengeSound === true && SoundEffectStatus === true && isBlurred === false){
                PlayChallengeSoundUpdate(null)
            }
        })

        socket.on('SwitchOffDeductBoot' , function (){
            if(PlayDeductBoot === true && SoundEffectStatus === true && isBlurred === false){
                PlayDeductBootUpdate(false)
            }
        })

        socket.on('SwitchOffCardDistribution' , function (){
            if(PlayCardDistribution === true && SoundEffectStatus === true && isBlurred === false){
                PlayCardDistributionUpdate(false)
            }
        })

        socket.on('PlayWinnerSound' , function (){
            if(PlayWinnerSound === null && SoundEffectStatus === true && isBlurred === false){
                PlayWinnerSoundUpdate(true)
            }
        })

        socket.on('EmoteAlertMusic' , function(Data){    
            if(SoundEffectStatus === true&& isBlurred === false){
                if(Data.EmoteType === 9){
                    playThree()
                } else if(Data.EmoteType === 10){
                    playFour()
                } else if(Data.EmoteType === 17){
                    playFive()
                }
            }            
        })

        if(GamerData === null){
            IsGamerAccess(function(Data){
                GamerDataUpdate(Data)
                MusicStatusUpdate(Data.MusicStatus)
                SoundEffectUpdate(Data.SoundEffects)
            })
        }

        if(GameData !== null && GameData.CurrentChallenge === null && isChallengeRuning === true){
            isChallengeRuningUpdate(null)
        }

        return () => {
            socket.off('EvictedFromTable');
            socket.off("onStopMusic");
            socket.off('onStartMusic');            
            socket.off('PlayDeductBoot');
            socket.off('PlayCardDistribution');
            socket.off('PlayChallengeSound');
            socket.off('PlayWinnerSound');
            socket.off('SwitchOffChallengeSound');
            socket.off('EmoteAlertMusic')
            window.removeEventListener("blur", blurCallback);
            window.removeEventListener("focus", focusCallback);
        }
    }, [ SoundEffectStatus , 
        PlayDeductBoot ,
        PlayCardDistribution ,
        PlayChallengeSound ,        
        MusicStatus , 
        MusicMode , 
        PlayWinnerSound ,
        GamerData,
        GameData,
        isChallengeRuning,
        isBlurred ,
        playOne,
        playTwo,
        playThree,
        playFour,
        playFive,
        pause, 
        stop ])

    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();


    function percentage(num, per)
    {
        return (num/100)*per;
    }


    const TemplateValue = TemplateData()

    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth);

    const animateValue = () => {
        if(isExitSelected !== true){
            return ({                
                position : "absolute",
                zIndex : "2",       
                width :  `${percentage(BoardWidth , 125)}px`,   
                height : `${percentage(BoardHeight , 10)}px`,                    
                display : DisplayValue  ,
                left : `${0 - percentage(BoardWidth , 12.5)}px`,    
                top : `${0 - percentage(BoardHeight , 30)}px`,    
            })
        } else {
            return({
                position : "absolute",
                zIndex : "10",       
                width :  `${percentage(currentSize.width , 100)}px`,   
                height : `${percentage(BoardHeight , 40)}px`,    
                left : "-18%",
                top : `${0 - percentage(BoardHeight , 30)}px`,   
                display : DisplayValue    
            })
        }
    }

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const SelectExit = () => {
        if(GameData !== null && GameData.CurrentChallenge !== null){
            isChallengeRuningUpdate(true)
        }
        isExitSelectedUpdate(true)

        if(PlayChallengeSound === true){
            PlayChallengeSoundUpdate(null)
        }
    }
    

    const ExitYes = () => {
        Config.isPayloadSent = true
        window.sendCustomAnalyticsEvent("game_end", {level: 1, score: 0, highScore: 200 }); 
        if(GameData.Winner !== true){
            StartExitUpdate(true)
            isExitSelectedUpdate(false)   
            socket.emit(EmitSignalExit)  
            ReportQuit(function (data){})   
        } else {
            if(GameData.PlayerOne.Winner === true){
                StartExitUpdate(true)
                isExitSelectedUpdate(false)    
                socket.emit(EmitSignalExit)  
            } else if(GameData.PlayerOne.Winner !== true){
                StartExitUpdate(true)
                isExitSelectedUpdate(false)  
                socket.emit(EmitSignalExit)   
                ReportQuit(function (data){})
            }
            
        }
             
    }
    
    const ExitNo = () => {
        isExitSelectedUpdate(null)
    }

    const MusicSetOff = () => {        
        MusicStatusUpdate(false)
        if(MusicMode === 1){
            stop()
            MusicModeUpdate(2)
        } else {
            pause()
            MusicModeUpdate(1)
        }
    }

    const MusicSetOn = () => {
        MusicStatusUpdate(true)
    }

    const SoundSetOff = () => {
        socket.emit('SwitchOffSound')     
        SoundEffectUpdate(false)
    }

    const SoundSetOn = () => {
        socket.emit('SwitchOnSound')  
        SoundEffectUpdate(true)
    }

    
    

    const DisplayValueUpdater = () => {
        const [ Show , ShowUpdate ] = useState(null)

        useSetTimeout(() => {
            DisplayValueUpdate("")
            ShowUpdate(true)
        }, 500 )

        return (<div>
            { Show === true &&
                <div></div>
            }
        </div>)
    }

    const EmitExit = () => {
        const [ Show , ShowUpdate ] = useState(null)

        useSetTimeout(() => {
            socket.emit(EmitSignalExit)    
            ShowUpdate(true)
        }, 1000)

        return (<div>
            { Show === true &&
                <div></div>
            }
        </div>)
    } 
    
    return (<motion.div 
        style={ animateValue() }>  
        < DisplayValueUpdater /> 
        { GameData !== null && PlayerData() !== null && GamerData !== null &&
        <motion.div>
                
                < ExitButton 
                    isExitSelected = { isExitSelected } 
                    PlayerTurn = { PlayerData().Turn }
                    Winner = { GameData.Winner }
                />
                

                { isExitSelected !== true && PlayerData().Turn !== null && GameData.Winner !== true &&
                    <motion.div 
                    onTapStart={ SelectExit }
                    style={{
                        position : "absolute",
                        bottom : "0%",
                        left : `${percentage(BoardHeight , 2)}px`,
                        zIndex : 1,
                        width : `${percentage(BoardHeight , 10)}px`,
                        height : `${percentage(BoardHeight , 10)}px`,
                    }}>

                    </motion.div>
                }

                
                < ExitButtonSelected  isExitSelected = { isExitSelected } 
                    Winner = { GameData.Winner }
                /> 

                { isExitSelected === true && GameData.Winner !== true &&
                <motion.div 
                    style={{
                            position : "absolute",
                            top : "0%",
                            left :  `${percentage(BoardWidth , 20)}px`,
                            width : `${percentage(BoardWidth , 100)}px`,
                            height : `${percentage(BoardHeight , 40)}px`, 
                        }}>

                        <motion.div 
                        onTapStart={ ExitYes }
                        style={{
                            position : "absolute",
                            bottom : "9%",
                            left : `15%`,
                            width : "28%",
                            height : "20%",
                            zIndex : "20",
                        }}>

                        </motion.div>

                    
                        <motion.div 
                        onTapStart={ ExitNo }
                        style={{
                            position : "absolute",
                            bottom : "9%",
                            left : `56.5%`,
                            width : "28%",
                            height : "20%",
                            zIndex : "20",           
                        }}>

                        </motion.div>
                </motion.div>
                }
                
                < MusicWarm 
                    isExitSelected = { isExitSelected }
                    MusicMode = { MusicMode }
                    MusicStatus = { MusicStatus }
                />
                
                
                
                < MusicCold 
                    isExitSelected = { isExitSelected }
                    MusicMode = { MusicMode }
                    MusicStatus = { MusicStatus }
                />
                

                { MusicStatus === true && isExitSelected !== true && 
                    <motion.div 
                    onTapStart={ MusicSetOff }
                    style={{
                        position : "absolute",
                        bottom : "0%",
                        zIndex : 3,
                        width : `${percentage(BoardHeight , 10)}px`,
                        height : `${percentage(BoardHeight , 10)}px`,
                        right : `${percentage(BoardHeight , 1)}px`,
                    }}>

                    </motion.div>
                }

                
                < MusicOff MusicStatus = { MusicStatus }  isExitSelected = { isExitSelected } />
                

                { MusicStatus === false && isExitSelected !== true && 
                    <motion.div 
                    onTapStart={ MusicSetOn }
                    style={{
                        position : "absolute",
                        bottom : "0%",
                        right : `${percentage(BoardHeight , 1)}px`,
                        zIndex : 1,
                        width : `${percentage(BoardHeight , 10)}px`,
                        height : `${percentage(BoardHeight , 10)}px`,
                    }}>

                    </motion.div>
                }

                
                < SoundEffectsOn SoundEffectStatus = { SoundEffectStatus } isExitSelected = { isExitSelected } />
                

                { SoundEffectStatus === true && isExitSelected !== true && 
                    <motion.div 
                    onTapStart={ SoundSetOff }
                    style={{
                        position : "absolute",
                        bottom : `${0 - percentage(BoardHeight , 12)}px`,   
                        right : `${percentage(BoardHeight , 1)}px`,
                        zIndex : 1,
                        width : `${percentage(BoardHeight , 10)}px`,
                        height : `${percentage(BoardHeight , 10)}px`,
                    }}>

                    </motion.div>
                }

                
                < SoundEffectsOff SoundEffectStatus = { SoundEffectStatus } isExitSelected = { isExitSelected } />
                

                { SoundEffectStatus === false && isExitSelected !== true && 
                    <motion.div 
                    onTapStart={ SoundSetOn }
                    style={{
                        position : "absolute",
                        bottom : `${0 - percentage(BoardHeight , 12)}px`,   
                        right : `${percentage(BoardHeight , 1)}px`,
                        zIndex : 1,
                        width : `${percentage(BoardHeight , 10)}px`,
                        height : `${percentage(BoardHeight , 10)}px`,
                    }}>

                    </motion.div>
                }

                { MusicStatus === true && isBlurred === false &&
                    <div>
                        { MusicMode === 1 && playOne() }            
                        { MusicMode === 2 && playTwo() }  
                    </div>
                }

                { SoundEffectStatus === true && isBlurred === false &&
                    <div>
                        { PlayDeductBoot === true &&
                            <BootDeductionSound />
                        }

                        { PlayCardDistribution === true &&
                            < CardDistributionSound /> 
                        }

                        { PlayChallengeSound === true &&
                            < ChallengeSound />     
                        }

                        { PlayWinnerSound === true && 
                            < WinnerMusic /> 
                        }
                    </div>
                }

            </motion.div>
            }

            { StartExit === true && <EmitExit /> }

            { RedirectToHome === true  && < Redirect to={"/"}/> }

        </motion.div>                 
    )
    

}

export default Section