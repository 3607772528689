import React , { useState , useEffect } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import IconOne from '../../../Icons/EvictedPopup.svg'
import IconTwo from '../../../Icons/EvictedPopupNoAd.svg'
import TableTemplate from '../GameAssets/TableTemplate';
import IsGamerAccess from '../../../GlobalFunctions/NetworkCalls/IsGamerAccess'
import Config from '../../../GlobalFunctions/Config';
import socket from '../../../SocketConnectionManager';

const Loader = (Value) => {  
    const GameData = Value.GameData 
    const EmitSignalExit =  "LeaveGameNow-Bluff";
    const [ GamerData , GamerDataUpdate ] = useState(null)
    const [ ADPresent , ADPresentUpdate ] = useState(null)

    const MX = window.MX;

    const AdClaimedValue = (Value) => {
        if(Value.CurrentTableAdSeen === true){
            return 1
        } else if(Value.CurrentTableAdSeen === false){
            return 0
        }
    }
    

    useEffect(() => {
        MX.notifyGameState(MX.GameState.Result);
        
        if(GameData !== null && GameData.Winner === true){
            MX.notifyGameState(MX.GameState.Result);
        }

        socket.emit("MasterSoundOff")

        if(ADPresent === null){
            window.MX.AdsManager.checkRewardedAdExists(function(result){
                if(result.status === 0) {
                    ADPresentUpdate(true)              
                } else {
                    ADPresentUpdate(false)
                }     
            });
        }
        
        if(GamerData === null){
            IsGamerAccess(function(Data){
                GamerDataUpdate(Data)
            })
        }
    },[ GamerData , ADPresent, MX, GameData ])

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    

    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const ADPresentDialog = () => {
        if(ADPresent === true){
            return ({
                scale : 1
            })
        } else {
            return ({
                scale : 0
            })
        }
    }

    const ADNotPresentDialog = () => {
        if(ADPresent === false){
            return ({
                scale : 1
            })
        } else {
            return ({
                scale : 0
            })
        }
    }

    const ExecuteDraw = () => {
        window.MX.AdsManager.showRewardedAd({
            autoPlayed : 1,
            position : "end" 
         }
         , function(data){
            if (data.status === 0) {
                ReportDraw()
            } else {
                ReportQuit()
            }
        })
        
        window.MX.onTrack("gameAdClicked",{
            autoPlayed : 1, 
            position : "end"
        });        
    }

    const ReportDraw = () => {
        Config.isPayloadSent = true
        socket.emit(EmitSignalExit)
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 1,
            adGameEndOpportunity : 1,
            adGamePowerupClaimed : AdClaimedValue(GamerData) + 1,
            score: 0,
            lastLevel: 1,
            result: "draw", 
            useCumulatedScoring : true,
        });
    }

    const ReportQuit = () => {
        Config.isPayloadSent = true
        socket.emit(EmitSignalExit)
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 1,
            adGameEndOpportunity : 1,
            adGamePowerupClaimed : AdClaimedValue(GamerData),
            score: -50,
            lastLevel: 1,
            result: "quit", 
            useCumulatedScoring : true,
        });
    }

    let callback = function (event_name, info) {
        switch (event_name) {
            case "backPressed": {
                ReportQuit()
            break;
            }
            case "screenOn": {
            break;
            }
            case "screenOff": {                
            break;
            }
            case "pagePause": {                 
            break;
            }
            case "pageResume": {                
            break;
            }
            case "userPresent": {                
            break;
            }
            default:
        }
    }

    MX.listenEvent("SystemEvents", callback, this);




    if(TableType >= 1){
    return (
        <motion.div style={{
            position : "absolute",
            width : `${percentage(currentSize.width , 100)}px`,   
            height : `${percentage(currentSize.height , 100)}px`,               
            zIndex : "80",
            top : "0%",
            left : "0%",
            backgroundColor : "rgba(0,0,0,0.8)"
        }}>
            <motion.div 
                initial={{ scale : 0 }}
                animate={ ADPresentDialog() }
                style={{
                        position : "absolute",
                        bottom : "4%",
                        left : `${percentage(currentSize.width , 50) -  (percentage(BoardWidth, 90)/2)}px`,   
                        width : `${percentage(BoardWidth , 90)}px`,
                        height : `${percentage(BoardWidth , 55)}px`,
                    }}>
                    <object style={{
                    width :  `100%`,
                    position : "absolute",
                    zIndex : 1,
                    left : "50%",
                    top : "50%",
                    transform : "translate(-50%, -50%)",
                }} type="image/svg+xml" data={IconOne}>Table</object> 
            </motion.div>

            <motion.div 
                initial={{ scale : 0 }}
                animate={ ADNotPresentDialog() }
                style={{
                        position : "absolute",
                        bottom : "4%",
                        left : `${percentage(currentSize.width , 50) -  (percentage(BoardWidth, 90)/2)}px`,   
                        width : `${percentage(BoardWidth , 90)}px`,
                        height : `${percentage(BoardWidth , 55)}px`,
                    }}>
                    <object style={{
                    width :  `100%`,
                    position : "absolute",
                    zIndex : 1,
                    left : "50%",
                    top : "50%",
                    transform : "translate(-50%, -50%)",
                }} type="image/svg+xml" data={IconTwo}>Table</object> 
            </motion.div>

            { ADPresent === true && 
            <motion.div style={{
                position : "absolute",
                bottom : "4%",
                left : `${percentage(currentSize.width , 50) -  (percentage(BoardWidth, 90)/2)}px`,   
                width : `${percentage(BoardWidth , 90)}px`,
                height : `${percentage(BoardWidth , 55)}px`,
                zIndex : 2,
            }}>
                <motion.div 
                onTapStart={ReportQuit}
                style={{
                    position : "absolute",
                    bottom : "15%",
                    left : `9%`,   
                    width : `38.5%`,
                    height : `35%`,
                    zIndex : 2,
                }}>

                </motion.div>

                <motion.div 
                onTapStart={ExecuteDraw}
                style={{
                    position : "absolute",
                    bottom : "15%",
                    left : `52.5%`,   
                    width : `38.5%`,
                    height : `35%`,
                    zIndex : 2,
                }}>

                </motion.div>
            </motion.div> }

            { ADPresent === false && 
            <motion.div             
            style={{
                position : "absolute",
                bottom : "4%",
                left : `${percentage(currentSize.width , 50) -  (percentage(BoardWidth, 90)/2)}px`,   
                width : `${percentage(BoardWidth , 90)}px`,
                height : `${percentage(BoardWidth , 55)}px`,
                zIndex : 2,
            }}>
                <motion.div 
                onTapStart={ReportQuit}
                style={{
                    position : "absolute",
                    bottom : "15%",
                    left : `31%`,   
                    width : `38.5%`,
                    height : `35%`,
                    zIndex : 2,
                }}>

                </motion.div>
            </motion.div> }
        </motion.div>        
    )
    } else {
        return (<motion.div>            
        </motion.div>)
    }
    
}

export default Loader