import React , { useState , useEffect } from "react"; 
import { motion }  from "framer-motion";
import CardPile from './CardPile'

const Mover = (Value) => {
    const GameData = Value.GameData ;
    const [ isLoaded , isLoadedUpdate ] = useState(null)

    useEffect(() => {
        isLoadedUpdate(true)
    },[ isLoaded ])

    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const PlayerTurn = () => {
        if(GameData === null){
            return null
        } else if(SeatOneValues.isBluffer ===  true){
            return 1
        } else if(SeatTwoValues.isBluffer === true){
            return 2
        } else if(SeatThreeValues.isBluffer === true){
            return 3
        } else if(SeatFourValues.isBluffer === true){
            return 4
        } else {
            return null
        }
    }
    
    return (
        <motion.div>
            { GameData.CurrentBluff !== null && isLoaded === true &&
                <motion.div>
                    <CardPile GameData={GameData} Seat={ PlayerTurn() }/>
                </motion.div>
            }
        </motion.div>
    )    
}            

export default Mover