import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/TimerPlayer.svg'
import TimerOne from '../TimerValueProvider/SeatOne';

const Button = (Value) => {
    const [ IconLoaded , IconLoadedUpdate ] = useState(null)
    const GameData = Value.GameData ;
    const BluffSelected = Value.BluffSelected ;
    const PlayerTurn = Value.PlayerTurn ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    const TotalWidth = percentage(currentSize.width , TemplateValue.ImageWidth)
    const TotalHeight = percentage(currentSize.width , TemplateValue.ImageHeight) 

    const AnimateValue = () => {
        if(PlayerTurn === true && IconLoaded === true){   
            if(BluffSelected !== true) {
                return({
                    x : percentage(BoardWidth , 32),  
                    y : percentage(BoardHeight , 61), 
                    scale : 1                     
                })  
            } else {
                return({
                    x : percentage(BoardWidth , 30),  
                    y : percentage(BoardHeight , 12),  
                    scale : 1.5
                }) 
            }                             
        } else {
            return ({
                scale : 0,
                x : percentage(TotalWidth , 31.5),
                y : percentage(TotalHeight , 40),
            })
        }
    }

    const PlayerTimerValue = () => {
        if(PlayerTimerFetch() <= 0){
            return 20
        } else {
            return PlayerTimerFetch()
        }
    }

    const PlayerTimerFetch = () => {
        if(GameData.PlayerOne.Turn === true){
            return GameData.PlayerOne.TimerValue;
        } else if(GameData.PlayerTwo.Turn === true){
            return null
        } else if(GameData.PlayerThree.Turn === true){
            return null
        } else if(GameData.PlayerFour.Turn === true){
            return null
        } else {
            return null
        }
    }

    const TransitionValue = () => {
        if(BluffSelected !== true) {
            return({
                delay : 0 , duration : 0.2                 
            })  
        } else {
            if(GameData.PlayerOne.Turn === true){
                return({
                    delay : 0.5  , duration : 0.2
                })  
            } else {
                return ({
                    delay : 0 , duration : 0.1
                })
            }
            
        }
    }

    return (
        <motion.div  
        style={{
            position : "absolute", 
            zIndex : "2",
            width  : `${ percentage(BoardWidth , 38)}px`,                                            
            height : `${ percentage(BoardHeight , 7)}px`,
        }}
        animate={ AnimateValue() }
        transition={ TransitionValue() }
        >
                     <object 
                        onLoad={() => IconLoadedUpdate(true) }
                        style={{
                        width :  `100%`,
                        position : "absolute",
                        zIndex : 1,
                        left : "50%",
                        top : "50%",
                        transform : "translate(-50%, -50%)",
                    }} type="image/svg+xml" data={Icon}>Table</object>   
                    
                    <motion.div                     
                    style={{
                        position : "absolute",
                        fontSize :  `${percentage(BoardWidth , 4.75)}px`,  
                        height :`${percentage(BoardWidth , 3.5)}px`,  
                        width :`${percentage(BoardWidth , 5)}px`,                          
                        color : "red",
                        fontWeight : "bolder",   
                        textAlign : "center" ,
                        zIndex : 2,
                        x :  percentage(BoardWidth , 29),
                        y :  percentage(BoardHeight , 1),
                    }}>
                       { PlayerTimerFetch() !== null && < TimerOne SeatOneTimerValue = { PlayerTimerValue() } /> }
                    </motion.div>                    
        </motion.div>
    )
}

export default Button