import React , { useState , useEffect } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import socket from '../../../SocketConnectionManager';
import Icon from '../../../Icons/ExitGameDisplay.svg'
import useSetTimeout from 'use-set-timeout';

const Loader = () => {
    const [ isExitCancelled , isExitCancelledUpdate ] = useState(false)
    const MX = window.MX ;      

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    useEffect(() => {
        
    }, [  ])


    const sizeManager = () => {
        return ({
            position : "absolute",
            width : `${percentage(currentSize.width , 100)}px`,   
            height : `${percentage(currentSize.height , 100)}px`,               
            zIndex : "80",
            top : "0%",
            left : "0%"
        })
    }

    const DefaultClass = () => {        
        if(isExitCancelled === false){
            return ("slide-in-left")
        } else {
            return ("flip-out-hor-top-fast")
        }
    }

    

    const QuitGame = () => {
        MX.notifyGameState(MX.GameState.Close, {
            adGameStartOpportunity : 0,
            adGameEndOpportunity : 0,
            adGamePowerupClaimed : 0,
            score: 0,
            lastLevel: 1,
            result: "quit",
            useCumulatedScoring : true,
        });
    }

    const Cancel = () => {
        isExitCancelledUpdate(true)        
    }

    const RedirectBackToProfile = () => {
        const [ Update , Updater ] = useState(false)

        useSetTimeout(() => {
            Updater(true)
            socket.emit('Cancel-ExitGame')
        }, 300)

        return(<div>
            { Update === true && <div></div>}
        </div>)
    }

    if(TableType >= 1){
    return (
        <motion.div style={ sizeManager()}>
            
            <motion.div
                style={{
                    position : "absolute",
                    width : "80%",
                    zIndex : "1",
                    left : "50%",
                    top : "50%",
                    transform : "translate(-50%, -50%)", 
                }}>
                    <object 
                    className={ DefaultClass() }                    
                    style={{
                        width : "100%",
                    }} type="image/svg+xml" data={Icon}>Floor</object>  

                    <motion.div
                    onTapStart={QuitGame}
                    style={{
                        position : "absolute",
                        width : `${percentage(currentSize.width , 65)}px`,
                        height : `${percentage(currentSize.width , 14)}px`,                        
                        top : `${(percentage(currentSize.width , 3))}px`,
                        left : `${percentage(currentSize.width , 7.5)}px`,
                    }}>

                    </motion.div>

                    <motion.div 
                    onTapStart={Cancel}
                    style={{
                        position : "absolute",
                        width : `${percentage(currentSize.width , 65)}px`,
                        height : `${percentage(currentSize.width , 14)}px`,                        
                        top : `${(percentage(currentSize.width , 29))}px`,
                        left : `${percentage(currentSize.width , 7.5)}px`,
                    }}>

                    </motion.div>
                
            </motion.div> 

            { isExitCancelled === true && < RedirectBackToProfile /> }

        </motion.div>        
    )
    } else {
        return (<motion.div>            
        </motion.div>)
    }
    
}

export default Loader