import React , { useEffect, useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import useSound from 'use-sound';
import ArrayList from 'arraylist';
import TableTemplate from '../TableTemplate';
import CardInfoDisplay from './CardInfoDisplay'
import SlotOneDisplay from './SlotOneDisplay';
import SlotTwoDisplay from './SlotTwoDisplay';
import SlotThreeDisplay from './SlotThreeDisplay';
import SlotFourDisplay from './SlotFourDisplay';
import SlotFiveDisplay from './SlotFiveDisplay';
import SlotSixDisplay from './SlotSixDisplay';
import BluffButton from './BluffButton';
import ChallengeButton from './ChallengeButton';
import PassButton from './PassButton'
import CardDisplaySection from './CardDisplaySection'
import BluffBackgroundScreen from './BluffBackgroundScreen'
import BluffCloseButton from './BluffCloseButton'
import BluffInformation from './BluffInformation'
import BluffBottomPanel from './BluffBottomPanel'
import BluffSlotsPanel from './BluffSlotsPanel'
import BluffRunning from './BluffRunning'
import CreateBluff from './CreateBluff'
import ZeroCardsInfo from './ZeroCardsInfo';
import AwaitingMatchResult from './AwaitingMatchResult';
import OnNoTurnCardClick from './OnNoTurnCardClick';
import MatchEnded from './MatchEnded';
import PlayerTurnTimer from './PlayerTurnTimer';
import PlayerBlufferIcon from './PlayerBlufferIcon';
import PlayerChallengerIcon from './PlayerChallengerIcon';
import socket from '../../../../SocketConnectionManager';
import Sound from '../../../../Sound/OnHandPlayed.mp3'


const Section = (Value) => {
    const GameData = Value.GameState
    const GamerData = Value.GamerData
    const PlayerTurn = Value.PlayerTurn
    const PlayerData = Value.GameState.PlayerOne
    

    const AllAces = GameData.MyCards.Aces;
    const AllKings = GameData.MyCards.Kings;
    const AllQueens = GameData.MyCards.Queens;
    const AllJacks = GameData.MyCards.Jacks;
    const AllTens = GameData.MyCards.Tens;
    const AllNines = GameData.MyCards.Nines;
    const SoundEffectStatus = Value.SoundEffectStatus

    const [ AllBluffAces , AllAcesUpdate ]  = useState(GameData.MyCards.Aces);
    const [ AllBluffKings , AllKingsUpdate ]  = useState(GameData.MyCards.Kings);
    const [ AllBluffQueens , AllQueensUpdate ]  = useState(GameData.MyCards.Queens);
    const [ AllBluffJacks , AllJacksUpdate ]  = useState(GameData.MyCards.Jacks);
    const [ AllBluffTens , AllTensUpdate ]  = useState(GameData.MyCards.Tens);
    const [ AllBluffNines , AllNinesUpdate ]  = useState(GameData.MyCards.Nines);


    const [ SlotOneValue , SlotOneValueUpdate ] = useState(null);
    const [ SlotTwoValue , SlotTwoValueUpdate ] = useState(null);
    const [ SlotThreeValue , SlotThreeValueUpdate ] = useState(null);
    const [ SlotFourValue , SlotFourValueUpdate ] = useState(null);
    const [ SlotFiveValue , SlotFiveValueUpdate ] = useState(null);
    const [ SlotSixValue , SlotSixValueUpdate ] = useState(null);
    const [ TotalOccupiedSlots , TotalOccupiedSlotsUpdate ] = useState(0);
    const [ BluffSelected , BluffSelectedUpdate ] = useState(null)
    const [ ChallengeSelected , ChallengeSelectedUpdate ] = useState(null)
    const [ PassSelected , PassSelectedUpdate ] = useState(null)
    const [ BluffSubmitted, BluffSubmittedUpdate ] = useState(null)
    
    const [ playOne] = useSound(Sound, { interrupt : false});
    const [ ActionSubmitted , ActionSubmittedUpdate ] = useState(false)

    useEffect(() => {        
        if(PlayerTurn !== true && ActionSubmitted === true){
            ActionSubmittedUpdate(false)
            BluffSelectedUpdate(null)
            BluffSubmittedUpdate(null)
            TotalOccupiedSlotsUpdate(0)
            SlotOneValueUpdate(null)
            SlotTwoValueUpdate(null)
            SlotThreeValueUpdate(null)
            SlotFourValueUpdate(null)
            SlotFiveValueUpdate(null)
            SlotSixValueUpdate(null)
            PassSelectedUpdate(null)
            ChallengeSelectedUpdate(null)
        } else if(PlayerTurn !== true && BluffSelected === true){
            BluffSelectedUpdate(null)
        }

        if((PlayerData.TotalCards !== AllBluffAces.length + AllBluffKings.length + AllBluffQueens.length +
                                        AllBluffJacks.length + AllBluffTens.length + AllBluffNines.length) && BluffSelected !== true){
            //console.log("CARD COUNT UPDATED")
            if(AllAces.length !== AllBluffAces.length){ AllAcesUpdate(AllAces) }
            if(AllKings.length !== AllBluffKings.length){ AllKingsUpdate(AllKings) }
            if(AllQueens.length !== AllBluffQueens.length){ AllQueensUpdate(AllQueens) }
            if(AllJacks.length !== AllBluffJacks.length){ AllJacksUpdate(AllJacks) }
            if(AllTens.length !== AllBluffTens.length){ AllTensUpdate(AllTens) }
            if(AllNines.length !== AllBluffNines.length){ AllNinesUpdate(AllNines) }
        } else if((PlayerData.TotalCards !== AllBluffAces.length + AllBluffKings.length + AllBluffQueens.length +
            AllBluffJacks.length + AllBluffTens.length + AllBluffNines.length)){
            //console.log("UPDATE REQUIRED")
        } else {
            //console.log("CARD COUNT NOT UPDATED")
        }
    }, [ActionSubmitted , BluffSelected, PlayerTurn, AllAces, AllKings, 
        AllQueens, AllJacks, AllTens, AllNines, AllBluffAces, AllBluffKings, 
        AllBluffQueens, AllBluffJacks, AllBluffTens, AllBluffNines , PlayerData
    ])
    
    

    

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()

    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                                                       
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20,
            x : 0,
            scale : 1    ,
            display : ""        
        })
    }

    
    const SelectBluff = () => {
        if(SoundEffectStatus === true){
            playOne()
        }
        BluffSelectedUpdate(true)                
    }

    const SelectChallenge = () => {
        ChallengeSelectedUpdate(true)   
        socket.emit('ChallengeAction');    
        ActionSubmittedUpdate(true) 
    }

    const SelectPass = () => {
        PassSelectedUpdate(true)        
        socket.emit('PassAction');
        ActionSubmittedUpdate(true)
    }

    const CancelBluff = () => {
        if(SoundEffectStatus === true){
            playOne()
        }
        SlotOneValueUpdate(null)
        SlotTwoValueUpdate(null)
        SlotThreeValueUpdate(null)
        SlotFourValueUpdate(null)
        SlotFiveValueUpdate(null)
        SlotSixValueUpdate(null)
        BluffSelectedUpdate(null)
        TotalOccupiedSlotsUpdate(0)
        AllAcesUpdate(AllAces)
        AllKingsUpdate(AllKings)
        AllQueensUpdate(AllQueens)
        AllJacksUpdate(AllJacks)
        AllTensUpdate(AllTens)
        AllNinesUpdate(AllNines)        
    }

    const PlayBluff = () => {
        var BluffData = null;
        var CardsPlayed = new ArrayList ();
    
        if(SlotOneValue !== null){
            CardsPlayed.add(SlotOneValue);
        } 
    
        if(SlotTwoValue !== null){
            CardsPlayed.add(SlotTwoValue);
        } 
    
        if(SlotThreeValue !== null){
            CardsPlayed.add(SlotThreeValue);
        } 
        
        if(SlotFourValue !== null){
            CardsPlayed.add(SlotFourValue);
        } 
    
        if(SlotFiveValue !== null){
            CardsPlayed.add(SlotFiveValue);
        } 
    
        if(SlotSixValue !== null){
            CardsPlayed.add(SlotSixValue);
        } 
    
        BluffData = {
            "Cards" : CardsPlayed ,
            "Claim" : GameData.CurrentBluff.Claim
        }
        
        
        SlotOneValueUpdate(null);
        SlotTwoValueUpdate(null);
        SlotThreeValueUpdate(null);
        SlotFourValueUpdate(null);
        SlotFiveValueUpdate(null);
        SlotSixValueUpdate(null);

        BluffSubmittedUpdate(true);

        socket.emit('BluffAction' , BluffData)
        ActionSubmittedUpdate(true)
    }

    const CreatePlayBluff = (Series) => {
        var BluffData = null;
        var CardsPlayed = new ArrayList ();
    
        if(SlotOneValue !== null){
            CardsPlayed.add(SlotOneValue);
        } 
    
        if(SlotTwoValue !== null){
            CardsPlayed.add(SlotTwoValue);
        } 
    
        if(SlotThreeValue !== null){
            CardsPlayed.add(SlotThreeValue);
        } 
        
        if(SlotFourValue !== null){
            CardsPlayed.add(SlotFourValue);
        } 
    
        if(SlotFiveValue !== null){
            CardsPlayed.add(SlotFiveValue);
        } 
    
        if(SlotSixValue !== null){
            CardsPlayed.add(SlotSixValue);
        } 
    
        BluffData = {
            "Cards" : CardsPlayed ,
            "Claim" : Series
        }
        
        
        SlotOneValueUpdate(null);
        SlotTwoValueUpdate(null);
        SlotThreeValueUpdate(null);
        SlotFourValueUpdate(null);
        SlotFiveValueUpdate(null);
        SlotSixValueUpdate(null);

        
        BluffSubmittedUpdate(true);
        socket.emit('BluffAction' , BluffData)
        ActionSubmittedUpdate(true)
    }

    
    const OnCardsClicked = () => {
        if(SoundEffectStatus === true && PlayerData.Turn === true){
            playOne()
        }
        if(GameData.ZeroCards === false && BluffSelected !== true){
            BluffSelectedUpdate(true)
        }
    }

    const SelectCard = (CardValue) => {
        if(SoundEffectStatus === true){
            playOne()
        }
        const CardList = new ArrayList ()
        if(TotalOccupiedSlots < 6){
            if(CardValue === "A" && AllBluffAces.length > 0){
                CardList.add(AllBluffAces);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllAcesUpdate(CardList)
            } else if(CardValue === "K" && AllBluffKings.length > 0){
                CardList.add(AllBluffKings);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllKingsUpdate(CardList)
            } else if(CardValue === "Q" && AllBluffQueens.length > 0){
                CardList.add(AllBluffQueens);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllQueensUpdate(CardList)
            } else if(CardValue === "J" && AllBluffJacks.length > 0){
                CardList.add(AllBluffJacks);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllJacksUpdate(CardList)
            } else if(CardValue === "10" && AllBluffTens.length > 0){
                CardList.add(AllBluffTens);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllTensUpdate(CardList)
            } else if(CardValue === "9" && AllBluffNines.length > 0){
                CardList.add(AllBluffNines);
                UpdateOnSlot(CardList[0])
                CardList.remove(0)
                AllNinesUpdate(CardList)
            }
        }
    }

    const UpdateOnSlot = (CardValue) => {
        if(SlotOneValue === null){
            SlotOneValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        } else if(SlotTwoValue === null){
            SlotTwoValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        } else if(SlotThreeValue === null){
            SlotThreeValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        } else if(SlotFourValue === null){
            SlotFourValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        } else if(SlotFiveValue === null){
            SlotFiveValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        } else if(SlotSixValue === null){
            SlotSixValueUpdate(CardValue)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots + 1)
        }
    }

    const Deselect = (Value) => {
        if(Value === 1 && SlotOneValue !== null){
            PopulateCard(SlotOneValue)
            SlotOneValueUpdate(null)            
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        } else if(Value === 2 && SlotTwoValue !== null){
            PopulateCard(SlotTwoValue)
            SlotTwoValueUpdate(null)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        } else if(Value === 3 && SlotThreeValue !== null){
            PopulateCard(SlotThreeValue)
            SlotThreeValueUpdate(null)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        } else if(Value === 4 && SlotFourValue !== null){
            PopulateCard(SlotFourValue)
            SlotFourValueUpdate(null)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        } else if(Value === 5 && SlotFiveValue !== null){
            PopulateCard(SlotFiveValue)
            SlotFiveValueUpdate(null)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        } else if(Value === 6 && SlotSixValue !== null){
            PopulateCard(SlotSixValue)
            SlotSixValueUpdate(null)
            TotalOccupiedSlotsUpdate(TotalOccupiedSlots - 1)
        }
    }


    const PopulateCard = (Value) => {
        if(SoundEffectStatus === true){
            playOne()
        }
        const CardValue = Value.Value
        const CardList = new ArrayList ()
        if(TotalOccupiedSlots <= 6){
            if(CardValue === "A"){
                CardList.add(AllBluffAces);
                CardList.add(Value)
                AllAcesUpdate(CardList)
            } else if(CardValue === "K"){
                CardList.add(AllBluffKings);
                CardList.add(Value)
                AllKingsUpdate(CardList)
            } else if(CardValue === "Q"){
                CardList.add(AllBluffQueens);
                CardList.add(Value)
                AllQueensUpdate(CardList)
            } else if(CardValue === "J"){
                CardList.add(AllBluffJacks);
                CardList.add(Value)
                AllJacksUpdate(CardList)
            } else if(CardValue === "10"){
                CardList.add(AllBluffTens);
                CardList.add(Value)
                AllTensUpdate(CardList)
            } else if(CardValue === "9"){
                CardList.add(AllBluffNines);
                CardList.add(Value)
                AllNinesUpdate(CardList)
            }
        }
    }

    

    const BluffSelectedDisplayHandler = () => {
        if(PlayerData !== null && BluffSelected === true && BluffSubmitted === null && PlayerTurn === true){
            return ({
                x : 0, scale : 1, opacity : 1
            })
        } else if(PlayerData !== null && BluffSelected !== true && BluffSubmitted === null && PlayerTurn === true){
            return ({
                x : 0, scale : 0, opacity : 0
            })
        } else if(PlayerTurn !== true){
            return ({
                x : 0, scale : 0, opacity : 0
            })
        }
    }

    const BluffButtonControllerDisplayHandler = () => {
        if(PlayerTurn === true){
            if(GameData.ZeroCards === false && BluffSelected !== true){
                return ({
                    x : 0 - percentage(BoardWidth , 10),
                    y : percentage(BoardHeight , 65),   
                    opacity : 1
                })
            } else {
                return({
                    x : 0 - percentage(BoardWidth , 10),
                    y : percentage(BoardHeight , 65),  
                    opacity : 0
                })
            }
        } else {
            return({
                x : 0 - percentage(currentSize.width , 100),
                y : percentage(BoardHeight , 65), 
                opacity : 1 
            })
        }
    }

    const PassButtonControllerDisplayHandler = () => {
        if(PlayerTurn === true){
            if(GameData.ZeroCards === false && GameData.CurrentBluff === null && BluffSelected !== true){
                return ({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 65),  
                    opacity : 1 
                })
            } else if(GameData.ZeroCards === false && GameData.CurrentBluff !== null && BluffSelected !== true){
                return ({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 75),  
                    opacity : 1 
                })
            } else {
                return({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 65),  
                    opacity : 0
                })
            }
        } else {
            return({
                x : 0 - percentage(currentSize.width , 100),
                y : percentage(BoardHeight , 65),  
                opacity : 1
            })
        }
    }

    const ChallengeButtonControllerDisplayHandler = () => {
        if(PlayerTurn === true){
            if(GameData.ZeroCards !== true && GameData.CurrentBluff !== null && BluffSelected !== true){
                return ({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 65),  
                    opacity : 1 
                })
            } else if(GameData.ZeroCards === true && GameData.CurrentBluff !== null && BluffSelected !== true){
                return ({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 70),  
                    opacity : 1 
                })
            } else {
                return({
                    x : percentage(BoardWidth , 80),
                    y : percentage(BoardHeight , 65), 
                    opacity : 0 
                })
            }
        } else {
            return({
                x : 0 - percentage(currentSize.width , 100),
                y : percentage(BoardHeight , 65),  
                opacity : 1
            })
        }
    }

    const CardDisplaySectionDisplayHandler = () => {
        if(BluffSelected !== true ){
            return({
                x : 0 , scale : 1
            })
        } else {
            return({
                x : 0 , scale : 0
            })
        }
    }

    const BluffSelectedModifier = () => {
        if(BluffSelected === true){
            return true
        } else if(ChallengeSelected === true){
            return true
        } else if(PassSelected === true){
            return true
        } else {
            return null
        }
    }

    return (<motion.div 
    initial={{ 
        x : 0,
        y : 0,
        scale : 0,
    }}
    animate={ animateValue() }
    style={{
        position : "absolute",
        zIndex : "2",       
        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
        height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
        left : percentage(currentSize.width , LeftValue()),                                                    
        bottom : TemplateValue.BottomValue,             
    }}
    transition={{ delay : 1 , duration : 0.5 }}
    >
        
        <motion.div>
            < PlayerTurnTimer PlayerTurn={ PlayerTurn } 
                          BluffSelected ={ BluffSelected }
                          GameData = { GameData } />

            < PlayerBlufferIcon PlayerTurn={ PlayerTurn } 
                          BluffSelected ={ BluffSelected }
                          GameData = { GameData } />

            < PlayerChallengerIcon PlayerTurn={ PlayerTurn } 
                          BluffSelected ={ BluffSelected }
                          GameData = { GameData } />

            < BluffButton PlayerTurn={ PlayerTurn } 
                          BluffSelected ={ BluffSelectedModifier() }
                          GameData = { GameData } />
        
            <motion.div 
            style={{
                position : "absolute", 
                zIndex : "2",
                width  : `${ percentage(BoardWidth , 30)}px`,                                            
                height : `${ percentage(BoardHeight , 7)}px`,
            }}
            animate={ BluffButtonControllerDisplayHandler() }
            onTapStart={SelectBluff}>

            </motion.div>
        

            
            < ZeroCardsInfo PlayerTurn={ PlayerTurn } 
                        BluffSelected ={ BluffSelected }
                        GameData = { GameData } />
        

        
            < AwaitingMatchResult PlayerTurn={ PlayerTurn } 
                        BluffSelected ={ BluffSelected }
                        GameData = { GameData } />
        

        
            < ChallengeButton PlayerTurn={ PlayerTurn } 
                          BluffSelected ={ BluffSelectedModifier() }
                          GameData = { GameData } />
        

        
            <motion.div 
                style={{
                    position : "absolute", 
                    zIndex : "2",
                    width  : `${ percentage(BoardWidth , 30)}px`,                                            
                    height : `${ percentage(BoardHeight , 7)}px`,
                }}
                animate={ ChallengeButtonControllerDisplayHandler() }
                onTapStart={SelectChallenge}>

            </motion.div>
            

            < PassButton PlayerTurn={ PlayerTurn } 
                        BluffSelected ={ BluffSelectedModifier() }
                        GameData = { GameData } />
            
            <motion.div 
                style={{
                    position : "absolute", 
                    zIndex : "2",
                    width  : `${ percentage(BoardWidth , 30)}px`,                                            
                    height : `${ percentage(BoardHeight , 7)}px`,
                }}
                animate={ PassButtonControllerDisplayHandler() }
                onTapStart={SelectPass}>

            </motion.div>
                     
        </motion.div>
        
        
        <motion.div 
        style={{
            position : "absolute",
            bottom : "0%",
            width : `${percentage(BoardWidth , 120)}px`,
            height : `${percentage(BoardWidth , 25)}px`,               
        }}
        animate={ CardDisplaySectionDisplayHandler }>
            < CardDisplaySection PlayerData = { PlayerData }/>

            { AllAces !== null &&
                AllKings !== null &&
                AllQueens !== null &&
                AllJacks !== null &&
                AllTens !== null &&
                AllNines !== null &&
                < CardInfoDisplay 
                    AllAces = { AllAces }
                    AllKings = { AllKings }
                    AllQueens = { AllQueens }
                    AllJacks = { AllJacks }
                    AllTens = { AllTens }
                    AllNines = { AllNines }
                    PlayerData = { PlayerData }
                    isBluffSelected = { BluffSelected }
                />
            }     
            
        </motion.div>
        

        
        < OnNoTurnCardClick PlayerTurn={ PlayerTurn } 
                        BluffSelected ={ BluffSelected }
                        GameData = { GameData } />
    

        
        < MatchEnded  GameData={GameData} GamerData={GamerData} />
        
        
        

        
        <motion.div 
        onTapStart={ OnCardsClicked }
        style={{
            position : "absolute",
            bottom : "0%",
            left : `${0 - percentage(BoardWidth , 10)}px`,
            width : `${percentage(BoardWidth , 120)}px`,
            height : `${percentage(BoardWidth , 20)}px`,                     
            zIndex : 3,
            textAlign : "center",
        }}></motion.div>
        
        

        
        <motion.div // BLUFF SELECTED
          animate={ BluffSelectedDisplayHandler() }
          transition={{ duration : 0.1 }}
          style={{
              position : "absolute",
              height : "100%",
              width : "100%"
          }}>
            < BluffBackgroundScreen />

            

            < BluffCloseButton />

            <motion.div onTapStart={CancelBluff} style={{
                position : "absolute",
                bottom : "65%",
                left : `${percentage(BoardWidth , 40)}px`,
                width : `${percentage(BoardWidth , 20)}px`,
                height : `${percentage(BoardWidth , 18)}px`,                     
                zIndex : 3
            }}></motion.div>

            < BluffInformation />

            < BluffBottomPanel />
                            
            < BluffSlotsPanel /> 

            { GameData.CurrentBluff !== null && TotalOccupiedSlots > 0 &&
                < BluffRunning GameData = { GameData }/>
            }

            { GameData.CurrentBluff !== null && TotalOccupiedSlots > 0 &&
                <motion.div 
                onTapStart={()=>PlayBluff()}
                style={{
                    position : "absolute",
                    left : `${percentage(BoardWidth , 38)}px`,
                    bottom : "41%",
                    width : `${percentage(BoardWidth , 22)}px`,
                    height : `${percentage(BoardWidth , 15)}px`, 
                    zIndex : 4
                }}>

                </motion.div>
            }

            { GameData.CurrentBluff === null && TotalOccupiedSlots > 0 &&
                < CreateBluff />
            }

            { GameData.CurrentBluff === null && TotalOccupiedSlots > 0 &&
                <motion.div>
                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("9")}
                    style={{
                        position : "absolute",
                        left : `${0 - percentage(BoardWidth , 10)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>

                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("10")}
                    style={{
                        position : "absolute",
                        left : `${percentage(BoardWidth , 10)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>

                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("J")}
                    style={{
                        position : "absolute",
                        left : `${percentage(BoardWidth , 30.5)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>

                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("Q")}
                    style={{
                        position : "absolute",
                        left : `${percentage(BoardWidth , 50.5)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>

                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("K")}
                    style={{
                        position : "absolute",
                        left : `${percentage(BoardWidth , 70.5)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>

                    <motion.div 
                    onTapStart={()=>CreatePlayBluff("A")}
                    style={{
                        position : "absolute",
                        left : `${percentage(BoardWidth , 90.5)}px`,
                        bottom : "43%",
                        width : `${percentage(BoardWidth , 19)}px`,
                        height : `${percentage(BoardWidth , 12)}px`,                         
                        zIndex : 4
                    }}>
                        
                    </motion.div>
                </motion.div>
            }


            < CardInfoDisplay 
                AllAces = { AllBluffAces }
                AllKings = { AllBluffKings }
                AllQueens = { AllBluffQueens }
                AllJacks = { AllBluffJacks }
                AllTens = { AllBluffTens }
                AllNines = { AllBluffNines }
                PlayerData = { PlayerData }
                isBluffSelected = { BluffSelected }
            />

            <motion.div 
            onTapStart={()=>SelectCard("A")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "-9%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>

            <motion.div 
            onTapStart={()=>SelectCard("K")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "11%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>

            <motion.div 
            onTapStart={()=>SelectCard("Q")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "32%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>

            <motion.div 
            onTapStart={()=>SelectCard("J")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "52%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>

            <motion.div 
            onTapStart={()=>SelectCard("10")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "72%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>

            <motion.div 
            onTapStart={()=>SelectCard("9")}
            style={{
                position : "absolute" ,
                bottom : "1%",
                left : "92.5%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 20)}px`,                    
                zIndex : 4,
            }}>
            </motion.div>


            <motion.div 
            onTapStart={()=>Deselect(1)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "-9%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4
            }}>
                
            </motion.div>

            <motion.div 
            onTapStart={()=>Deselect(2)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "11%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4
            }}>
                
            </motion.div>

            <motion.div 
            onTapStart={()=>Deselect(3)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "32%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4
            }}>
                
            </motion.div>

            <motion.div 
            onTapStart={()=>Deselect(4)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "52%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4,
            }}>
                
            </motion.div>

            <motion.div 
            onTapStart={()=>Deselect(5)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "72%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4,
            }}>
                
            </motion.div>

            <motion.div 
            onTapStart={()=>Deselect(6)}
            style={{
                position : "absolute" ,
                bottom : "22%",
                left : "92.5%",
                width : `${percentage(BoardWidth , 16)}px`,
                height : `${percentage(BoardWidth , 17)}px`,                    
                zIndex : 4,
            }}>
                
            </motion.div>

            <motion.div style={{
                position : "absolute",
                bottom : "22%",
                left : `${0 - percentage(BoardWidth , 10)}px`,
                width : `${percentage(BoardWidth , 120)}px`,
                height : `${percentage(BoardWidth , 24)}px`,                      
                zIndex : 3  
            }}>
                < SlotOneDisplay Card = { SlotOneValue }/>
                < SlotTwoDisplay Card = { SlotTwoValue }/>
                < SlotThreeDisplay Card = { SlotThreeValue }/>
                < SlotFourDisplay Card = { SlotFourValue }/>
                < SlotFiveDisplay Card = { SlotFiveValue }/>
                < SlotSixDisplay Card = { SlotSixValue }/>
            </motion.div>
        </motion.div>  
        
        
    </motion.div>)
}

export default Section