import React from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from './TableTemplate';
import Icon from '../../../Icons/CardPile.svg'
import CardBackground from '../../../Icons/CardBackground.svg';
import SeriesA from '../../../Icons/SeriesA.svg'
import SeriesK from '../../../Icons/SeriesK.svg'
import SeriesQ from '../../../Icons/SeriesQ.svg'
import SeriesJ from '../../../Icons/SeriesJ.svg'
import Series10 from '../../../Icons/Series10.svg'
import Series9 from '../../../Icons/Series9.svg'
import ChallengeWinnerIcon from '../../../Icons/ChallengeWinner.svg'
import Correct from '../../../Icons/Correct.svg'
import Wrong from '../../../Icons/Wrong.svg'

const Section = (Data) => {
    const GameData = Data.GameData ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    
    const TemplateValue = TableTemplate()

    const IconPosition = () => ({
        position : "absolute",
        left : "50%",   
        transform : "translate(-50%, -50%)",
        top : "41%" ,
    })

    const IconSize = () => ({
        width :  `${percentage(BoardWidth , 20)}px`,
        height : `${percentage(BoardHeight , 8)}px`,                  
        position : "absolute",
    })

    const ImageSize = () => ({
        width :  `${percentage(BoardWidth , 25)}px`,
        position : "absolute",
        zIndex : 1,
        left : "50%",
        top : "50%",
        transform : "translate(-50%, -50%)",
    })
   

    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20                                      
        })
    }

    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const BluffersCards = () => {
        if(GameData.CurrentChallenge === null){
            return []
        } else {
            return GameData.CurrentChallenge.BluffData.BluffCards
        }
    }

    const CardsPlayed = BluffersCards ()

    const CardSize = `${percentage(BoardWidth , 12)}px`;
    const TickSize = `${percentage(BoardWidth , 5)}px`;

    const NumberOfCardsBluffed = () => {        
        if(GameData.CurrentChallenge !== null){
            return (GameData.CurrentChallenge.BluffData.NumberOfCardsBluffed)
        } else {
            return (null)
        }        
    }

    const BluffersCardsStyleProvider = () => {
        if(GameData.CurrentChallenge !== null && ( NumberOfCardsBluffed() <= 3)){
           return ({
            position : "absolute",
            top : `${percentage(BoardHeight , 47)}px`,
            width : `${percentage(BoardWidth , 35)}px`,
            height : `${percentage(BoardWidth , 15)}px`,
            left : `${percentage(BoardWidth , 33)}px`,
            backgroundColor : "rgba(0,0,0,0.5)",
            textAlign : "center",
            borderRadius : `${percentage(BoardWidth , 2)}px`,
           })
        } else if(GameData.CurrentChallenge !== null && (NumberOfCardsBluffed() <= 5 )){
            return ({
             position : "absolute",
             top : `${percentage(BoardHeight , 47)}px`,
             width : `${percentage(BoardWidth , 55)}px`,
             height : `${percentage(BoardWidth , 15)}px`,
             left : `${percentage(BoardWidth , 22.5)}px`,
             backgroundColor : "rgba(0,0,0,0.5)",
             textAlign : "center",
             borderRadius : `${percentage(BoardWidth , 2)}px`,
            })
         } else if(GameData.CurrentChallenge !== null && (NumberOfCardsBluffed() === 6 )){
            return ({
             position : "absolute",
             top : `${percentage(BoardHeight , 47)}px`,
             width : `${percentage(BoardWidth , 70)}px`,
             height : `${percentage(BoardWidth , 15)}px`,
             left : `${percentage(BoardWidth , 15)}px`,
             backgroundColor : "rgba(0,0,0,0.5)",
             textAlign : "center",
             borderRadius : `${percentage(BoardWidth , 2)}px`,
            })
         } else {
            return (null)
        }
    }

    const LoserPositionFinder = () => {
       const LoserSeat = GameData.CurrentChallenge.BluffData.Loser ;

        if(LoserSeat === 1){
            return ({
                position : "absolute",
                left : "82%",     
                transform : "translate(-50%, -50%)",
                top : "70%", 
            })
        } if(LoserSeat === 2){
            return ({
                position : "absolute",
                left : "10%",   
                transform : "translate(-50%, -50%)",
                top : "36%" ,
            })
        } if(LoserSeat === 3){
            return ({
                position : "absolute",
                left : "80%", 
                transform : "translate(-50%, -50%)",
                top : "-9%",
            })
        } if(LoserSeat === 4){
            return ({
                position : "absolute",
                left : "90%",   
                transform : "translate(-50%, -50%)",
                top : "36%" ,
            })
        } 

        return null
    }

    const LoserPositionCardCountFinder = () => {
        const LoserSeat = GameData.CurrentChallenge.BluffData.Loser ;        

        if(LoserSeat === 1){
            return ({
                position : "absolute",
                left : "82%",    
                transform : "translate(-50%, -50%)",
                top : "80%", 
            })
        } if(LoserSeat === 2){
            return ({
                position : "absolute",
                left : "5%",   
                transform : "translate(-50%, -50%)",
                top : "46%" ,
            })
        } if(LoserSeat === 3){
            return ({
                position : "absolute",
                left : "80%",   
                transform : "translate(-50%, -50%)",
                top : "2%",
            })
        } if(LoserSeat === 4){
            return ({
                position : "absolute",
                left : "95%",   
                transform : "translate(-50%, -50%)",
                top : "46%" ,
            })
        } 

        return null
    }

    const WinnerPositionFinder = () => {
        const WinnerSeat = GameData.CurrentChallenge.BluffData.Winner ;        
        if(WinnerSeat === 1){
            return ({
                position : "absolute",
                left : "82%",     
                transform : "translate(-50%, -50%)",
                top : "72%", 
            })
        } if(WinnerSeat === 2){
            return ({
                position : "absolute",
                left : "10%",   
                transform : "translate(-50%, -50%)",
                top : "38%" ,
            })
        } if(WinnerSeat === 3){
            return ({
                position : "absolute",
                left : "80%", 
                transform : "translate(-50%, -50%)",
                top : "-9%",
            })
        } if(WinnerSeat === 4){
            return ({
                position : "absolute",
                left : "90%",   
                transform : "translate(-50%, -50%)",
                top : "38%" ,
            })
        } 

        return null
    }

    if(GameData !== null && GameData.CurrentChallenge !== null){
    return (<motion.div 
        style={ animateValue() }>                    
                
                <motion.div 
                style={ IconSize() }
                initial={ IconPosition () }
                animate={ LoserPositionFinder() }
                transition={{ delay : 4 }}
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={Icon}>Table</object>  
                
                    
                        <img 
                        style={ ImageSize() }
                        src={Icon} alt="Loader" />
                              
                </motion.div>

                <motion.div className="bounce-in-top" style={
                    WinnerPositionFinder()
                }>
                    <object style={
                        ImageSize()
                    } type="image/svg+xml" data={ChallengeWinnerIcon}>Table</object>  
                </motion.div>

                
                <motion.div  
                animate={ LoserPositionCardCountFinder() }
                transition={{ delay : 4 }}
                style={{
                    position : "absolute",
                    top : "39%",
                    height : `${percentage(BoardWidth , 10)}px`,
                    width : `${percentage(BoardWidth , 10)}px`,
                    fontSize : `${percentage(BoardWidth , 2.5)}px`,
                    textAlign : "center",
                    background : "black",
                    borderRadius : "50%",
                    left : `${percentage(BoardWidth , 70)}px`,
                    borderStyle : "solid",
                    borderColor : "red",
                    borderWidth : `${percentage(BoardWidth , 0.15)}px`,
                }}>
                    <div style={{
                        position : "absolute",
                        fontSize : `${percentage(BoardWidth , 3.5)}px`,
                        top : "43%",
                        textAlign : "center",
                        width : `${percentage(BoardWidth , 10)}px`,
                    }}>{ GameData.CurrentChallenge.BluffData.CardPile }</div>
                    
                    <div style={{
                        position : "absolute",
                        background : "red",
                        textAlign : "center",
                        width : `${percentage(BoardWidth , 20)}px`,
                        backgroundColor : "black",
                        borderRadius : `${percentage(BoardWidth , 2)}px`,
                        left : "-50%",
                        top : "-10%",
                        borderStyle : "solid",
                        borderColor : "red",
                        borderWidth : `${percentage(BoardWidth , 0.15)}px`,
                    }}>
                        <div style={{
                            height : `${percentage(BoardWidth , 0.5)}px`,
                        }}></div>
                        CARD COUNT
                        <div style={{
                            height : `${percentage(BoardWidth , 0.5)}px`,
                        }}></div>
                        
                    </div>
                </motion.div>

                <motion.div className="flip-in-hor-top" style={ BluffersCardsStyleProvider() }>
                    <motion.div style={{
                        display : "flex",
                        justifyContent : "center"
                    }}>
                        { CardsPlayed.map((Card) => 
                            <motion.div style={{
                            }} key={Card.Index}>
                                { Card.Value === "A" &&
                                    <img src={SeriesA} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.75)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.75)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                        width : "auto"
                                    }}/> 
                                }

                                { Card.Value === "K" &&
                                    <img src={SeriesK} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.75)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.75)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                        width : "auto"
                                    }}/> 
                                }

                                { Card.Value === "Q" &&
                                    <img src={SeriesQ} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.75)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.75)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                        width : "auto"
                                    }}/> 
                                }

                                { Card.Value === "J" &&
                                    <img src={SeriesJ} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.75)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.75)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                        width : "auto"
                                    }}/> 
                                }

                                { Card.Value === "10" &&
                                    <img src={Series10} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.5)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.5)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,                                        
                                        width : "auto"
                                    }}/> 
                                }

                                { Card.Value === "9" &&
                                    <img src={Series9} alt="Card Background"
                                    style={{
                                        height : CardSize,
                                        marginLeft : `${percentage(BoardWidth , 0.5)}px`,
                                        marginRight : `${percentage(BoardWidth , 0.5)}px`,
                                        marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                        width : "auto"
                                    }}/> 
                                }


                                { Card.Value === GameData.CurrentChallenge.BluffData.Claim &&
                                    <motion.div style={{
                                    }}>
                                        <img src={Correct} alt="Card Background"
                                            style={{
                                                height : TickSize,
                                                marginLeft : `${percentage(BoardWidth , 0.5)}px`,
                                                marginRight : `${percentage(BoardWidth , 0.5)}px`,
                                                marginTop : `${percentage(BoardWidth , 1.5)}px`,
                                                width : "auto"
                                            }}/> 
                                    </motion.div>
                                }

                                { Card.Value !== GameData.CurrentChallenge.BluffData.Claim &&
                                    <motion.div style={{
                                    }}>
                                        <img src={Wrong} alt="Card Background"
                                            style={{
                                                height : TickSize,
                                                marginLeft : `${percentage(BoardWidth , 0.5)}px`,
                                                marginRight : `${percentage(BoardWidth , 0.5)}px`,
                                                marginTop : `${percentage(BoardWidth , 0.5)}px`,
                                                width : "auto"
                                            }}/> 
                                    </motion.div>
                                }
                                                               
                            </motion.div>
                        )}
                    </motion.div>                        
                </motion.div>

                <motion.div className="flip-out-hor-top" style={ BluffersCardsStyleProvider() }>
                        <motion.div style={{
                            backgroundColor : "black",
                            position : "absolute",
                            width : `100%`,
                            height : `${percentage(BoardWidth , 4.5)}px`,
                            top : `${percentage(BoardHeight , 3.5)}px`,
                            fontSize : `${percentage(BoardWidth , 3.5)}px`,
                            zIndex : 2
                        }}>BLUFFER'S CARDS</motion.div>

                        { NumberOfCardsBluffed() === 1 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }

                        { NumberOfCardsBluffed() === 2 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }

                        { NumberOfCardsBluffed() === 3 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }

                        { NumberOfCardsBluffed() === 4 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }

                        { NumberOfCardsBluffed() === 5 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }

                        { NumberOfCardsBluffed() === 6 &&
                            < motion.div>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                                <img src={CardBackground} alt="Card Background"
                                        style={{
                                            height : `${percentage(BoardWidth , 15)}px`,
                                            width : "auto"
                                        }}/>
                            </motion.div>
                        }
                        
                </motion.div>            
                
            </motion.div>)
    } else {
        return(<motion.div>
            
        </motion.div>)
    }

}

export default Section