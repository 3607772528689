import React , { useState , useEffect } from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import socket from '../../../SocketConnectionManager';
import IconOne from '../../../Icons/DisconnectedBlock.svg'

const Loader = () => {
    const [ isDisconnected , isDisconnectedUpdate ] = useState(null)      

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    useEffect(() => {
        if(isDisconnected === null){
            if(socket.connected === true){
                isDisconnectedUpdate(false)                
            } else {
                isDisconnectedUpdate(true)                
            }

        }
        
        socket.on("disconnect", function() { 
            isDisconnectedUpdate(true)
        });

        socket.on('connect' , function(){
            if(isDisconnected === true){
                isDisconnectedUpdate(false)
            }            
        })

        return () => {       
            if(window.location.pathname !== "/freetoplay/gametable"){
                socket.off('disconnect') 
                socket.off('connect')
            }

            if(window.location.pathname === "/freetoplay/gametable"){
                if(isDisconnected === true){
                    socket.off('disconnect') 
                }

                if(isDisconnected === false){
                    socket.off('connect')
                }
            }
        }
    }, [ isDisconnected , currentSize ])


    const sizeManager = () => {
        if(isDisconnected === true){
            return ({
                position : "absolute",
                width : `${percentage(currentSize.width , 100)}px`,   
                height : `${percentage(currentSize.height , 100)}px`,   
                backgroundColor : "rgba(0,0,0,0.2)",
                zIndex : "100",
                top : "0%",
                left : "0%"
            })
        } else {
            return ({
                position : "absolute",
                width : `${percentage(currentSize.width , 0)}px`,   
                height : `${percentage(currentSize.width , 0)}px`,   
                zIndex : "100",
                top : "0%",
                left : "90%"
            })
        }
    }

    


    if(TableType >= 1){
    return (
        <motion.div style={ sizeManager()}>

            <object 
            style={{
                width : "60%",
                position : "absolute",
                zIndex : 1,
                left : "50%",
                top : "50%",
                transform : "translate(-50%, -50%)",
            }} type="image/svg+xml" data={IconOne}>Floor</object> 

            {isDisconnected === true &&
                <motion.div        
                initial={{ skewX : -20}}
                style={{
                    width : "60%",
                    position : "absolute",
                    zIndex : 1,
                    left : "20%",
                    top : "28%",
                    transform : "translate(-50%, -50%)",
                    background : "red",
                    textAlign : "center",
                    fontSize : `${percentage(sizeManager().height , 10)}px`,
                    color : "#02ffff",
                    backgroundColor : "#323232"
                }}>
                    <div className='pulsate-fwd'>RECONNECTING....</div>
                </motion.div>
            }              

        </motion.div>        
    )
    } else {
        return (<motion.div>            
        </motion.div>)
    }
    
}

export default Loader