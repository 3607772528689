import React from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import SeriesA from '../../../../Icons/SeriesA.svg'
import SeriesK from '../../../../Icons/SeriesK.svg'
import SeriesQ from '../../../../Icons/SeriesQ.svg'
import SeriesJ from '../../../../Icons/SeriesJ.svg'
import Series10 from '../../../../Icons/Series10.svg'
import Series9 from '../../../../Icons/Series9.svg'

const SlotDisplay = (Value) => {
    const Card = Value.Card ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()

    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;

    const CardPosition = {
        position : "absolute",
        width : `${percentage(BoardWidth , 10)}px`,
        bottom : "6%",
        left : "37.5%"
    }

    return (  
        <motion.div>
            { Card !== null &&
                <motion.div>
                { Card.Value === "A" &&
                    <motion.div 
                        className='slide-in-bottom-minus-two-to-one'
                        style={ CardPosition }
                    >
                        <img src={SeriesA} alt="Aces" />
                    </motion.div>
                }
    
                { Card.Value === "K" &&
                    <motion.div 
                        className='slide-in-bottom-minus-one-to-one'
                        style={ CardPosition }
                    >
                        <img src={SeriesK} alt="Aces" />
                    </motion.div>
                }
    
                { Card.Value === "Q" && 
                    <motion.div 
                        className='slide-in-bottom-straight'
                        style={ CardPosition }
                    >
                        <img src={SeriesQ} alt="Aces" />
                    </motion.div>
                }
    
                { Card.Value === "J" &&
                    <motion.div 
                        className='slide-in-bottom-two-to-one'
                        style={ CardPosition }
                    >
                        <img src={SeriesJ} alt="Aces" />
                    </motion.div>
                }
    
                { Card.Value === "10" &&
                    <motion.div 
                        className='slide-in-bottom-three-to-one'
                        style={ CardPosition }
                    >
                        <img src={Series10} alt="Aces" />
                    </motion.div>
                }
    
                { Card.Value === "9" && 
                    <motion.div 
                        className='slide-in-bottom-four-to-one'
                        style={ CardPosition }
                    >
                        <img src={Series9} alt="Aces" />
                    </motion.div>
                }

            </motion.div>
        }
        </motion.div>               
    )
}

export default SlotDisplay 