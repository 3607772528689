import React from 'react';
import useOrientationChange from "use-orientation-change";
import Rendering from './ProfileComponents/MatchMakerRendering'
import DisconnectedMatchMaker from './ProfileComponents/MatchMakerDisconnected'

const Section = () => {
    const orientation = useOrientationChange()
    if (orientation === "landscape"){
        return (<div>

        </div>)
    } else {
        return (
            <div style={{'position' : "fixed"}}>
                < Rendering /> 
                < DisconnectedMatchMaker />
            </div>  
        )
    }
}

export default Section