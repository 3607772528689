import React  from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import SeriesA from '../../../../Icons/SeriesA.svg'
import SeriesK from '../../../../Icons/SeriesK.svg'
import SeriesQ from '../../../../Icons/SeriesQ.svg'
import SeriesJ from '../../../../Icons/SeriesJ.svg'
import Series10 from '../../../../Icons/Series10.svg'
import Series9 from '../../../../Icons/Series9.svg'
import Cards2 from '../../../../Icons/2Cards.svg'
import Cards3 from '../../../../Icons/3Cards.svg'
import Cards4 from '../../../../Icons/4Cards.svg'
import Cards5 from '../../../../Icons/5Cards.svg'
import Cards6 from '../../../../Icons/6Cards.svg'
import Cards7 from '../../../../Icons/7Cards.svg'
import Cards8 from '../../../../Icons/8Cards.svg'
import Cards9 from '../../../../Icons/9Cards.svg'
import Cards10 from '../../../../Icons/10Cards.svg'
import Cards11 from '../../../../Icons/11Cards.svg'
import Cards12 from '../../../../Icons/12Cards.svg'

const CardInfoDisplay = (Data) => {    
    const AllAces = Data.AllAces;
    const AllKings = Data.AllKings;
    const AllQueens = Data.AllQueens;
    const AllJacks = Data.AllJacks;
    const AllTens = Data.AllTens;
    const AllNines = Data.AllNines;


    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()

    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;



    const AcesPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            left : `${percentage(BoardWidth , 4.5)}px`,
            bottom : "11%"
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {            
            position : "absolute",
            left : `${percentage(BoardWidth , 4.5)}px`,
            zIndex : 1,
            bottom : "13%"
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "-2%"            
        }
    }

    const KingsPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            bottom : "11%",
            left : `${percentage(BoardWidth , 24.5)}px`,
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {
            position : "absolute",
            zIndex : 1,
            bottom : "13%",
            left : `${percentage(BoardWidth , 24.5)}px`,
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "15.5%"            
        }
    }

    const QueensPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            bottom : "11%",
            left : `${percentage(BoardWidth , 45.5)}px`,
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {
            position : "absolute",
            zIndex : 1,
            bottom : "13%",
            left : `${percentage(BoardWidth , 45.5)}px`,
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "32.5%"            
        }
    }

    const NinesPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            left : `${percentage(BoardWidth , 106)}px`,
            bottom : "11%",
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {
            position : "absolute",
            zIndex : 1,
            left : `${percentage(BoardWidth , 106)}px`,
            bottom : "13%",
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "83.5%"             
        }
    }

    const TensPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            left : `${percentage(BoardWidth , 85.5)}px`,
            bottom : "11%",
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {
            position : "absolute",
            zIndex : 1,
            left : `${percentage(BoardWidth , 85.5)}px`,
            bottom : "13%",
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "66.5%"            
        }
    }

    const JacksPosition = {
        CardPosition : {
            position : "absolute",
            zIndex : 2,
            left : `${percentage(BoardWidth , 65.5)}px`,
            bottom : "11%",
        },
        ImageSize : {
            width : `${percentage(BoardWidth , 10)}px`,
            height : "auto"
        },
        CardStackPosition : {
            position : "absolute",
            zIndex : 1,
            left : `${percentage(BoardWidth , 65.5)}px`,
            bottom : "13%",
        },
        NumberOfCards : {            
            position : "absolute",
            zIndex : 3,
            backgroundColor : "black",
            width : `${percentage(BoardWidth , 21)}px`,
            fontSize : `${percentage(BoardWidth , 3)}px`,
            textAlign : "center",
            bottom : "5%",
            left : "49.5%"          
        }
    }

    return (
    <motion.div 
    style={{
        position : "absolute",
        bottom : "0%",
        left : `${0 - percentage(BoardWidth , 10)}px`,
        width : `${percentage(BoardWidth , 120)}px`,
        height : `${percentage(BoardWidth , 34)}px`,   
        zIndex : 1                           
    }}>
        { AllAces.length > 0 &&
            <motion.div style={ AcesPosition.CardPosition }>
                <img src={SeriesA} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }

        { AllAces.length === 2 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards2} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllAces.length === 3 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }   

        { AllAces.length === 4 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllAces.length === 5 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }     

        { AllAces.length === 6 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }         

        { AllAces.length === 7 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        } 

        { AllAces.length === 8 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }       

        { AllAces.length === 9 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }   

        { AllAces.length === 10 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllAces.length === 11 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }      

        { AllAces.length === 12 &&
            <motion.div style={ AcesPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ AcesPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ AcesPosition.NumberOfCards }>
            { AllAces.length } - ACES
        </motion.div>

        { AllKings.length > 0 &&
            <motion.div style={ KingsPosition.CardPosition }>
                <img src={SeriesK} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }

        { AllKings.length === 2 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards2} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }    

        { AllKings.length === 3 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }   

        { AllKings.length === 4 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }    

        { AllKings.length === 5 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }     

        { AllKings.length === 6 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }         

        { AllKings.length === 7 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        } 

        { AllKings.length === 8 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }       

        { AllKings.length === 9 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }   

        { AllKings.length === 10 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }    

        { AllKings.length === 11 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }      

        { AllKings.length === 12 &&
            <motion.div style={ KingsPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ KingsPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ KingsPosition.NumberOfCards }>
            { AllKings.length } - KINGS
        </motion.div>

        { AllQueens.length > 0 &&
            <motion.div style={ QueensPosition.CardPosition }>
                <img src={SeriesQ} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }

        { AllQueens.length === 2 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllQueens.length === 3 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }   

        { AllQueens.length === 4 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllQueens.length === 5 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }     

        { AllQueens.length === 6 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }         

        { AllQueens.length === 7 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        } 

        { AllQueens.length === 8 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }       

        { AllQueens.length === 9 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }   

        { AllQueens.length === 10 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllQueens.length === 11 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }      

        { AllQueens.length === 12 &&
            <motion.div style={ QueensPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ QueensPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ QueensPosition.NumberOfCards }>
            { AllQueens.length } - QUEENS
        </motion.div>

        { AllNines.length > 0 &&
            <motion.div style={ NinesPosition.CardPosition }>
                <img src={Series9} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }

        { AllNines.length === 2 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllNines.length === 3 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }   

        { AllNines.length === 4 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllNines.length === 5 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }     

        { AllNines.length === 6 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }         

        { AllNines.length === 7 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        } 

        { AllNines.length === 8 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }       

        { AllNines.length === 9 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }   

        { AllNines.length === 10 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }    

        { AllNines.length === 11 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }      

        { AllNines.length === 12 &&
            <motion.div style={ NinesPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ NinesPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ NinesPosition.NumberOfCards }>
            { AllNines.length } - NINES
        </motion.div>

        { AllTens.length > 0 &&
            <motion.div style={ TensPosition.CardPosition }>
                <img src={Series10} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }

        { AllTens.length === 2 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllTens.length === 3 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }   

        { AllTens.length === 4 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllTens.length === 5 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }     

        { AllTens.length === 6 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }         

        { AllTens.length === 7 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        } 

        { AllTens.length === 8 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }       

        { AllTens.length === 9 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }   

        { AllTens.length === 10 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }    

        { AllTens.length === 11 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }      

        { AllTens.length === 12 &&
            <motion.div style={ TensPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ TensPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ TensPosition.NumberOfCards }>
            { AllTens.length } - TENS
        </motion.div>

        { AllJacks.length > 0 &&
            <motion.div style={ JacksPosition.CardPosition }>
                <img src={SeriesJ} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }

        { AllJacks.length === 2 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }    

        { AllJacks.length === 3 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards3} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }   

        { AllJacks.length === 4 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards4} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }    

        { AllJacks.length === 5 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards5} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }     

        { AllJacks.length === 6 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards6} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }         

        { AllJacks.length === 7 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards7} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        } 

        { AllJacks.length === 8 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards8} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }       

        { AllJacks.length === 9 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards9} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }   

        { AllJacks.length === 10 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards10} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }    

        { AllJacks.length === 11 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards11} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }      

        { AllJacks.length === 12 &&
            <motion.div style={ JacksPosition.CardStackPosition }>
                <img src={Cards12} alt="Aces" style={ JacksPosition.ImageSize }/>
            </motion.div>
        }                 

        <motion.div 
        initial={{ skewX : -10}}
        style={ JacksPosition.NumberOfCards }>
            { AllJacks.length } - JACKS
        </motion.div>

        
    </motion.div>
    )
}

export default CardInfoDisplay