import React , { useState } from 'react';
import { motion } from "framer-motion";
import useSound from 'use-sound';
import useResize from 'use-resize'; 
import Icon from '../../../Icons/PlayNow.svg'
import IconTwo from '../../../Icons/AccessingMatchMaker.svg'
import Config from '../../../GlobalFunctions/Config';
import socket from '../../../SocketConnectionManager';
import Sound from '../../../Sound/OnHandPlayed.mp3'

const PlayButton = (Data) => {
    const [ RedirectOn , RedirectOnUpdate ] = useState(false)
    const PlayerData = Data.PlayerData ;
    const LayerLevel = "2"
    const DefaultBackgroundColor = ""
    const [ playOne] = useSound(Sound, { interrupt : false});

    const ActiveClass = () => {
        if(Data.isExit === false || RedirectOn === true){
            return("")
        } else if(Data.isExit === true){
            return("flip-out-hor-top-fast")
        }
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateData = () => {
        if(TableType >= 2.01){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 71.5
            })
        } else if(TableType < 2.01 && TableType > 1.9){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 71
            })
        } else if(TableType <= 1.9 && TableType > 1.8){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 73
            })
        } else if(TableType <= 1.8 && TableType > 1.7){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 75
            })
        } else if(TableType <= 1.7 && TableType > 1.6){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 75
            })
        } else if(TableType <= 1.6 && TableType > 1.5){
            return ({
                ImageWidth : 90,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "5%",
                Top : 75
            })
        } else if(TableType <= 1.5 && TableType > 1.4){
            return ({
                ImageWidth : 80,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "10%",
                Top : 75
            })
        } else if(TableType <= 1.4 && TableType > 1.3){
            return ({
                ImageWidth : 80,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "10%",
                Top : 75
            })
        } else if(TableType <= 1.3 && TableType > 1.2){
            return ({
                ImageWidth : 80,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "10%",
                Top : 75
            })
        } else if(TableType <= 1.2 && TableType >= 1){
            return ({
                ImageWidth : 70,
                ImageHeight : 28,
                ImageType : "Long",
                Left : "15%",
                Top : 75
            })
        }
    }

    const TemplateValue = TemplateData()

    
    const DefaultSize  = {
        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
        height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,    
    }

    const DefaultTop = TemplateValue.Top;

    const isLoaded = TemplateValue.Left;

    const PlayButtonClicked = () => {  
        playOne()
        RedirectOnUpdate(true)
        Config.MatchMakerData = PlayerData
        Config.AssetLoadedStatus = true
        socket.emit('Transfer-To-Matchmaker')
    }
    

    
    const AnimateValue = () => {
        if(RedirectOn === false){
            return ({
                x : 0 , y : 0, scale : 1, opacity : 1
            })
        } else if(RedirectOn === true){
            return ({
                x : 0 , y : 0, scale : 0, opacity : 0
            })
        }
    }

    const RedirectAnimateValue = () => {
        if(RedirectOn === true){
            return ({
                x : 0 , y : 0, scale : 0.75, opacity : 1
            })
        } else if(RedirectOn === false){
            return ({
                x : 0 , y : 0, scale : 0, opacity : 0
            })
        }
    }
    
    return (
        <motion.div 
        className={ActiveClass()}
        style={{
            position : "absolute",
            zIndex : LayerLevel,       
            width :  DefaultSize.width,   
            height : DefaultSize.height,              
            top : `${percentage(currentSize.height ,DefaultTop)}px`,
            backgroundColor : DefaultBackgroundColor ,
            left : isLoaded
        }}> 
            <motion.div
                initial={{ x : 0 , y : 0 }}
                animate={AnimateValue()}
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    left : "0%",
                    transform : "translate(-50%, -0%)",
                }}>
                    <object 
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : percentage(currentSize.width , TemplateValue.ImageWidth)
                    }} type="image/svg+xml" data={Icon}>Floor</object>  
            </motion.div>  

            { RedirectOn === false &&
                <motion.div
                    initial={{ x : 0 , y : 0 }}
                    onTapStart={PlayButtonClicked}
                    style={{
                        position : "absolute",
                        zIndex : "2",
                        width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                        height :  `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,
                        left : "0%",
                        transform : "translate(-50%, -0%)",
                    }}>
                </motion.div> 
            }

            <motion.div
                initial={{ x : 0 , y : 0 }}
                animate={RedirectAnimateValue()}
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,
                    left : "0%",
                    transform : "translate(-50%, -0%)",
                }}>
                    <object 
                    style={{
                        position : "absolute",
                        zIndex : 2,
                        width : percentage(currentSize.width , TemplateValue.ImageWidth)
                    }} type="image/svg+xml" data={IconTwo}>Floor</object>  
            </motion.div>  
                        
        </motion.div>
    )
}

export default PlayButton