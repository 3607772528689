import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/Challenger.svg'

const Button = (Value) => {
    const [ IconLoaded , IconLoadedUpdate ] = useState(null)
    const GameData = Value.GameData ;
    const PlayerTurn = Value.PlayerTurn ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    
    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const PlayerChallenger = () => {        
        if(GameData === null){
            return null
        } else if(SeatOneValues.isChallenger ===  true){
            return 1
        } else if(SeatTwoValues.isChallenger === true){
            return null
        } else if(SeatThreeValues.isChallenger === true){
            return null
        } else if(SeatFourValues.isChallenger === true){
            return null
        } else {
            return null
        }
    }

    const AnimateValue = () => {
        if(PlayerTurn === false && PlayerChallenger() !== null && IconLoaded === true){   
                return({
                    x : percentage(BoardWidth , 33),  
                    y : percentage(BoardHeight , 63),
                    scale : 1                      
                })  
            } else {
                return({
                    x : percentage(BoardWidth , 33),  
                    y : percentage(BoardHeight , 40),
                    scale : 0
                })
            }
    }

    
    return (
        <motion.div  
        style={{
            position : "absolute", 
            zIndex : "2",
            width  : `${ percentage(BoardWidth , 35)}px`,                                            
            height : `${ percentage(BoardHeight , 23)}px`,
        }}
        animate={ AnimateValue() }>
             <object 
                onLoad={() => IconLoadedUpdate(true) }
                style={{
                width :  `100%`,
                position : "absolute",
                zIndex : 1,
                left : "50%",
                top : "50%",
                transform : "translate(-50%, -50%)",
            }} type="image/svg+xml" data={Icon}>Table</object>   
                     
        </motion.div>
    )
}

export default Button