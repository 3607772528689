import React , { useState } from 'react';
import useResize from 'use-resize'; 
import { motion } from 'framer-motion';
import TableTemplate from '../TableTemplate';
import Icon from '../../../../Icons/Bluffer.svg'

const Button = (Value) => {
    const [ IconLoaded , IconLoadedUpdate ] = useState(null)
    const GameData = Value.GameData ;
    const PlayerTurn = Value.PlayerTurn ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }
    
    const TemplateValue = TableTemplate()
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageHeight) ;

    
    const PlayerData = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerOne
        }
    }

    const PlayerTwo = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerTwo
        }
    }

    const PlayerThree = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerThree
        }
    }

    const PlayerFour = () => {
        if(GameData === null){
            return null
        } else if(GameData.MySeat === 1){
            return GameData.PlayerFour
        }
    }


    const SeatOneValues = PlayerData() ;
    const SeatTwoValues = PlayerTwo() ;
    const SeatThreeValues = PlayerThree() ;
    const SeatFourValues = PlayerFour() ;

    const PlayerBluffer = () => {        
        if(GameData === null){
            return null
        } else if(SeatOneValues.isBluffer ===  true){
            return 1
        } else if(SeatTwoValues.isBluffer === true){
            return null
        } else if(SeatThreeValues.isBluffer === true){
            return null
        } else if(SeatFourValues.isBluffer === true){
            return null
        } else {
            return null
        }
    }

    const AnimateValue = () => {
        if(PlayerTurn === false && PlayerBluffer() !== null && IconLoaded === true){   
                return({
                    x : percentage(BoardWidth , 33),  
                    y : percentage(BoardHeight , 63),
                    scale : 1                      
                })  
            } else {
                return({
                    x : percentage(BoardWidth , 33),  
                    y : percentage(BoardHeight , 40),
                    scale : 0
                })
            }
    }

    const ClaimProvider = (Value) => {
        if(Value === "A"){
            return ("ACE(S)")
        } else if(Value === "K"){
            return ("KING(S)")
        } else if(Value === "Q"){
            return ("QUEEN(S)")
        } else if(Value === "J"){
            return ("JACK(S)")
        } else if(Value === "10"){
            return ("TEN(S)")
        } else if(Value === "9"){
            return ("NINE(S)")
        } else {
            return ("WTF")
        }
    }

    
    return (
        <motion.div  
        style={{
            position : "absolute", 
            zIndex : "2",
            width  : `${ percentage(BoardWidth , 35)}px`,                                            
            height : `${ percentage(BoardHeight , 23)}px`,
        }}
        animate={ AnimateValue() }>
             <object 
                onLoad={() => IconLoadedUpdate(true) }
                style={{
                width :  `100%`,
                position : "absolute",
                zIndex : 1,
                left : "50%",
                top : "50%",
                transform : "translate(-50%, -50%)",
            }} type="image/svg+xml" data={Icon}>Table</object>  

            { GameData.CurrentBluff !== null &&
                <motion.div  style={{
                    position : "absolute",                                          
                }}
                initial={{
                    x : percentage(BoardWidth , 0),
                    y : percentage(BoardWidth , 0),
                    scale : 2
                }} animate={{
                    x : percentage(BoardWidth , 47),
                    y : percentage(BoardWidth , 13),
                    scale : 2
                }} transition={{ duration : 0.3}}>
                    <motion.div>
                    <div style={{
                        backgroundColor : "rgba(0,0,0,0.8)",
                        width : `${percentage(BoardWidth , 18)}px`,
                        height : `${percentage(BoardHeight , 6)}px`,
                        borderRadius : `${percentage(BoardWidth , 1)}px`,
                        borderWidth : `${percentage(BoardWidth , 0.35)}px`,
                        borderStyle : "solid",
                        borderColor : "black",
                        fontSize : `${percentage(BoardHeight , 1.5)}px`,
                    }}>

                    <div style={{
                        color : "#FDA80D",
                        backgroundColor : "black",
                        textAlign : "center"
                    }}>
                        <div style={{"height" : `${percentage(BoardHeight , 0.5)}px`,}}></div>
                        CLAIMED
                        <div style={{"height" : `${percentage(BoardHeight , 0.5)}px`,}}></div>
                    </div>

                    <div style={{
                        
                    }}>
                        
                        <div style={{
                            display : "flex",
                            justifyContent : "center"
                        }}>
                            <div>{ GameData.CurrentBluff.NumberOfCardsBluffed }</div>
                            <div style={{width : `${percentage(BoardWidth , 1.5)}px`,}}></div>
                            <div>{ ClaimProvider(GameData.CurrentBluff.Claim) }</div>
                        </div>
                    </div>

                    </div>
                    </motion.div>
                </motion.div>
            }  
                     
        </motion.div>
    )
}

export default Button