import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Icon from '../../../Icons/Loading.svg'
import useSetTimeout from 'use-set-timeout';
import IconThree from '../../../Icons/LoaderBlock.svg'
import TableTemplate from '../GameAssets/TableTemplate';
import socket from '../../../SocketConnectionManager';
import Config from '../../../GlobalFunctions/Config';

const Loader = (Data) => {
    const ActiveClass = () => {
        if(Data.isExit === false){
            return("swing-in-left-fwd-fast")
        } else if(Data.isExit === true){
            return("flip-out-hor-top-fast")
        }
    }

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const  DefaultSize = {
        height : currentSize.height ,
        width : currentSize.width
    }

    const TableType = currentSize.height / currentSize.width

    const TemplateValueTable = TableTemplate()

    const LeftValue = () => {
        return ((100 - TemplateValueTable.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValueTable.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValueTable.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValueTable.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValueTable.ImageHeight)/20                                      
        })
    }

    const Loader = () => {
        const TimeOutValue = () => {
            if(Config.FirstTimeLoad === null){                
                return 2000
            } else if(Config.FirstTimeLoad === true){
                return 800
            }
        }
        useSetTimeout(() => {
            socket.emit("Now-Load-Profile")
        }, 100)

        useSetTimeout(() => {
            socket.emit("Switch-Loader-off")
        }, TimeOutValue())

        return (
            <motion.div style={{
                position : "absolute",
                zIndex : "100",       
                width :  `${DefaultSize.width}px`,   
                height : `${DefaultSize.height}px`,              
                left : "0%",
                top : "0%",
            }}
            >
            <motion.div 
            style={{
                position : "absolute",
                zIndex : "100",       
                width :  `${DefaultSize.width}px`,   
                height : `${DefaultSize.height}px`,           
                left : "0%",
                top : "0%",
            }}>

                
                
                
            </motion.div>
            </motion.div>
        )
    }

    if(TableType >= 1){
    return (
        <motion.div className={ActiveClass()}
        style={ animateValue()}>
            <motion.div 
                style={{
                    position : "absolute",
                    zIndex : "2",
                    width :  `${percentage(currentSize.width , TemplateValueTable.ImageWidth)/1.05}px`,
                    height :  `${percentage(currentSize.width , TemplateValueTable.ImageWidth)/1.05}px`,
                    left : `50%`,
                    transform : "translate(-50%, -0%)",
                    top : "10%",             
                }}>

                    <object                   
                    type="image/svg+xml" data={IconThree}>Backdrop</object>
                
            </motion.div>  

            <Loader />    

            <motion.div                
                style={{
                    position : "absolute",
                    zIndex : "2",
                    width :  `${percentage(currentSize.width , TemplateValueTable.ImageWidth)/2.5}px`,
                    height :  `${percentage(currentSize.width , TemplateValueTable.ImageWidth)/2.5}px`,
                    left : `50%`,
                    transform : "translate(-50%, -0%)",
                    top : "10%",
                }}>

                
                <img className='rotate-in-center-animation'
                style={{
                    position : "absolute",
                    width : percentage(currentSize.width , TemplateValueTable.ImageWidth)/2.5
                }}
                src={Icon} alt="Loader" />
                
            </motion.div>  
                                 
        </motion.div>
        
    )
    } else {
        return (<motion.div>            
        </motion.div>)
    }
    
}

export default Loader