import React from 'react';
import { motion }  from "framer-motion";

const CurrentBluff = (Values) => {        
        const GameData = Values.GameData;
        const BoardHeight = Values.BoardHeight ;
        const BoardWidth = Values.BoardWidth ;


        function percentage(num, per)
        {
            return (num/100)*per;
        }
        
        const ClaimProvider = (Value) => {
            if(Value === "A"){
                return ("ACE(S)")
            } else if(Value === "K"){
                return ("KING(S)")
            } else if(Value === "Q"){
                return ("QUEEN(S)")
            } else if(Value === "J"){
                return ("JACK(S)")
            } else if(Value === "10"){
                return ("TEN(S)")
            } else if(Value === "9"){
                return ("NINE(S)")
            } else {
                return ("WTF")
            }
        }

        
        return (<motion.div>
                <div style={{
                    backgroundColor : "rgba(0,0,0,0.8)",
                    width : `${percentage(BoardWidth , 18)}px`,
                    height : `${percentage(BoardHeight , 9)}px`,
                    borderRadius : `${percentage(BoardWidth , 1)}px`,
                    borderWidth : `${percentage(BoardWidth , 0.35)}px`,
                    borderStyle : "solid",
                    borderColor : "black",
                    fontSize : `${percentage(BoardHeight , 3)}px`,
                }}>

                <div style={{
                    color : "#FDA80D",
                    backgroundColor : "black",
                    textAlign : "center"
                }}>
                    <div style={{"height" : `${percentage(BoardHeight , 0.5)}px`,}}></div>
                    CLAIMED
                    <div style={{"height" : `${percentage(BoardHeight , 0.5)}px`,}}></div>
                </div>

                <div style={{
                   
                }}>
                    
                    <div style={{
                        display : "flex",
                        justifyContent : "center"
                    }}>
                        <div>{ GameData.CurrentBluff.NumberOfCardsBluffed }</div>
                        <div style={{width : `${percentage(BoardWidth , 1.5)}px`,}}></div>
                        <div>{ ClaimProvider(GameData.CurrentBluff.Claim) }</div>
                    </div>
                </div>

                </div>
        </motion.div>)
}

export default CurrentBluff