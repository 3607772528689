import React , { useState , useEffect } from 'react';
import PlaceHolder from '../../../../Icons/ProfilePlaceHolder.svg'
import Config from '../../../../GlobalFunctions/Config';
import IsGamerProfile from '../../../../GlobalFunctions/NetworkCalls/IsGamerAccess'

const Section = (Value) => {
        const BlockSize = Value.BlockSize;        
        const [ PlayerData , PlayerDataUpdate ] = useState(null) ;
        const [ isImageLoaded , isImageLoadedUpdate ] = useState(null)

        function percentage(num, per)
        {
                return (num/100)*per;
        }

        const width = BlockSize
        const AvatarSize = 88;

        useEffect(() => {
                if(PlayerData === null){
                        IsGamerProfile(function (data){
                                PlayerDataUpdate(data)
                        })
                }
        },[ PlayerData ])

        const onProfileImageLoad = () => {
        console.log("IMAGE LOADED")
        isImageLoadedUpdate(true)
        }

        const onProfileImageLoadError = () => {
        console.log("IMAGE NOT LOADED")
        isImageLoadedUpdate(false)
        }
        

        return (<div style={{
                position : "absolute",
                left : "21%",
                top : "7%",                
        }}>
                { PlayerData !== null && Config.MxPlayerData.playerData.playerProfileData.profilePicUrl === null && PlayerData.isMxPlayerIDValid === true &&          
                    <img 
                    style={{
                        height : `${percentage(width , AvatarSize)}px`,
                        width : `${percentage(width , AvatarSize)}px`,
                        borderRadius : "50%",
                        position : "absolute"
                    }} alt="placeholder" src={PlaceHolder} />
                }

                {   PlayerData !== null && PlayerData.isMxPlayerIDValid === false &&
                    <img 
                    style={{
                        height : `${percentage(width , AvatarSize)}px`,
                        width : `${percentage(width , AvatarSize)}px`,
                        borderRadius : "50%"
                    }} alt="placeholder" src={PlaceHolder} />
                }

                { PlayerData !== null && Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && PlayerData.isMxPlayerIDValid === true &&          
                    <img 
                    onLoad={()=> onProfileImageLoad() }
                    onError={()=> onProfileImageLoadError() }
                    style={{
                        height : `${percentage(width , AvatarSize)}px`,
                        width : `${percentage(width , AvatarSize)}px`,
                        borderRadius : "50%",
                        display : "none"
                    }} alt="placeholder" 
                    src={ Config.MxPlayerData.playerData.playerProfileData.profilePicUrl } />
                }

                { PlayerData !== null && Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && 
                    PlayerData.isMxPlayerIDValid === true &&   
                    isImageLoaded === true &&       
                    <img 
                    style={{
                        height : `${percentage(width , AvatarSize)}px`,
                        width : `${percentage(width , AvatarSize)}px`,
                        borderRadius : "50%"
                    }} alt="placeholder" 
                    src={ Config.MxPlayerData.playerData.playerProfileData.profilePicUrl } />
                }

                { PlayerData !== null && Config.MxPlayerData.playerData.playerProfileData.profilePicUrl !== null && 
                    PlayerData.isMxPlayerIDValid === true &&   
                    isImageLoaded === false &&       
                    <img 
                    style={{
                        height : `${percentage(width , AvatarSize)}px`,
                        width : `${percentage(width , AvatarSize)}px`,
                        borderRadius : "50%"
                    }} alt="placeholder" 
                    src={ PlaceHolder } />
                }

        </div>)
}

export default Section