import React from 'react';
import { motion } from "framer-motion";
import useResize from 'use-resize'; 
import Icon from '../../../Icons/PokerTable.svg'
import TableTemplate from '../GameAssets/TableTemplate';

const Table = (Value) => {
    const Location = Value.Location ;
    
    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const TemplateValue = TableTemplate()

    const AnimateValue = () => {
        if(Location === "/freetoplay/gametable"){
            return(0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20 )
        } else {
            return(0)
        }
    }

    const animateValue = () => {        
        return ({
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : "0%",
            bottom : TemplateValue.BottomValue,
            y : AnimateValue()
        })
    }

    
    const TableClass = () => {
        return("")
    }
    return (
        <motion.div 
        style={ animateValue() }>
            <motion.div 
                style={{
                    position : "absolute",
                    zIndex : "1",
                    width :  `${percentage(currentSize.width ,  100)}px`,
                    height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,  
                    textAlign : "center"                                                                     
                }}>

                    <object 
                    className={ TableClass() }
                    style={{
                        height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,  
                    }}
                    type="image/svg+xml" data={Icon}>Table</object>  
                
            </motion.div>                      
        </motion.div>
    )    
}

export default Table