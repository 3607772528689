import React from "react";
import useResize from 'use-resize'; 
import { motion }  from "framer-motion";
import IconOne from '../../../Icons/NineRunning.svg'
import IconTwo from '../../../Icons/TenRunning.svg'
import IconThree from '../../../Icons/JackRunning.svg'
import IconFour from '../../../Icons/QueenRunning.svg'
import IconFive from '../../../Icons/KingRunning.svg'
import IconSix from '../../../Icons/AceRunning.svg'
import TableTemplate from './TableTemplate';

const Mover = (Value) => {
    const GameData = Value.GameData ;

    function SizeInfo() {
        const size = useResize()
        
        return size;
    }

    const currentSize = SizeInfo();

    const TemplateValue = TableTemplate()

    function percentage(num, per)
    {
        return (num/100)*per;
    }

    const SeriesRunningData = () => {
        if(GameData === null){            
            return null
        } else if(GameData.CurrentBluff !== null){
            return GameData.CurrentBluff.Claim
        } else if(GameData.CurrentChallenge !== null){
            return GameData.CurrentChallenge.BluffData.Claim
        } else {
            return null
        }
    }

    const ClassValue = () => {
        return ("flip-in-hor-top")
    }
    
    const LeftValue = () => {
        return ((100 - TemplateValue.ImageWidth) / 2)
     }
    
    const animateValue = () => {
        return ({                
            position : "absolute",
            zIndex : "2",       
            width :  `${percentage(currentSize.width , TemplateValue.ImageWidth)}px`,   
            height : `${percentage(currentSize.width , TemplateValue.ImageHeight)}px`,              
            left : percentage(currentSize.width , LeftValue()),                                        
            transform : "translate(-50%, -0%)",
            bottom : TemplateValue.BottomValue,     
            y : 0 - percentage(currentSize.width , TemplateValue.ImageHeight)/20                                      
        })
    }
    
    const BoardHeight = percentage(currentSize.width , TemplateValue.ImageWidth) ;
    const BoardWidth = percentage(currentSize.width , TemplateValue.ImageHeight) ;
    
    const IconPosition = () => {        
            return ({
                position : "absolute",
                left : "36%",   
                transform : "translate(-50%, -50%)",
                top : "25%" ,
            })
    }

    const IconSize = () => ({
        width :  `${percentage(BoardWidth , 20)}px`,
        height : `${percentage(BoardHeight , 8)}px`,                  
        position : "absolute",
    })

    const ImageSize = () => ({
        width :  `${percentage(BoardWidth , 18)}px`,
        position : "absolute",
        zIndex : 1,
        left : "50%",
        top : "50%",
        transform : "translate(-50%, -50%)",
    })
    
    return (
        <motion.div style={
            animateValue()
        }>
            {  SeriesRunningData() === "A" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconSix}>Table</object>  
                </motion.div>
            }

            {  SeriesRunningData() === "K" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconFive}>Table</object>  
                
                </motion.div>
            }

            {  SeriesRunningData() === "Q" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconFour}>Table</object>  
                </motion.div>
            }

            {  SeriesRunningData() === "J" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconThree}>Table</object>  
                </motion.div>
            }

            {  SeriesRunningData() === "10" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconTwo}>Table</object>  
                </motion.div>
            }

            {  SeriesRunningData() === "9" &&
                <motion.div className={ClassValue()}
                style={ IconSize() }
                initial={ IconPosition () }
                >
                <object style={
                    ImageSize()
                } type="image/svg+xml" data={IconOne}>Table</object>  
                </motion.div>
            }

        </motion.div>
    )
    
}

            

export default Mover